import { type MouseEventHandler, type SyntheticEvent, useCallback } from 'react'

import { useDropdownMenuContext } from '../../contexts/DropdownMenuContext'
import { NavigationItem } from './NavigationItem'

interface DropdownMenuItemProps {
  icon?: JSX.Element
  onClick?: MouseEventHandler<HTMLAnchorElement>
  title: string
  url: string
  stamp?: string
}

export function DropdownMenuItem(props: DropdownMenuItemProps) {
  const { collapse } = useDropdownMenuContext()

  const handleOnClick = useCallback(
    (event: SyntheticEvent<EventTarget>) => {
      event.stopPropagation()
      collapse()
    },
    [collapse]
  )

  return <NavigationItem {...props} onCallback={handleOnClick} />
}

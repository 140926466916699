export class NotFoundError extends Error {
  constructor(options?: ErrorOptions) {
    super('The required resource was not found', options)
    this.name = 'NotFoundError'

    // For V8 only
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, NotFoundError)
    }
  }
}

import flatpickr from 'flatpickr'
import PropTypes from 'prop-types'
import { useEffect, useCallback, useState, useRef, useMemo, useLayoutEffect } from 'react'
import styled, { createGlobalStyle, keyframes } from 'styled-components/macro'

import { IconCalendar, IconClose } from '@loadsmart/icons'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import 'flatpickr/dist/themes/airbnb.css'

import useWindowWidth from 'core/ui/hooks/useWindowWidth'

import Input from 'legacy/components/InputV2/Input'

const customFadeInDown = keyframes`
  0% { transform: translate3d(0, -20px, 0); opacity: 0; }
  100% { transform: translate3d(0, 5px, 0); opacity: 1; }
`

const DateInputContainer = styled.div`
  position: relative;
`

const DateInputInnerContainer = styled.div`
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

const StyledFlatpickr = createGlobalStyle`
  .flatpickr-calendar {
    width: auto !important;
    padding: 15px !important;
  }

  .flatpickr-calendar.open {
    animation: ${customFadeInDown} 300ms cubic-bezier(0.23, 1, 0.32, 1) !important;
  }
  .cur-month,
  .cur-year {
    color: ${getToken('color-neutral-darkest')} !important;
    font-weight: 500 !important;
    font-size: 15px !important;
  }
  .flatpickr-weekday {
    color: ${getToken('color-neutral-darkest')} !important;
    font-weight: 400 !important;
    font-size: 15px !important;
  }
  .flatpickr-day {
    font-size: 15px;

    border: 0px !important;
    box-shadow: none !important;
    :hover {
      background: ${getToken('color-success-light')} !important;
    }
  }
  .flatpickr-day.flatpickr-disabled {
    color: ${getToken('color-neutral')} !important;
  }
  .flatpickr-day.selected {
    font-weight: 500;

    background-color: ${getToken('color-success')} !important;
  }
  .flatpickr-day.startRange {
    background: ${getToken('color-success')} !important;
    border-top-left-radius: 4px !important;
  }
  .flatpickr-day.inRange {
    background-color: ${getToken('color-success-light')} !important;
  }
  .flatpickr-day.endRange {
    background: ${getToken('color-success')} !important;
    border-bottom-right-radius: 4px !important;
  }
  .flatpickr-day.today {
    position: relative;

    font-weight: 500;
    ::before {
      position: absolute;
      bottom: -5px;
      left: 0;

      display: inline-block;
      width: 100%;

      color: ${getToken('color-success')};
      font-weight: 700;
      font-size: 26px;
      text-align: center;

      content: '.';
    }
  }
  .dayContainer {
    padding: 5px 20px !important;
  }
  .flatpickr-weekdaycontainer {
    padding: 20px 20px 10px !important;
  }
  .flatpickr-next-month {
    top: 12px !important;
    right: 37px !important;

    fill: ${getToken('color-neutral-darker')} !important;
    :hover {
      svg {
        fill: ${getToken('color-neutral-darkest')} !important;
      }
    }
  }
  .flatpickr-prev-month {
    top: 12px !important;
    left: 37px !important;

    fill: ${getToken('color-neutral-darker')} !important;
    :hover {
      svg {
        fill: ${getToken('color-neutral-darkest')} !important;
      }
    }
  }
`

const StyledFlackpickrZIndex = createGlobalStyle`
  .flatpickr-calendar.open {
    z-index: ${({ zIndex }) => zIndex} !important;
  }
`

const DateInputClearButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 2px;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;

  & > button {
    padding: 0 7px 0 0;

    color: ${getToken('color-neutral-darker')};

    background-color: ${getToken('color-neutral-white')};
    border: 0;
    box-shadow: none;
    cursor: pointer;
    opacity: ${props => (props.isVisible ? 1 : 0)};

    transition: opacity 120ms linear;

    &:hover {
      color: ${getToken('color-neutral-darkest')};
    }
  }
`

export const DateInput = ({
  startDate,
  endDate,
  stateDateFormatter,
  displayDateFormatter,
  onDatesChange,
  range,
  hideClearButton,
  zIndex,
  onClose,
  invalid,
  disabled,
  maxDate,
  minDate,
  ...passThrough
}) => {
  const windowWidth = useWindowWidth()
  const [showMonths, setShowMonths] = useState(2)
  const [isOpen, setOpen] = useState(false)

  const datePicker = useRef()
  const inputRef = useRef()
  const innerInputRef = useRef()

  const mayClear = startDate || endDate ? true : false

  const handleInnerInputRef = useCallback(ref => {
    innerInputRef.current = ref.current
  }, [])

  const handleDatesChange = useCallback(
    selectedDates => onDatesChange(...selectedDates),
    [onDatesChange]
  )

  const handleOpen = useCallback(() => setOpen(true), [])

  const handleClose = useCallback(
    (selectedDates, _, current) => {
      setOpen(false)

      /* Solves an bug where when you select only one date and closed the date picker
       * it wouldn't show the date as selected when you next open the component. */
      if (range && selectedDates.length === 1) {
        const date = selectedDates[0]
        current.setDate([date, date])
      }
      onClose()
    },
    [range, onClose]
  )

  const handleClearDates = useCallback(() => {
    if (!mayClear) {
      if (innerInputRef.current) {
        innerInputRef.current.focus()
      }

      return datePicker.current.open()
    }

    return datePicker.current.clear()
  }, [datePicker, mayClear])

  useLayoutEffect(() => {
    /* Flatpickr requires the date to be provided using the format as specified on config */
    const formattedStartDate = startDate && stateDateFormatter(startDate)
    const formattedEndDate = endDate && stateDateFormatter(endDate)
    const defaultDate = range ? [formattedStartDate, formattedEndDate] : [formattedStartDate]

    /**
     * @type import('flatpickr/dist/types/options').Options
     */
    const flatpickrOptions = {
      mode: range ? 'range' : 'single',
      dateFormat: 'Y-m-d',
      minDate: minDate,
      maxDate: maxDate,
      onChange: handleDatesChange,
      onOpen: handleOpen,
      onClose: handleClose,
      defaultDate,
      showMonths,
      disableMobile: true,
    }

    if (inputRef.current && !datePicker.current) {
      datePicker.current = flatpickr(inputRef.current, flatpickrOptions)
    } else if (inputRef.current && datePicker.current) {
      /* Ideally we would just set the config of showMonths to the appropriate value
       * but that has bugs misbehaves when going low res -> hi res.
       * So we use recreate the component.
       * This is for a fully responsive behavior. */
      const curMonths = datePicker.current.config.showMonths
      const shouldReopen = datePicker.current.isOpen

      if (curMonths !== showMonths) {
        datePicker.current.destroy()
        const newDatePicker = flatpickr(inputRef.current, flatpickrOptions)
        newDatePicker.setDate(defaultDate)
        datePicker.current = newDatePicker
        if (shouldReopen) newDatePicker.open()
      }
    }
  }, [
    handleDatesChange,
    inputRef,
    startDate,
    endDate,
    datePicker,
    handleClose,
    showMonths,
    stateDateFormatter,
    range,
    handleOpen,
    minDate,
    maxDate,
  ])

  useEffect(() => {
    if (windowWidth < 768 && showMonths !== 1) {
      setShowMonths(1)
    } else if (windowWidth >= 768 && showMonths !== 2) {
      setShowMonths(2)
    }
  }, [windowWidth, showMonths])

  const displayValue = useMemo(() => {
    const startDateStr = startDate && displayDateFormatter(startDate)
    const endDateStr = range ? endDate && displayDateFormatter(endDate) : null

    return `${startDateStr ? startDateStr : ''}${endDateStr ? ' - ' + endDateStr : ''}`
  }, [startDate, displayDateFormatter, range, endDate])

  const placeholderText = useMemo(() => `Select date${range ? 's' : ''}`, [range])

  return (
    <DateInputContainer {...passThrough}>
      <StyledFlatpickr />
      {isOpen && <StyledFlackpickrZIndex zIndex={zIndex} />}

      <DateInputInnerContainer ref={inputRef} disabled={disabled}>
        <Input
          placeholder={placeholderText}
          icon={IconCalendar}
          value={displayValue}
          getInputRef={handleInnerInputRef}
          selectOnly
          invalid={invalid}
          disabled={disabled}
        />
      </DateInputInnerContainer>

      {!hideClearButton && (
        <DateInputClearButtonContainer isVisible={mayClear}>
          <button type="button" onClick={handleClearDates} data-testid="date-input-clear-button">
            <IconClose width={11} height={11} />
          </button>
        </DateInputClearButtonContainer>
      )}
    </DateInputContainer>
  )
}

DateInput.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onDatesChange: PropTypes.func,
  stateDateFormatter: PropTypes.func.isRequired,
  displayDateFormatter: PropTypes.func.isRequired,
  range: PropTypes.bool,
  hideClearButton: PropTypes.bool,
  zIndex: PropTypes.number,
  onClose: PropTypes.func,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
}

DateInput.defaultProps = {
  startDate: undefined,
  endDate: undefined,
  onDatesChange: Function.prototype,
  range: false,
  hideClearButton: false,
  zIndex: 60,
  onClose: Function.prototype,
  invalid: false,
  disabled: false,
  minDate: null,
  maxDate: null,
  calendarPosition: 'auto',
  positionElement: null,
}

export default DateInput

import numeral from 'numeral'

const RATE_INCREMENT_STEP = 10

const RATE_ACTIONS = {
  SET_RATE_PRICE: 'SET_RATE_PRICE',
  INCREMENT_RATE_PRICE: 'INCREMENT_RATE_PRICE',
  DECREMENT_RATE_PRICE: 'DECREMENT_RATE_PRICE',
}

function parseRate(value) {
  const rate = numeral(value || 0).value() ?? 0
  if (rate < 0) {
    return 0
  }
  return rate
}

function isRateValid(rate) {
  return rate > 0
}

function parseAndValidateRate(rate) {
  const parsedRate = parseRate(rate)
  const hasError = !isRateValid(parsedRate)

  return {
    rate: parsedRate,
    hasError,
  }
}

function rateReducer(state, action) {
  const { type, payload } = action

  switch (type) {
    case RATE_ACTIONS.SET_RATE_PRICE: {
      return parseAndValidateRate(payload)
    }
    case RATE_ACTIONS.INCREMENT_RATE_PRICE: {
      const { rate } = state
      return parseAndValidateRate(rate + RATE_INCREMENT_STEP)
    }
    case RATE_ACTIONS.DECREMENT_RATE_PRICE: {
      const { rate } = state
      return parseAndValidateRate(rate - RATE_INCREMENT_STEP)
    }
    default: {
      return state
    }
  }
}

function rateReducerInit(initialRate) {
  return parseAndValidateRate(initialRate)
}

export { RATE_INCREMENT_STEP, RATE_ACTIONS, rateReducer, rateReducerInit }

import { getCookie } from 'core/infra/cookie'

import { type SignUp } from '../domain/SignUp'

export type RawHubspot = {
  fields: [
    { name: 'firstname'; value: string },
    { name: 'lastname'; value: string },
    { name: 'email'; value: string },
    { name: 'dot_number'; value: string },
    { name: 'mc_number'; value: string }
  ]
  context: {
    hutk?: string
    pageName: string
    pageUri: string
  }
}

export function mapSignUpToApi(signUp: SignUp): RawHubspot {
  return {
    fields: [
      { name: 'firstname', value: signUp.firstName },
      { name: 'lastname', value: signUp.lastName },
      { name: 'email', value: signUp.email },
      { name: 'dot_number', value: signUp.dotNumber },
      { name: 'mc_number', value: signUp.mcNumber },
    ],
    context: {
      hutk: getCookie('hubspotutk'),
      pageName: document && document.location.pathname,
      pageUri: document && document.location.toString(),
    },
  }
}

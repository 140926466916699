export const storage = {
  set(key: string, value: string): void {
    if (typeof window !== 'undefined') {
      window.localStorage && window.localStorage.setItem(key, value)
    }
  },
  get(key: string): string | null {
    if (typeof window !== 'undefined') {
      return window.localStorage && window.localStorage.getItem(key)
    }
    return null
  },
  clear(): void {
    if (typeof window !== 'undefined') {
      window.localStorage && window.localStorage.clear()
    }
  },
  remove(key: string): void {
    if (typeof window !== 'undefined') {
      window.localStorage && window.localStorage.removeItem(key)
    }
  },
}

export default storage

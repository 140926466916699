import { useEffect } from 'react'

import { Button, Layout } from '@loadsmart/miranda-react'

import { type ServerError } from 'core/domain/Request'
import { type HttpClientError } from 'core/infra/http/HttpClient'
import { useAuthContext } from 'core/ui/contexts/authContext'
import { useAbortController } from 'core/ui/hooks/useAbortController'

import { analytics } from 'legacy/utils/analytics'

import { useLogin } from './hooks/useLogin'
import { LoginForm } from './LoginForm'
import { Dialog } from './SignUpDialog.styles'

export type LoginDialogProps = {
  onCancel: () => void
  onComplete: () => void
  onSignup: () => void
}

export function LoginDialog({ onCancel, onComplete, onSignup }: LoginDialogProps) {
  const { message, isLoading, login } = useLogin()
  const [, setToken] = useAuthContext()
  const abortController = useAbortController()

  useEffect(function onMount() {
    analytics.track('Login / Dialog / View')
  }, [])

  async function handleLogin(email: string, password: string) {
    if (isLoading) return

    try {
      const carrier = await login(email, password, abortController)

      setToken?.(carrier!.accessToken)

      analytics.track('Login / Dialog / Success')

      onComplete()
    } catch (error) {
      const errorResponse = (error as HttpClientError<ServerError>)?.response

      analytics.track('Login / Dialog / Error', {
        status: errorResponse?.status,
        description: errorResponse?.data?.error_description,
      })
    }
  }

  function handleNotNow() {
    analytics.track('Login / Dialog / Not now')
    onCancel()
  }

  function handleForgotPassword() {
    window.location.href = '/#/reset-password'
  }

  return (
    <Dialog open>
      <Dialog.Header>Login to Loadsmart Carrier Platform</Dialog.Header>
      <Dialog.Body>
        <Layout.Stack>
          <LoginForm loading={isLoading} message={message} onLogin={handleLogin} />
          <Button variant="secondary" type="button" onClick={handleNotNow} disabled={isLoading}>
            NOT NOW
          </Button>
          <Layout.Stack gap="spacing-2">
            <Button variant="tertiary" type="button" onClick={handleForgotPassword}>
              Forgot your password?
            </Button>
            <Button variant="tertiary" type="button" onClick={onSignup}>
              Create an account
            </Button>
          </Layout.Stack>
        </Layout.Stack>
      </Dialog.Body>
    </Dialog>
  )
}

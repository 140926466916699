import styled from 'styled-components/macro'

import { IconPhone } from '@loadsmart/icons'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { LS_PHONE_NUMBER_MASK } from 'legacy/constants/contacts'
import { screen } from 'legacy/styles/screen'

const Wrapper = styled.div`
  display: none;
  border-bottom: 0;
  font-size: 12px;
  padding: 17px 20px 16px 15px;
  user-select: initial;

  ${screen.lg} {
    display: inline-block;
  }

  span {
    color: ${getToken('color-neutral-light')};
    font-weight: 500;
  }

  svg {
    vertical-align: middle;
  }

  svg path {
    fill: ${getToken('color-neutral-light')};
  }
`

export function Phone() {
  return (
    <Wrapper>
      <IconPhone width={14} height={14} />
      <span>{LS_PHONE_NUMBER_MASK}</span>
    </Wrapper>
  )
}

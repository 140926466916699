import { API_URL } from 'core/infra/environment'
import { HttpClient, type HttpClientConfig } from 'core/infra/http/HttpClient'

import sentry from 'legacy/utils/sentry'

import { CarrierRepository } from 'auth/infra/CarrierRepository'

type UpdateCarrierDependencies = {
  repository: CarrierRepository
}

export function updateCarrierFactory({ repository }: UpdateCarrierDependencies) {
  return async function updateCarrier(data: Object, config?: HttpClientConfig) {
    try {
      return await repository.updateCarrier({ data, config })
    } catch (error) {
      sentry.log(error)
      throw error
    }
  }
}

export function updateCarrier(data: Object, config?: HttpClientConfig) {
  const httpClient = new HttpClient(API_URL)
  const repository = new CarrierRepository({ httpClient })
  const update = updateCarrierFactory({ repository })

  return update(data, config)
}

import { type ServerError } from 'core/domain/Request'
import { API_URL } from 'core/infra/environment'
import { HttpClient, type HttpClientError } from 'core/infra/http/HttpClient'

import sentry from 'legacy/utils/sentry'
import storage from 'legacy/utils/storage'

import { type CarrierToken } from '../domain/CarrierLogin'
import { PendingApprovalError } from '../errors/PendingApprovalError'
import { PendingEmailVerificationError } from '../errors/PendingEmailVerificationError'
import { AccountRepository } from '../infra/AccountRepository'

export type StorageRepository = typeof storage

export type CarrierLoginFactoryDependencies = {
  accountRepository: AccountRepository
  storageRepository: StorageRepository
}

function setTokenInfo(carrierToken: CarrierToken, storageRepository: StorageRepository) {
  storageRepository.set('loadSmart.user-token', carrierToken.accessToken)
  storageRepository.set('loadSmart.user-tokenCreation', new Date().getTime().toString())
}

export function carrierLoginFactory({
  accountRepository,
  storageRepository,
}: CarrierLoginFactoryDependencies) {
  return async function (email: string, password: string, config?: { signal?: AbortSignal }) {
    try {
      const token = await accountRepository.login(email, password, config)
      setTokenInfo(token, storageRepository)

      return token
    } catch (error) {
      const loginError = (error as HttpClientError<ServerError>)?.response

      const unauthorizedError = loginError?.status === 401
      const forbiddenError = loginError?.status === 403

      if (forbiddenError) {
        const error = loginError?.data?.error

        if (error === 'pending_email_verification') {
          throw new PendingEmailVerificationError()
        }

        if (error === 'pending_approval') {
          throw new PendingApprovalError()
        }
      }

      if (!unauthorizedError) {
        const newError = new Error('Failed to login', { cause: error })
        sentry.log(newError)
      }

      throw error
    }
  }
}

export function carrierLogin(email: string, password: string, config?: { signal?: AbortSignal }) {
  const httpClient = new HttpClient(API_URL)
  const accountRepository = new AccountRepository({ httpClient })
  const storageRepository = storage
  const login = carrierLoginFactory({ accountRepository, storageRepository })

  return login(email, password, config)
}

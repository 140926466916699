import numeral from 'numeral'

function formatCurrency(price, cents = false, currencyPrefix = '$') {
  let value = ''

  if (cents && (price || price === 0)) {
    value = numeral(price).format('0,0.00')
  } else if (!cents && (price || price === 0)) {
    value = numeral(price).format('0,0')
  }

  return value ? `${currencyPrefix}${value}` : ''
}

export { formatCurrency }

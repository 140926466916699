import { FirebaseClient } from 'core/infra/FirebaseClient'

import { ConfigAdapter } from '../infra/ConfigAdapter'
import { FirebaseConfigClient } from '../infra/FirebaseConfigClient'

type FetchRemoteConfigsParams = {
  configClient: ConfigAdapter
}

export function fetchRemoteConfigsFactory({ configClient }: FetchRemoteConfigsParams) {
  return async function fetchConfigs() {
    return configClient.getRemoteConfigs()
  }
}

export function fetchRemoteConfigs() {
  const firebaseConfig = new FirebaseConfigClient(FirebaseClient.getInstance())
  const configClient = new ConfigAdapter(firebaseConfig)

  const fetchConfigs = fetchRemoteConfigsFactory({ configClient })

  return fetchConfigs()
}

import { API_URL } from 'core/infra/environment'
import { HttpClient, type HttpClientError } from 'core/infra/http/HttpClient'
import { LocalStorageClient } from 'core/infra/storage/LocalStorageClient'
import { type StorageInterface } from 'core/infra/storage/StorageInterface'

import { sentry } from 'legacy/utils/sentry'

import { type CarrierAccount } from '../domain/Carrier'
import { type SignUp, type SignUpErrors, validate } from '../domain/SignUp'
import { AccountRepository } from '../infra/AccountRepository'
import { HubspotRepository } from '../infra/HubspotRepository'

export type SignUpErrorParams = {
  validationErrors?: SignUpErrors
  serverError?: string
  status?: number
} & ErrorOptions

export class SignUpError extends Error {
  validationErrors?: SignUpErrors
  serverError?: string
  status?: number

  constructor({ validationErrors, serverError, status, ...config }: SignUpErrorParams) {
    super('Failed to SignUp Carrier', config)

    // For V8 only
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, SignUpError)
    }

    this.name = 'SignUpError'
    this.validationErrors = validationErrors
    this.serverError = serverError
    this.status = status
  }
}

export type CarrierSignUpFactoryDependencies = {
  accountRepository: AccountRepository
  hubspotRepository: HubspotRepository
  storage: StorageInterface
}

async function createAccountCarrier(
  accountRepository: AccountRepository,
  signUp: SignUp,
  config?: { signal?: AbortSignal }
) {
  try {
    return await accountRepository.createAccountCarrier(signUp, config)
  } catch (e) {
    const error = e as HttpClientError<any>

    let serverError

    try {
      if (error?.response?.data) {
        const { errors, error_description } = error?.response?.data

        if (errors) {
          const accountError = errors[Object.keys(errors)[0]]
          const key = Object.keys(accountError)[0]

          serverError = accountError[key]
        } else if (error_description) {
          serverError = error_description
        }
      }
    } catch {}

    serverError = serverError || 'SignUp could not be completed, please contact us.'
    const status = error?.response?.status

    throw new SignUpError({ serverError, status, cause: error })
  }
}

async function createAccountCarrierIntermodal(
  accountRepository: AccountRepository,
  signUp: SignUp,
  config?: { signal?: AbortSignal }
) {
  try {
    return await accountRepository.createAccountCarrierIntermodal(signUp, config)
  } catch (e) {
    const error = e as HttpClientError<any>

    let serverError

    if (error?.response?.status === 409 && error?.response?.data?.error_description) {
      serverError = error.response.data.error_description
    }

    serverError = serverError || 'SignUp could not be completed, please contact us.'

    throw new SignUpError({ serverError, cause: error })
  }
}

export function carrierSignUpFactory({
  accountRepository,
  hubspotRepository,
  storage,
}: CarrierSignUpFactoryDependencies) {
  return async function (signUp: SignUp, config?: { signal?: AbortSignal }) {
    const validationErrors = validate(signUp)

    if (validationErrors) throw new SignUpError({ validationErrors })

    let createdCarrier: CarrierAccount

    if (signUp.equipmentType === 'ftl') {
      createdCarrier = await createAccountCarrier(accountRepository, signUp, config)
    } else {
      createdCarrier = await createAccountCarrierIntermodal(accountRepository, signUp, config)
    }

    try {
      storage.set('ls-signup-time', new Date())

      await hubspotRepository.submitHubspot(signUp, config)
    } catch (error) {
      // Don't stop the flow if the 'create' request completed wihtout errors
      sentry.log(error)
    }

    return createdCarrier
  }
}

export function carrierSignUp(signUp: SignUp, config?: { signal?: AbortSignal }) {
  const accountRepository = new AccountRepository({ httpClient: new HttpClient(API_URL) })
  const hubspotRepository = new HubspotRepository({ httpClient: new HttpClient() })
  const storage = new LocalStorageClient()
  const newCarrierSignUp = carrierSignUpFactory({ accountRepository, hubspotRepository, storage })
  return newCarrierSignUp(signUp, config)
}

import { type RemoteConfigType } from '../domain/Config'

export interface ConfigClient {
  getConfigs(): Promise<RemoteConfigType>
}

export class ConfigAdapter {
  private client: ConfigClient

  constructor(client: ConfigClient) {
    this.client = client
  }

  async getRemoteConfigs(): Promise<RemoteConfigType> {
    return this.client.getConfigs()
  }
}

import styled from 'styled-components/macro'

import { Banner, Layout } from '@loadsmart/loadsmart-ui'

export const InputGroup = styled(Layout.Stack).attrs({ space: 'none' })`
  flex-grow: 1;
  text-align: left;
`

export const StyledBanner = styled(Banner)`
  min-width: 0;
`

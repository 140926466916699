/**
 * Produces array for non-array value.
 *
 * @param value Value to be converted/returned.
 * @return Returns `value` itself if it is an array or an array containing the
 *         provided `value`. If `value` is either `null` or `undefined`, returns
 *         an empty array.
 */
export function toArray<T>(value?: T | Array<T> | null): Array<T> {
  if (value == null) {
    return []
  }

  if (Array.isArray(value)) {
    return value
  }

  return [value]
}

/**
 * Checks if two arrays are equal by comparing their elements.
 * The function performs a shallow comparison and works with arrays of any type.
 *
 * @param arr1 The first array to compare.
 * @param arr2 The second array to compare.
 * @returns Returns `true` if the arrays are equal, `false` otherwise.
 */
export function arraysAreEqual(
  arr1: ReadonlyArray<unknown>,
  arr2: ReadonlyArray<unknown>
): boolean {
  if (arr1.length !== arr2.length) return false

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }

  return true
}

/**
 * Checks if an array (or item that can be converted to array) is empty.
 *
 * @param value Value to be evaluated as array.
 * @return Returns true if the array is empty or null.
 */
export function isEmpty<T>(value?: T | Array<T> | null): boolean {
  return toArray(value).length === 0
}

import styled from 'styled-components/macro'

import LogoImage from 'legacy/assets/svg/icons/icon_logo_loadsmart.svg'
import { CARRIER_DEFAULT_HOME } from 'legacy/constants/tabs'

interface LogoLinkProps {
  isVisible?: boolean
}

const LogoLink = styled.a<LogoLinkProps>`
  transition: all 300ms ease-in;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`

interface LogoProps {
  isVisible?: boolean
}

export function Logo(props: LogoProps) {
  const { isVisible = true, ...restProps } = props

  return (
    <LogoLink isVisible={isVisible} href={CARRIER_DEFAULT_HOME} {...restProps}>
      <h1>
        <img src={LogoImage} alt="Loadsmart" />
      </h1>
    </LogoLink>
  )
}

import {
  IconTruckDryvan,
  IconTruckFlatbed,
  IconTruckReefer,
  IconTruckConestoga,
  IconTruckCourtainSide,
  IconTruckDoubleDrop,
  IconContainer,
  IconTruckStraight,
  IconTruckLtlDryvan,
} from '@loadsmart/icons'

export const ALL_EQUIPMENT_TYPES = {
  DRV: { value: 'DRV', longvalue: 'dryvan', label: 'Dry Van', icon: <IconTruckDryvan /> },
  FBE: { value: 'FBE', longvalue: 'flatbed', label: 'Flatbed', icon: <IconTruckFlatbed /> },
  RFR: { value: 'RFR', longvalue: 'reefer', label: 'Reefer', icon: <IconTruckReefer /> },
  CON: { value: 'CON', longvalue: 'conestoga', label: 'Conestoga', icon: <IconTruckConestoga /> },
  CUR: {
    value: 'CUR',
    longvalue: 'curtainside',
    label: 'Curtainside',
    icon: <IconTruckCourtainSide />,
  },
  DDP: {
    value: 'DDP',
    longvalue: 'double_drop',
    label: 'Double Drop',
    icon: <IconTruckDoubleDrop />,
  },
  IMC: { value: 'IMC', longvalue: 'intermodal', label: 'Intermodal', icon: <IconContainer /> },
  SDK: { value: 'SDK', longvalue: 'step_deck', label: 'Step Deck', icon: <IconTruckFlatbed /> },
  STK: {
    value: 'STK',
    longvalue: 'straight_truck',
    label: 'Straight Truck',
    icon: <IconTruckStraight />,
  },
  LTL: {
    value: 'LTL',
    longvalue: 'less_than_truckload',
    label: 'Less than Truckload',
    icon: <IconTruckLtlDryvan />,
  },
}

// Values are coming from utils/intermodal.js
export const ALL_INTERMODAL_EQUIPMENT_TYPES = {
  R0: {
    value: 'R0',
    label: 'Reefer',
  },
  G0: {
    value: 'G0',
    label: 'Dry Box',
  },
}

export const EQUIPMENT_SUBTYPES = {
  regular_dryvan: 'Regular Dry van',
  vented_van: 'Vented Van',
  dryvan_or_reefer: 'Dry Van or Reefer',
  cargo_van: 'Cargo Van',
  sprinter: 'Sprinter',
  straight_truck: 'Straight Truck',
  regular_reefer: 'Regular Reefer',
  space_saver_reefer: 'Space Saver Reefer',
  regular_flatbed: 'Regular Flatbed',
  conestoga: 'Conestoga',
  step_deck: 'Step Deck',
  hotshot: 'Hotshot',
  double_drop: 'Double Drop',
  gooseneck: 'Gooseneck',
  low_boy: 'Low Boy',
  maxi: 'Maxi',
  power_only: 'Power Only',
  removable_gooseneck: 'Removable Gooseneck',
}

export const ALL_MODES = {
  FTL: { value: 'FTL', longvalue: 'full_truckload', label: 'Full Truckload' },
  LTL: { value: 'LTL', longvalue: 'less_than_truckload', label: 'Less than Truckload' },
  RAIL: { value: 'RAIL', longvalue: 'rail', label: 'Rail' },
  FCL: { value: 'FCL', longvalue: 'full_container_load', label: 'Full container load' },
  PTL: { value: 'PTL', longvalue: 'partial_truckload', label: 'Partial Truckload' },
}

import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Button, Layout } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import ImgEmptyLoadOffer from 'legacy/assets/img/empty-load-offers.png'
import { PageWrapper } from 'legacy/components/PageWrapper/PageWrapper'
import { analytics } from 'legacy/utils/analytics'

import { Container } from '../Container'

export const ErrorContainer = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${getToken('space-xl')};
`

export function PageNotFound() {
  useEffect(() => {
    analytics.track('Page Not Found')
  }, [])

  return (
    <PageWrapper>
      <ErrorContainer>
        <Layout.Stack align="center" justify="center" space="l">
          <img src={ImgEmptyLoadOffer} alt="No related loads found" width="141" height="96" />
          <h1>Page Not Found</h1>
          <p>It seems the page you were looking for does not exist</p>
          {/* 
          // @ts-expect-error  */}
          <Button as={Link} to="/" replace>
            Go back
          </Button>
        </Layout.Stack>
      </ErrorContainer>
    </PageWrapper>
  )
}

export type Regions = Array<string>

export type State = {
  name: string
  abbv: string
  region: string
  selected: boolean
}

export type StateMapping = {
  [key: string]: State
}

export function setSelectedStates(states: StateMapping, regions?: Regions): StateMapping {
  if (!regions) return states

  const selectedStates = regions
    .filter(r => Boolean(r))
    .map<State>(abbv => ({
      ...states[abbv.toLowerCase()],
      selected: !states[abbv.toLowerCase()].selected,
    }))
    .reduce<StateMapping>((acc, cur) => {
      acc[cur.abbv.toLowerCase()] = cur
      return acc
    }, {})

  return { ...states, ...selectedStates }
}

export function getSelectedRegions(states: StateMapping): Regions {
  return Object.values(states)
    .filter(state => state.selected === true)
    .map<string>(state => state.abbv)
}

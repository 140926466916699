import { getAccountV2 } from 'legacy/services/account'
import sentry from 'legacy/utils/sentry'

type getUserAccountDeps = {
  userRepository: any
}

export async function getUserAccount({ userRepository }: getUserAccountDeps) {
  try {
    /** TODO: refactor this in the future to use
     * service/repository as dependency */
    const {
      data: { data: account },
    }: any = await getAccountV2()

    userRepository.setAccountInfo(account)

    return account
  } catch (e) {
    sentry.log(e)
    userRepository.logout()
  }
}

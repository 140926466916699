import styled, { css } from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { INPUT_HEIGHT, InputClearContainer } from 'legacy/components/InputV2/Input'
import { SelectContainer } from 'legacy/components/Select/Select'
import { screen } from 'legacy/styles/screen'

const BREAKPOINT = screen.md

export const InputGroupContainer = styled.div`
  display: flex;
  align-items: stretch;
`

export const InputGroupInnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${getToken('space-m')};

  ${BREAKPOINT} {
    flex-wrap: nowrap;
  }
`

const firstChildStyle = css`
  input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    ${({ hasAppendedContent }) =>
      hasAppendedContent
        ? css`
            border-top-right-radius: 0;
          `
        : ''};
  }

  ${BREAKPOINT} {
    input {
      border-top-right-radius: 0;
      border-bottom-left-radius: 4px;

      &[data-is-suggestions-open='true'] {
        border-bottom-left-radius: 0px;
      }
    }

    ${InputClearContainer} {
      right: 10px !important;
    }

    ${SelectContainer} {
      ${InputClearContainer} {
        right: 5px !important;
      }
    }
  }
`

const middleChildStyle = css`
  margin-top: -1px;

  input {
    border-radius: 0;

    ${({ hasAppendedContent }) =>
      hasAppendedContent
        ? css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          `
        : ''};
  }

  ${BREAKPOINT} {
    margin-top: 0;
    margin-left: -1px;
  }
`

const lastChildStyle = css`
  margin-top: -1px;

  input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    ${({ hasAppendedContent }) =>
      hasAppendedContent
        ? css`
            border-bottom-right-radius: 0;
          `
        : ''};
  }

  ${BREAKPOINT} {
    margin-top: 0;
    margin-left: -1px;

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 4px;

      ${({ hasAppendedContent }) =>
        hasAppendedContent
          ? css`
              border-top-right-radius: 0;
            `
          : ''};
    }
  }
`

export const InputGroupWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  input {
    &:focus {
      z-index: 1;
    }
  }

  &:first-child {
    ${firstChildStyle};
  }

  &:not(:first-child):not(:last-child) {
    ${middleChildStyle};
  }

  &:last-child {
    ${lastChildStyle};
  }
`

export const AppendedContentWrapper = styled.div`
  flex-shrink: 0;
  margin-left: -1px;

  button {
    border-color: ${getToken('color-neutral-light')};
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;
  }

  ${BREAKPOINT} {
    button {
      max-height: ${INPUT_HEIGHT}px;
    }
  }
`

const USStates = [
  { name: 'Alabama', abbv: 'AL' },
  { name: 'Alaska', abbv: 'AK' },
  { name: 'Arizona', abbv: 'AZ' },
  { name: 'Arkansas', abbv: 'AR' },
  { name: 'California', abbv: 'CA' },
  { name: 'Colorado', abbv: 'CO' },
  { name: 'Connecticut', abbv: 'CT' },
  { name: 'Delaware', abbv: 'DE' },
  { name: 'Washington D.C.', abbv: 'DC' },
  { name: 'Florida', abbv: 'FL' },
  { name: 'Georgia', abbv: 'GA' },
  { name: 'Hawaii', abbv: 'HI' },
  { name: 'Idaho', abbv: 'ID' },
  { name: 'Illinois', abbv: 'IL' },
  { name: 'Indiana', abbv: 'IN' },
  { name: 'Iowa', abbv: 'IA' },
  { name: 'Kansas', abbv: 'KS' },
  { name: 'Kentucky', abbv: 'KY' },
  { name: 'Louisiana', abbv: 'LA' },
  { name: 'Maine', abbv: 'ME' },
  { name: 'Maryland', abbv: 'MD' },
  { name: 'Massachusetts', abbv: 'MA' },
  { name: 'Michigan', abbv: 'MI' },
  { name: 'Minnesota', abbv: 'MN' },
  { name: 'Mississippi', abbv: 'MS' },
  { name: 'Missouri', abbv: 'MO' },
  { name: 'Montana', abbv: 'MT' },
  { name: 'Nebraska', abbv: 'NE' },
  { name: 'Nevada', abbv: 'NV' },
  { name: 'New Hampshire', abbv: 'NH' },
  { name: 'New Jersey', abbv: 'NJ' },
  { name: 'New Mexico', abbv: 'NM' },
  { name: 'New York', abbv: 'NY' },
  { name: 'North Carolina', abbv: 'NC' },
  { name: 'North Dakota', abbv: 'ND' },
  { name: 'Ohio', abbv: 'OH' },
  { name: 'Oklahoma', abbv: 'OK' },
  { name: 'Oregon', abbv: 'OR' },
  { name: 'Pennsylvania', abbv: 'PA' },
  { name: 'Rhode Island', abbv: 'RI' },
  { name: 'South Carolina', abbv: 'SC' },
  { name: 'South Dakota', abbv: 'SD' },
  { name: 'Tennessee', abbv: 'TN' },
  { name: 'Texas', abbv: 'TX' },
  { name: 'Utah', abbv: 'UT' },
  { name: 'Vermont', abbv: 'VT' },
  { name: 'Virginia', abbv: 'VA' },
  { name: 'Washington', abbv: 'WA' },
  { name: 'West Virginia', abbv: 'WV' },
  { name: 'Wisconsin', abbv: 'WI' },
  { name: 'Wyoming', abbv: 'WY' },
]

const WEST = ['AK', 'AZ', 'CA', 'CO', 'HI', 'ID', 'MT', 'NV', 'NM', 'OR', 'UT', 'WA', 'WY']
const SOUTH = ['AL', 'AR', 'FL', 'GA', 'LA', 'MS', 'NC', 'OK', 'SC', 'TN', 'TX']
const MIDWEST = ['IA', 'IL', 'IN', 'KS', 'KY', 'MI', 'MN', 'MO', 'ND', 'NE', 'OH', 'SD', 'WI']
const NORTHEAST = [
  'CT',
  'DE',
  'MA',
  'MD',
  'ME',
  'NH',
  'NJ',
  'NY',
  'PA',
  'RI',
  'VA',
  'VT',
  'WV',
  'DC',
]

const statesObject = USStates.reduce((acc, cur) => {
  acc[cur.abbv] = cur
  return acc
}, {})

const westStates = WEST.map(state => ({
  name: statesObject[state].name,
  abbv: state,
  region: 'west',
  selected: false,
}))
const southStates = SOUTH.map(state => ({
  name: statesObject[state].name,
  abbv: state,
  region: 'south',
  selected: false,
}))
const midwestStates = MIDWEST.map(state => ({
  name: statesObject[state].name,
  abbv: state,
  region: 'midwest',
  selected: false,
}))
const northeastStates = NORTHEAST.map(state => ({
  name: statesObject[state].name,
  abbv: state,
  region: 'northeast',
  selected: false,
}))

const allStates = [...westStates, ...southStates, ...midwestStates, ...northeastStates].reduce(
  (acc, cur) => {
    acc[cur.abbv.toLowerCase()] = cur
    return acc
  },
  {}
)

export const getRegion = region => {
  const regionMapping = {
    west: WEST,
    south: SOUTH,
    midwest: MIDWEST,
    northeast: NORTHEAST,
  }

  return regionMapping[region]
}

export default allStates

import parseISOFn from 'date-fns/parseISO'

export function parseISO(date, options = {}) {
  if (typeof date === 'string') {
    return parseISOFn(date, options)
  }

  if (date instanceof Date) {
    return date
  }

  throw new TypeError('Invalid date format')
}

export default parseISO

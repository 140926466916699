import { useCallback, useState } from 'react'
import styled from 'styled-components/macro'

import { Drawer } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { useEventMessage } from 'core/hooks/useEventMessage'

import { screen } from 'legacy/styles/screen'
import { analytics } from 'legacy/utils/analytics'

import { ConfigProvider } from 'config/ui/ConfigContext'
import { ConfigRender } from 'config/ui/ConfigRender'

import appsIcon from '../../assets/svg/icons/icon-apps.svg'
import kamionIcon from '../../assets/svg/icons/icon-logo-kamion.svg'
import { DropdownMenu } from './DropdownMenu'
import { NavigationItem } from './NavigationItem'

const IconWrapper = styled.div`
  margin-top: ${getToken('space-xs')};
  .apps-icon {
    color: ${getToken('color-neutral-white')};
    margin-left: ${getToken('space-s')};
  }
  .kamion-icon {
    margin-right: ${getToken('space-m')};
    margin-top: ${getToken('space-m')};
  }
`

const StyledDrawer = styled(Drawer)`
  top: var(--navbar-height);
  > button {
    top: ${getToken('space-m')};
    left: ${getToken('space-m')};
  }
`

const StyledIframe = styled.iframe`
  border: none;
  height: calc(100% - var(--navbar-height));
`

const StyledMenuItem = styled(NavigationItem)`
  display: none;
  width: 100%;
  > a {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  ${screen.lg} {
    display: block;
  }
`

function AppsIcon() {
  return (
    <IconWrapper>
      <img className="apps-icon" src={appsIcon} width={32} height={32} alt="dots icon" />
    </IconWrapper>
  )
}

function KamionIcon() {
  return (
    <IconWrapper>
      <img className="kamion-icon" src={kamionIcon} width={24} height={24} alt="kamion logo" />
    </IconWrapper>
  )
}

export function Apps() {
  const [sidebarState, setSidebarState] = useState(false)
  const KAMION_URL = process.env.REACT_APP_KAMION_URL
    ? `${process.env.REACT_APP_KAMION_URL}/login?external=loadboard`
    : null

  const handleMessageEvent = useCallback((event: MessageEvent) => {
    if (event.origin === process.env.REACT_APP_KAMION_URL && event.data.status === 'ready') {
      setSidebarState(false)
    }
  }, [])

  function handleMouseOver() {
    analytics.track('Navibar / Viewed / Kamion')
  }

  useEventMessage(handleMessageEvent, false)

  return (
    <ConfigProvider>
      <ConfigRender configName="showKamionLink">
        <DropdownMenu title="Apps" image={<AppsIcon />} onHover={handleMouseOver}>
          <StyledMenuItem
            title="Kamion"
            icon={<KamionIcon />}
            onClick={() => setSidebarState(true)}
            url="/"
          />
        </DropdownMenu>
        <StyledDrawer open={sidebarState} onClose={() => setSidebarState(false)}>
          {KAMION_URL && (
            <StyledIframe title="Kamion login" src={KAMION_URL} hidden={!sidebarState} />
          )}
        </StyledDrawer>
      </ConfigRender>
    </ConfigProvider>
  )
}

import { DropdownMenu } from './DropdownMenu'
import { StyledMenuItem } from './Navigation.styles'

export function Contracts() {
  return (
    <DropdownMenu title="Contracts">
      <StyledMenuItem title="Loadsmart Contracts" url="/contracts" />
      <StyledMenuItem title="Shipper RFPs" url="/rfp" />
    </DropdownMenu>
  )
}

import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

const Input = styled.input`
  background-color: ${getToken('color-neutral-white')};
  border: 1px solid ${getToken('color-neutral-light')};
  cursor: pointer;
  margin-right: 6px;
  position: relative;
  width: 18px;

  &:before {
    border: 3px solid ${getToken('color-neutral')};
    border-right: none;
    border-top: none;
    content: ' ';
    height: 7px;
    left: 4px;
    position: absolute;
    transition: transform 100ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transform: rotate(-45deg) scale(0);
    top: 1px;
    visibility: visible;
    width: 11px;
    z-index: 1;
  }

  &:checked::before {
    transform: rotate(-45deg) scale(1);
  }

  &:after {
    background: white;
    border: 1px solid ${getToken('color-neutral-light')};
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 ${getToken('color-neutral')} inset;
    content: ' ';
    display: block;
    height: 18px;
    position: relative;
    top: -3px;
    visibility: visible;
    width: 18px;
  }
`

export const Checkbox = ({ className, ...props }) => {
  return <Input {...props} className={className} type="checkbox" />
}

Checkbox.defaultProps = {
  className: '',
}

Checkbox.propTypes = {
  className: PropTypes.string,
}

export default Checkbox

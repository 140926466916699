import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

export type DropdownMenuContextType =
  | {
      expanded: boolean
      collapse: () => void
      expand: () => void
      toggle: () => void
    }
  | undefined

export const DropdownMenuContext = createContext<DropdownMenuContextType>(undefined)
DropdownMenuContext.displayName = 'DropdownMenuContext'

export function useDropdownMenuContext() {
  const context = useContext(DropdownMenuContext)

  if (context === undefined) {
    throw new Error('useDropdownMenuContext must be used within a DropdownMenuContext')
  }

  return context
}

export function DropdownMenuProvider({ children }: { children?: React.ReactNode }) {
  const [expanded, setExpanded] = useState<boolean>(false)

  const collapse = useCallback(() => {
    setExpanded(false)
  }, [])

  const expand = useCallback(() => {
    setExpanded(true)
  }, [])

  const toggle = useCallback(() => {
    setExpanded(prevState => !prevState)
  }, [])

  const memoizedValue = useMemo(
    () => ({
      expanded,
      expand,
      collapse,
      toggle,
    }),
    [expanded, expand, collapse, toggle]
  )

  return (
    <DropdownMenuContext.Provider value={memoizedValue}>{children}</DropdownMenuContext.Provider>
  )
}

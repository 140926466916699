/**
 * Loads a script into the body of the document.
 *
 * @param src The URL of the script to be loaded
 * @returns Promise that resolves after the script being successfully loaded or
 * is rejected if there is an error loading the script.
 */
export function load(src: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')

    script.addEventListener('load', () => {
      resolve()
    })

    script.addEventListener('error', event => {
      reject(new Error('Failed to load script', { cause: event.error }))
    })

    script.setAttribute('src', src)

    document.body.appendChild(script)
  })
}

import { type TruckType } from 'core/domain/Truck'
import { type Weekday } from 'core/domain/Weekday'
import { getNumberFromString } from 'core/utils/number'

import { type Proposal } from 'rfp/domain/Proposal'
import { mapEquipmentTypeToTruckType } from 'rfp/infra/EquipmentTypeMapper'

import { type Channel } from '../domain/Channel'
import { type PreferredLane, getPreferredLaneKey, hasAllCoordinates } from '../domain/PreferredLane'

type RawPlace = {
  description: string
  latitude: number
  longitude: number
  radius: number
}

export type RawPreferredLane = {
  channel: Channel
  destination?: RawPlace
  equipment_types: TruckType[]
  expires_at: string | null
  origin?: RawPlace
  rate: number | null
  starts_at: string
  suggested_rate?: number
  uuid: string
  weekdays: Array<Weekday> | null
}

export function mapApiToPreferredLane(rawPreferredLane: RawPreferredLane): PreferredLane {
  if (!rawPreferredLane) {
    throw new TypeError('mapper:: missing preferred lane object')
  }

  return {
    channel: rawPreferredLane.channel,
    destination: rawPreferredLane.destination,
    equipmentTypes: rawPreferredLane.equipment_types,
    expiresAt: rawPreferredLane.expires_at || undefined,
    origin: rawPreferredLane.origin,
    rate: rawPreferredLane.rate ?? undefined,
    startsAt: rawPreferredLane.starts_at,
    suggestedRate: rawPreferredLane.suggested_rate,
    uuid: rawPreferredLane.uuid,
    weekdays: rawPreferredLane.weekdays ?? undefined,
  }
}

export function mapRfpProposalToPreferredLanes(proposal: Proposal): Array<PreferredLane> {
  const preferredLanes = proposal.lanes.map((lane): PreferredLane => {
    const equipmentType = mapEquipmentTypeToTruckType(lane.equipmentType)

    return {
      origin: {
        description: `${lane.originCity}, ${lane.originState}, ${lane.originCountry}`,
        latitude: lane.originLatitude ? Number(lane.originLatitude) : undefined,
        longitude: lane.originLongitude ? Number(lane.originLongitude) : undefined,
      },
      destination: {
        description: `${lane.destCity}, ${lane.destState}, ${lane.destCountry}`,
        latitude: lane.destLatitude ? Number(lane.destLatitude) : undefined,
        longitude: lane.destLongitude ? Number(lane.destLongitude) : undefined,
      },
      equipmentTypes: equipmentType ? [equipmentType] : undefined,
      rate: lane.laneProposal?.flatRate
        ? getNumberFromString(lane.laneProposal.flatRate) / 1000
        : undefined,
    }
  })

  // Equipment type is required, so we filter out the preferred lanes that don't have it
  // Turns out coordinates are also required
  const filteredPreferredLanes = preferredLanes.filter(preferredLane => {
    return Array.isArray(preferredLane.equipmentTypes) && hasAllCoordinates(preferredLane)
  })

  // This map removes duplicated lanes
  const mergedPreferredLanes = new Map<string, PreferredLane>()
  for (const preferredLane of filteredPreferredLanes) {
    mergedPreferredLanes.set(getPreferredLaneKey(preferredLane), preferredLane)
  }

  return Array.from(mergedPreferredLanes.values())
}

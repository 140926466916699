import { wait } from './promise'

export function isFunction(value?: unknown): value is Function {
  return typeof value === 'function'
}

export class RetryError extends Error {
  constructor(functionName: string, maximumAttemps: number, options?: ErrorOptions) {
    super(
      `The pre-condition for the "${functionName}" function failed ${maximumAttemps} times`,
      options
    )

    // For V8 only
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, RetryError)
    }

    this.name = 'RetryError'
  }
}

/**
 * Wraps a function into a helper that will invoke your function after the
 * `preCondition` returns a truthy value. This check will be done at maximum of
 * `maximumAttemps`, and the helper will wait for `retryAwaitTimeInMs` between
 * the checks.
 *
 * @param fn Function to be wrapped
 * @param preCondition Function that will called before calling the wrapped
 * function. It expects a truthy value to proceed.
 * @param maximumAttemps Maximum number of attemps. Default: 30.
 * @param retryAwaitTimeInMs Time to be awaited between the calls. Default: 1000.
 */
export function retry<P extends any[], R>(
  fn: (...args: P) => R,
  preCondition: () => boolean,
  maximumAttemps: number = 30,
  retryAwaitTimeInMs: number = 1000
): (...args: P) => Promise<R> {
  let remainingTries = maximumAttemps

  return async function retryWrapper(...args: P): Promise<R> {
    remainingTries -= 1
    if (preCondition()) {
      return fn(...args)
    } else if (remainingTries > 0) {
      await wait(retryAwaitTimeInMs)
      return retryWrapper(...args)
    } else {
      throw new RetryError(fn.name || 'anonymous', maximumAttemps)
    }
  }
}

import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Button } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import analytics from 'legacy/utils/analytics'

import { MenuList } from './Navigation.styles'

const ButtonWrapper = styled.div`
  margin-left: ${getToken('space-m')};
  display: flex;
  gap: ${getToken('space-s')};
`

const SignInButton = styled(Button)`
  border-color: ${getToken('color-neutral-white')};
  color: ${getToken('color-neutral-white')};
`

export function PublicMenu() {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const {
    params: { id } = { id: null },
    path,
    url,
  } = useRouteMatch<{ id: string }>({ path: '/rfp/:id' }) || {}

  function handleSignup() {
    analytics.track('Navibar / Click / Sign up', {
      id,
      path,
      url,
    })

    const currentQueryParams = new URLSearchParams(search)
    if (currentQueryParams.get('signup')) return // avoid adding duplicated queries on multiples button clicks
    currentQueryParams.append('signup', 'true')
    currentQueryParams.delete('login')
    history.push(`${pathname}?${currentQueryParams.toString()}`)
  }

  function handleLogin() {
    analytics.track('Navibar / Click / Login', {
      id,
      path,
      url,
    })

    const currentQueryParams = new URLSearchParams(search)
    if (currentQueryParams.get('login')) return // avoid adding duplicated queries on multiples button clicks
    currentQueryParams.append('login', 'true')
    currentQueryParams.delete('signup')
    history.push(`${pathname}?${currentQueryParams.toString()}`)
  }

  return (
    <MenuList>
      <ButtonWrapper>
        <Button variant="primary" scale="small" onClick={handleSignup}>
          Sign up with loadsmart
        </Button>
        <SignInButton scale="small" onClick={handleLogin}>
          Sign in
        </SignInButton>
      </ButtonWrapper>
    </MenuList>
  )
}

/* eslint-disable eqeqeq */

import styled from 'styled-components/macro'

import { screen } from 'legacy/styles/screen'

const relativeBreakPoint = {
  sm: null,
  md: 'sm',
  lg: 'md',
}

const getWidthValue = (breakpoint, widths) => {
  let width = null

  if (typeof widths === 'object' && breakpoint in widths) {
    width = widths[breakpoint]
  } else if (typeof widths === 'string') {
    width = widths
  }

  return width
}

const getWidth = (breakpoint, widths) => {
  let value = getWidthValue(breakpoint, widths)
  const sm = getWidthValue('sm', widths) || 1
  const relative = getWidthValue(relativeBreakPoint[breakpoint], widths)

  if (value === null && relative === null) {
    value = sm
  } else if (relative !== null) {
    value = relative
  }

  return `calc(100% * ${value})`
}

const getGap = (breakpoint, gaps) => {
  let gap = 0

  if (typeof gaps === 'object' && breakpoint in gaps) {
    gap = gaps[breakpoint]
  } else if (typeof gaps === 'string') {
    gap = gaps
  }

  return gap
}

const InlineBox = styled.div`
  vertical-align: top;

  display: ${({ width }) => (getWidthValue('sm', width) == 0 ? 'none' : 'inline-block')};
  padding-right: ${props => getGap('sm', props.gap)};
  text-align: ${props => props.align || 'left'};
  width: ${props => getWidth('sm', props.width)};

  ${screen.md} {
    display: ${({ width }) => (getWidthValue('md', width) == 0 ? 'none' : 'inline-block')};
    padding-right: ${props => getGap('md', props.gap)};
    width: ${props => getWidth('md', props.width)};
  }

  ${screen.lg} {
    display: ${({ width }) => (getWidthValue('lg', width) == 0 ? 'none' : 'inline-block')};
    padding-right: ${props => getGap('lg', props.gap)};
    width: ${props => getWidth('lg', props.width)};
  }
`

export function Grid({ className, ...props }) {
  let component = (
    <InlineBox {...props} className={className}>
      {props.children}
    </InlineBox>
  )

  return component
}

export default Grid

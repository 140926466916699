import React, { useEffect, useRef, type PropsWithChildren, type ReactElement } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components/macro'

import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { screen } from 'legacy/styles/screen'

import { DropdownMenuProvider, useDropdownMenuContext } from '../../contexts/DropdownMenuContext'
import { DropdownMenuTitle } from './DropdownMenuTitle'

export const Menu = styled.li<{ $selected?: boolean }>`
  cursor: pointer;
  display: inline-block;
  width: 100%;

  ${screen.lg} {
    width: auto;
  }

  background: ${({ $selected }) => ($selected ? toCSSValue('color-primary-60') : 'unset')};

  &:hover {
    background: ${toCSSValue('color-primary-60', 0.4)};
  }
`

export const Submenu = styled.ul<{ forceOpen?: boolean }>`
  background-color: ${toCSSValue('color-primary-100')};

  ${screen.lg} {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.14), 0 2px 5px rgba(0, 0, 0, 0.28);
    display: ${({ forceOpen }) => (forceOpen ? 'block' : 'none')};
    position: fixed;
    top: 50px;
    width: 200px;
    z-index: 10;
  }
`

export type DropdownMenuProps = {
  children: React.ReactNode
  image?: JSX.Element
  title: string
  onHover?: () => void
}

function Dropdown({ title, image, children, onHover }: DropdownMenuProps) {
  const { expanded, collapse, expand, toggle } = useDropdownMenuContext()
  const ref = useRef<HTMLLIElement | null>(null)

  const childrenURLs = React.Children.map(children, child => {
    const { props } = child as ReactElement<PropsWithChildren<{ url: string }>>
    return props.url
  })

  const selected = useRouteMatch(childrenURLs ?? [])

  function handleMouseEnter() {
    expand()
    onHover?.()
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current != null && !ref.current.contains(event.target as Node)) {
        collapse()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [collapse, ref])

  return (
    <Menu
      ref={ref}
      onClick={toggle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={collapse}
      $selected={Boolean(selected)}
      data-testid="navigation-dropdown-menu"
    >
      <DropdownMenuTitle title={title} image={image} />
      <Submenu forceOpen={expanded}>{children}</Submenu>
    </Menu>
  )
}

export function DropdownMenu({ children, image, title, onHover }: DropdownMenuProps) {
  return (
    <DropdownMenuProvider>
      <Dropdown image={image} title={title} onHover={onHover}>
        {children}
      </Dropdown>
    </DropdownMenuProvider>
  )
}

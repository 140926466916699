import { removeCookie } from 'core/infra/cookie'

import { storage } from './storage'

export const user = {
  avatarUrl: () => storage.get('loadSmart.user-avatarUrl'),
  email: () => storage.get('loadSmart.user-email'),
  firstName: () => storage.get('loadSmart.user-firstName'),
  lastName: () => storage.get('loadSmart.user-lastName'),
  token: () => storage.get('loadSmart.user-token'),
  companyName: () => storage.get('loadSmart.user-companyName'),
  daimlerVip: storage.get('loadSmart.user-daimlerVip'),
  type: storage.get('loadSmart.user-type'),
  role: storage.get('loadSmart.user-role'),
  onboardingComplete: storage.get('loadSmart.user-onboardingComplete'),
  companyId: storage.get('loadSmart.user-companyId'),
  mcNumber: storage.get('loadSmart.user-mcNumber'),
  verified: storage.get('loadSmart.user-verified'),
  w9Url: storage.get('loadSmart.user-w9Url'),
  insuranceUrl: storage.get('loadSmart.user-insuranceUrl'),
  authorityUrl: storage.get('loadSmart.user-authorityUrl'),
  packageUrl: storage.get('loadSmart.user-packageUrl'),
  w9Verified: storage.get('loadSmart.user-w9Verified'),
  set(key: string, value: string) {
    storage.set(`loadSmart.user-${key}`, value)
  },
  remove(key: string) {
    storage.remove(`loadSmart.user-${key}`)
  },
  get(key: string) {
    return storage.get(`loadSmart.user-${key}`)
  },
  isLoggedIn() {
    return storage.get(`loadSmart.user-token`) ? true : false
  },
  logout() {
    const onboardingComplete = storage.get('closedTermsAndConditions')

    storage.clear()

    storage.set('closedTermsAndConditions', JSON.stringify(onboardingComplete))

    removeCookie('lsUserToken')
  },
  setIsAliceLoginAs(isAliceLoginAs = true) {
    storage.set('loadSmart.user-isAliceLoginAs', JSON.stringify(isAliceLoginAs))
  },
  getIsAliceLoginAs() {
    return storage.get('loadSmart.user-isAliceLoginAs') === 'true' ? true : false
  },
}

export default user

type HotjarWindow = {
  hj?: any
  _hjSettings: any
  _scriptPath: string
} & Window &
  typeof globalThis

function init(
  container: HotjarWindow = window as HotjarWindow,
  windowDocument: Document = document,
  url: string = '//static.hotjar.com/c/hotjar-',
  urlSuffix: string = '.js?sv=',
  a?: HTMLHeadElement,
  r?: HTMLScriptElement
) {
  if (container.hj) return

  container.hj = function () {
    if (!container.hj!.q) {
      container.hj.q = []
      container.hj.q.push(arguments)
    }
  }

  container._hjSettings = { hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: 6 }

  container._scriptPath = url + container._hjSettings.hjid + urlSuffix + container._hjSettings.hjsv

  if (!document.querySelector('script[src*="' + container._scriptPath + '"]')) {
    a = windowDocument.getElementsByTagName('head')[0]
    r = windowDocument.createElement('script')
    r.async = true
    r.src = container._scriptPath
    a.appendChild(r)
  }
}

export default { init }

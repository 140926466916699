import Grid from 'legacy/components/Grid/Grid'

import MapSVG from './MapSVG'
import { Map, MapWrapper, Table } from './RegionsOfOperation.styles'
import TableBody from './TableBody'

type RegionsOfOperationProps = {
  selectedStates: {}
  onRegionSelection: (region: string) => void
  onStateSelection: (stateId: string) => void
}

export default function RegionsOfOperation({
  selectedStates,
  onRegionSelection,
  onStateSelection,
}: RegionsOfOperationProps) {
  return (
    <Map>
      <Grid width={{ sm: 0, lg: 1 / 3 }} className="">
        <MapWrapper>
          <MapSVG states={selectedStates} handleClick={e => onStateSelection(e.target.id)} />
        </MapWrapper>
      </Grid>
      <Grid width={{ lg: 2 / 3 }} className="">
        <Table>
          <thead>
            <tr>
              <th onClick={() => onRegionSelection('west')} data-testid="west">
                West
              </th>
              <th onClick={() => onRegionSelection('south')} data-testid="south">
                South
              </th>
              <th onClick={() => onRegionSelection('midwest')} data-testid="midwest">
                Midwest
              </th>
              <th onClick={() => onRegionSelection('northeast')} data-testid="northeast">
                Northeast
              </th>
            </tr>
          </thead>
          <tbody>
            <TableBody states={selectedStates} handleClick={e => onStateSelection(e.target.id)} />
          </tbody>
        </Table>
      </Grid>
    </Map>
  )
}

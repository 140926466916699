import PropTypes from 'prop-types'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { toast } from 'react-toastify'
import styled, { css } from 'styled-components/macro'

import { IconArrowRight, IconPlus, IconMinus } from '@loadsmart/icons'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { Layout, Dialog } from '@loadsmart/miranda-react'

import { I18n } from 'legacy/components/I18n/i18n'
import { updatePreferredLaneRate } from 'legacy/services/preferredLanes'
import { analytics } from 'legacy/utils/analytics'

import { formatRate } from '../formatRate'
import { SuggestedRateBanner } from '../SuggestedRateBanner'
import { useSuggestedRate } from '../useSuggestedRate'
import { RATE_ACTIONS, rateReducer, rateReducerInit } from './rateReducer'

const INITIAL_RATE = 0

const LaneWrapper = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${getToken('space-m')};

  background-color: ${getToken('color-neutral-lighter')};
  border-radius: ${getToken('border-radius-m')};
`

const Message = styled.div`
  margin: ${getToken('space-xl')};

  font-weight: ${getToken('font-weight-bold')};
  font-size: ${getToken('font-size-3')};
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StyledPriceInput = styled.input`
  width: 200px;
  padding-bottom: 4px;

  color: ${({ hasError }) =>
    hasError ? getToken('color-danger') : getToken('color-neutral-darker')};
  font-weight: bold;
  font-size: 44px;
  text-align: center;

  background: none;
  border: none;
  border-bottom: 1px solid
    ${({ hasError }) => (hasError ? getToken('color-danger') : getToken('color-neutral-darker'))};
  outline: none;

  transition: color 100ms, border-bottom-color 100ms;

  resize: none;
`

const changeBidIconStyle = css`
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  padding: 6px;

  color: ${getToken('color-primary')};

  border: 1px solid ${getToken('color-neutral-dark')};
  border-radius: 50%;
  cursor: pointer;

  transition: opacity 100ms, background-color 120ms, color 120ms;

  :active {
    color: ${getToken('color-neutral-white')};

    background-color: ${getToken('color-primary')};
    border-color: ${getToken('color-neutral-white')};
  }
`

const StyledIconMinus = styled(IconMinus)`
  ${changeBidIconStyle};
`

const StyledIconPlus = styled(IconPlus)`
  ${changeBidIconStyle};
`

function PreferredLaneRateModal({
  preferredLaneId,
  lane,
  initialRate,
  onClose,
  type,
  analyticsPrefix,
}) {
  const [{ rate, hasError }, rateDispatcher] = useReducer(rateReducer, initialRate, rateReducerInit)
  const [isLoading, setLoading] = useState(false)
  const { suggestedRate, getSuggestedRate, isLoadingSuggestedRate } = useSuggestedRate(
    lane.suggested_rate
  )

  const formattedRate = formatRate(rate)
  const hasChangedRate = initialRate !== rate

  useEffect(() => {
    analytics.track('Preferred Lane / Rate Dialog')
  }, [])

  const handleConfirm = useCallback(() => {
    setLoading(true)

    analytics.track(
      `${analyticsPrefix} / Preferred Lane / Rate Dialog / ${
        type === 'edit' ? 'Edit' : 'Add'
      } rate`,
      {
        lane,
        rate,
        suggestedRate,
      }
    )

    updatePreferredLaneRate(preferredLaneId, rate)
      .then(({ data }) => data)
      .catch(() => {
        toast.error(I18n.get('ls.components.PreferredLaneRateModal.error'))
      })
      .then(updatedPreferredLane => {
        setLoading(false)
        if (typeof onClose === 'function') {
          onClose({ confirmed: true, updatedPreferredLane })
        }
      })
  }, [analyticsPrefix, type, lane, rate, suggestedRate, preferredLaneId, onClose])

  const handleCancel = useCallback(() => {
    analytics.track(`${analyticsPrefix} / Preferred Lane / Rate Dialog / Dismiss`)

    if (typeof onClose === 'function') {
      onClose({ confirmed: false })
    }
  }, [onClose, analyticsPrefix])

  const handleIncrement = useCallback(() => {
    analytics.track(`${analyticsPrefix} / Preferred Lane / Rate Dialog / Increase`)

    rateDispatcher({ type: RATE_ACTIONS.INCREMENT_RATE_PRICE })
  }, [analyticsPrefix])

  const handleDecrement = useCallback(() => {
    analytics.track(`${analyticsPrefix} / Preferred Lane / Rate Dialog / Decrease`)

    rateDispatcher({ type: RATE_ACTIONS.DECREMENT_RATE_PRICE })
  }, [analyticsPrefix])

  const handleChangeRate = useCallback(event => {
    rateDispatcher({ type: RATE_ACTIONS.SET_RATE_PRICE, payload: event.target.value })
  }, [])

  function getRateCancelLabel(type) {
    if (type === 'new') return I18n.get('ls.components.PreferredLaneRateModal.notNow')
    return I18n.get('ls.components.PreferredLaneRateModal.cancel')
  }

  function getRateConfirmLabel(type) {
    if (type === 'edit') return I18n.get('ls.components.PreferredLaneRateModal.editRate')
    return I18n.get('ls.components.PreferredLaneRateModal.addRate')
  }

  useEffect(() => {
    if (suggestedRate > 0) return
    getSuggestedRate(lane)
  }, [getSuggestedRate, lane, suggestedRate])

  useEffect(() => {
    if (rate > 0) return
    rateDispatcher({ type: RATE_ACTIONS.SET_RATE_PRICE, payload: suggestedRate })
  }, [rate, suggestedRate])

  return (
    <Dialog open size="medium">
      <Dialog.Header>
        {type === 'new' && I18n.get('ls.components.PreferredLaneRateModal.header')}
        {type === 'add' && I18n.get('ls.components.PreferredLaneRateModal.addRate')}
        {type === 'edit' && I18n.get('ls.components.PreferredLaneRateModal.editRate')}
      </Dialog.Header>
      <Dialog.Body>
        <LaneWrapper>
          <span>
            {lane.origin?.description ?? I18n.get('ls.components.PreferredLaneRateModal.anywhere')}
          </span>
          <IconArrowRight width={12} height={12} />
          <span>
            {lane.destination?.description ??
              I18n.get('ls.components.PreferredLaneRateModal.anywhere')}
          </span>
        </LaneWrapper>
        <Layout.Box padding="none" textAlign="center">
          <Message>{I18n.get('ls.components.PreferredLaneRateModal.message')}</Message>
        </Layout.Box>
        <InputWrapper>
          <StyledIconMinus data-testid="decrement" onClick={handleDecrement} role="button" />
          <StyledPriceInput
            data-testid="rate"
            value={formattedRate}
            onChange={handleChangeRate}
            hasError={hasError}
            aria-label="rate value"
            autoFocus
          />
          <StyledIconPlus data-testid="increment" onClick={handleIncrement} role="button" />
        </InputWrapper>
        {suggestedRate !== null && (
          <SuggestedRateBanner rate={suggestedRate} loading={isLoadingSuggestedRate} />
        )}
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionSecondary onClick={handleCancel}>
          {getRateCancelLabel(type)}
        </Dialog.ActionSecondary>
        <Dialog.ActionPrimary
          data-testid="confirm-rate"
          onClick={handleConfirm}
          disabled={(type === 'edit' && !hasChangedRate) || hasError}
          loading={isLoading}
        >
          {getRateConfirmLabel(type)}
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </Dialog>
  )
}

PreferredLaneRateModal.propTypes = {
  preferredLaneId: PropTypes.string.isRequired,
  lane: PropTypes.shape({
    origin: PropTypes.shape({
      description: PropTypes.string,
    }),
    destination: PropTypes.shape({
      description: PropTypes.string,
    }),
  }).isRequired,
  initialRate: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['new', 'add', 'edit']),
  analyticsPrefix: PropTypes.string.isRequired,
}

PreferredLaneRateModal.defaultProps = {
  initialRate: INITIAL_RATE,
  type: 'new',
}

export { PreferredLaneRateModal }

import { API_URL } from 'core/infra/environment'
import { HttpClient } from 'core/infra/http/HttpClient'

import { sentry } from 'legacy/utils/sentry'

import { PreferredLaneRepository } from '../infra/PreferredLaneRepository'

export type DeletePreferredLaneDeps = {
  repository: PreferredLaneRepository
}

export function deletePreferredLaneFactory({ repository }: DeletePreferredLaneDeps) {
  return async function deletePreferredLaneImpl(
    preferredLaneId: string,
    config?: { signal?: AbortSignal }
  ) {
    try {
      return await repository.deletePreferredLane(preferredLaneId, config)
    } catch (error) {
      const newError = new Error('Could not delete preferred lane', { cause: error })
      sentry.log(newError)
      throw newError
    }
  }
}

export function deletePreferredLane(preferredLaneId: string, config?: { signal?: AbortSignal }) {
  const httpClient = new HttpClient(API_URL)
  const repository = new PreferredLaneRepository({ httpClient })
  const newDeletePreferredLane = deletePreferredLaneFactory({ repository })

  return newDeletePreferredLane(preferredLaneId, config)
}

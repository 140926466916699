import { Redirect, Route, type RouteProps } from 'react-router-dom'

import { user } from 'legacy/utils/user'

import { type EntityType } from '../../../domain/EntityType'
import { HashedUrl } from '../../../infra/HashedUrl'
import { stringifyQueryString } from '../../../infra/queryString'
import { PageNotFound } from '../../router/PageNotFound'

type PrivateRouteProps = {
  entitiesNotAllowed?: Array<EntityType>
} & RouteProps

export function PrivateRoute({ entitiesNotAllowed = [], ...props }: PrivateRouteProps) {
  const isAuthorized = user.isLoggedIn() && user.get('type') === 'carrier'
  const isEntityAllowed = !entitiesNotAllowed.includes(user.get('entityType') as EntityType)

  if (!isAuthorized) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: stringifyQueryString({ href: HashedUrl.fromCurrentHref().pathname }),
        }}
      />
    )
  }

  if (!isEntityAllowed) {
    return <PageNotFound />
  }

  return <Route {...props} />
}

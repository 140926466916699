import { type EntityType } from 'core/domain/EntityType'
import { isEmptyObject } from 'core/utils/object'
import { isEmpty } from 'core/utils/string'

import { isEmailValid } from './User'

export type SignUp = {
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirmation: string
  dotNumber: string
  mcNumber: string
  equipmentType: 'ftl' | 'drayage'
  scac?: string
  uiia?: boolean
  emodal?: boolean
  entityType?: EntityType
}

export type SignUpErrors = {
  [key in keyof Partial<SignUp>]: string
}

function validateName(signup: SignUp): SignUpErrors {
  const result: SignUpErrors = {}

  if (signup.firstName == null || isEmpty(signup.firstName)) {
    result.firstName = 'Required field'
  }

  if (signup.lastName == null || isEmpty(signup.lastName)) {
    result.lastName = 'Required field'
  }

  return result
}

function validatePassword(signup: SignUp): SignUpErrors {
  const result: SignUpErrors = {}

  if (signup.password == null || isEmpty(signup.password)) {
    // NOSONAR
    result.password = 'Required field'
  } else if (signup.password.length < 8) {
    // NOSONAR
    result.password = 'Must be at least 8 characters'
  }

  if (signup.passwordConfirmation == null || isEmpty(signup.passwordConfirmation)) {
    // NOSONAR
    result.passwordConfirmation = 'Required field'
  } else if (signup.password !== signup.passwordConfirmation) {
    // NOSONAR
    result.passwordConfirmation = 'Passwords do not match'
  }

  return result
}

function validateEmail(signup: SignUp): SignUpErrors {
  const result: SignUpErrors = {}

  if (signup.email == null || isEmpty(signup.email)) {
    result.email = 'Required field'
  } else if (!isEmailValid(signup.email)) {
    result.email = 'Must be a valid email'
  }

  return result
}

function validateOperatingAuthority(signup: SignUp): SignUpErrors {
  const result: SignUpErrors = {}

  if (signup.dotNumber == null || isEmpty(signup.dotNumber)) {
    result.dotNumber = 'Required field'
  }

  if (signup.mcNumber == null || isEmpty(signup.mcNumber)) {
    result.mcNumber = 'Required field'
  }

  return result
}

function validateEquipmentType(signup: SignUp): SignUpErrors {
  const result: SignUpErrors = {}

  if (signup.equipmentType == null || isEmpty(signup.equipmentType)) {
    result.equipmentType = 'You have to select your equipment'
  } else if (!['ftl', 'drayage'].includes(signup.equipmentType)) {
    result.equipmentType = 'Invalid option'
  }

  return result
}

function validateSCAC(signup: SignUp): SignUpErrors {
  const result: SignUpErrors = {}

  if (signup.scac == null || isEmpty(signup.scac)) {
    result.scac = 'Required field'
  } else if (signup.scac.length < 2) {
    result.scac = 'Must be at least 2 characters'
  } else if (signup.scac.length > 4) {
    result.scac = 'Must be at most 4 characters'
  } else if (!/^[a-zA-Z]+$/.test(signup.scac)) {
    result.scac = 'SCAC should contain only letters'
  }

  return result
}

function validateDrayage(signup: SignUp): SignUpErrors {
  const result: SignUpErrors = {
    ...validateSCAC(signup),
  }

  if (signup.uiia == null || !signup.uiia) {
    result.uiia = 'You need to have a valid UIIA'
  }

  if (signup.emodal == null || !signup.emodal) {
    result.emodal = 'Port access required. Please contact us.'
  }

  return result
}

export function validate(signup: SignUp): SignUpErrors | null {
  let result: SignUpErrors = {
    ...validateName(signup),
    ...validatePassword(signup),
    ...validateEmail(signup),
    ...validateOperatingAuthority(signup),
    ...validateEquipmentType(signup),
  }

  if (signup.equipmentType === 'drayage') {
    result = {
      ...result,
      ...validateDrayage(signup),
    }
  }

  if (isEmptyObject(result)) {
    return null
  }

  return result
}

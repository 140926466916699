import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { intSizes } from 'legacy/styles/screen'

import { useCarrierContext } from 'auth/ui/context/CarrierContext'

import { type SalesforceChatClient } from '../../infra/communication/SalesforceChatClient'
import { useWindowWidth } from '../hooks/useWindowWidth'

export type SalesforceChatContextValue = {
  isChatEnabled: boolean
  openChat: () => void
  showChatButton: () => void
  hideChatButton: () => void
  setRefNumber: (refNumber?: string) => void
  sendMessage: (message: string) => void
}

export const SalesforceChatContext = createContext<SalesforceChatContextValue | undefined>(
  undefined
)
SalesforceChatContext.displayName = 'SalesforceChatContext'

export type SalesforceChatProviderProps = {
  client: SalesforceChatClient
  children?: React.ReactNode
}

export function SalesforceChatProvider({ client, children }: SalesforceChatProviderProps) {
  const [isChatEnabled, setIsChatEnabled] = useState(client.isChatEnabled())
  const { carrier } = useCarrierContext()
  const windowWidth = useWindowWidth()
  const isBroker = carrier?.entityType === 'broker'
  const isSmallScreen = windowWidth < intSizes.md
  const shouldDisableChat = isBroker || isSmallScreen

  useEffect(() => {
    client.addEventListener('chatEnabled', setIsChatEnabled)

    return () => {
      client.removeEventListener('chatEnabled', setIsChatEnabled)
    }
  }, [client])

  useEffect(() => {
    if (shouldDisableChat) {
      client.hideChatButton()
    }

    return () => {
      if (shouldDisableChat) {
        client.showChatButton()
      }
    }
  }, [client, shouldDisableChat])

  const openChat = useCallback(() => {
    client.openChat()
  }, [client])

  const showChatButton = useCallback(() => {
    if (!shouldDisableChat) {
      client.showChatButton()
    }
  }, [client, shouldDisableChat])

  const hideChatButton = useCallback(() => {
    if (!shouldDisableChat) {
      client.hideChatButton()
    }
  }, [client, shouldDisableChat])

  const setRefNumber = useCallback(
    (refNumber?: string) => {
      client.setRefNumber(refNumber)
    },
    [client]
  )

  const sendMessage = useCallback(
    (message: string) => {
      client.sendMessage(message)
    },
    [client]
  )

  const value = useMemo(
    () => ({
      isChatEnabled,
      openChat,
      showChatButton,
      hideChatButton,
      setRefNumber,
      sendMessage,
    }),
    [isChatEnabled, openChat, showChatButton, hideChatButton, setRefNumber, sendMessage]
  )

  return <SalesforceChatContext.Provider value={value}>{children}</SalesforceChatContext.Provider>
}

export function useSalesforceChatContext(): SalesforceChatContextValue {
  const context = useContext(SalesforceChatContext)

  if (context === undefined) {
    throw new Error(`useSalesforceChatContext must be used within a SalesforceChatProvider`)
  }

  return context
}

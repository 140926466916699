import mixpanel from 'mixpanel-browser'
import queryString from 'query-string'

import { __DEV__ } from 'core/infra/environment'

import { storage } from 'legacy/utils/storage'
import { user } from 'legacy/utils/user'
import hotjar from 'legacy/vendors/hotjar'
import intercom from 'legacy/vendors/intercom'

function getQueryParams() {
  if (!window.location?.href) return {}

  const urlWithoutHash = new URL(window.location.href.replace('/#', ''))

  return queryString.parse(urlWithoutHash.search)
}

function getUtmParams() {
  const queryParams = getQueryParams()
  const campaignKeywords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
  const lastTouchedParams = {}
  const firstTouchedParams = {}

  campaignKeywords.forEach(campaignKeyword => {
    const queryParamValue = queryParams[campaignKeyword]

    if (queryParamValue) {
      const firstTouchKey = `${campaignKeyword} [first touch]`
      const lastTouchKey = `${campaignKeyword} [last touch]`

      firstTouchedParams[firstTouchKey] = queryParamValue
      lastTouchedParams[lastTouchKey] = queryParamValue
    }
  })

  return {
    lastTouchedParams,
    firstTouchedParams,
  }
}

export const analytics = {
  init() {
    hotjar.init()
    intercom.init()
    if (!this.isMixpanelEnabled()) return false
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
  },
  registerUtmParams() {
    const { lastTouchedParams, firstTouchedParams } = getUtmParams()

    mixpanel.people.set(lastTouchedParams)
    mixpanel.people.set_once(firstTouchedParams)
    mixpanel.register(lastTouchedParams)
  },
  track(label, obj) {
    if (!this.isMixpanelEnabled()) return false

    if (typeof mixpanel.track !== 'undefined') {
      mixpanel.track(label, obj)

      if (__DEV__) console.info('MIXPANEL EVENT:', label, obj || '')
    }
  },
  trackIntercom(event, metadata) {
    if (!this.isIntercomEnabled()) return

    window.Intercom('trackEvent', event, metadata)
  },
  setup() {
    const user = {
      Email: storage.get('loadSmart.user-email'),
      $first_name: storage.get('loadSmart.user-firstName'),
      $last_name: storage.get('loadSmart.user-lastName'),
      Company: storage.get('loadSmart.user-companyName'),
      companyId: storage.get('loadSmart.user-companyId'),
      CarrierFleetSize: storage.get('loadSmart.user-carrierFleetSize'),
      AccountRole: storage.get('loadSmart.user-role'),
      AccountType: storage.get('loadSmart.user-type'),
      hasPreferredLanesAccess: true,
      EntityType: storage.get('loadSmart.user-entityType'),
    }

    if (this.isMixpanelEnabled()) {
      mixpanel.register({
        ...user,
        app: 'carrier-web',
      })
      mixpanel.people.set(user)
      mixpanel.identify(user.Email)

      this.registerUtmParams()
    }

    if (this.isHotjarEnabled()) {
      const userID = user.Email
      const userData = {
        app: 'carrier-web',
        email: user.Email,
        first_name: user.$first_name,
        last_name: user.$last_name,
        company: user.Company,
        company_id: user.companyId,
        carrier_fleet_size: user.CarrierFleetSize,
        entity_type: user.EntityType,
      }
      window.hj('identify', userID, userData)
    }

    if (this.isIntercomEnabled()) {
      window.Intercom('boot', {
        app: 'carrier-web',
        app_id: window.intercomSettings.app_id,
        user_id: user.Email,
        email: user.Email,
        name: `${user.$first_name} ${user.$last_name}`,
        account_role: user.AccountRole,
        account_type: user.AccountType,
        hasPreferredLanesAccess: user.hasPreferredLanesAccess,
        company: {
          id: user.companyId,
          name: user.Company,
          carrier_fleet_size: user.CarrierFleetSize || 'NA',
          entity_type: user.EntityType,
        },
      })
    }
  },
  isDatadogSyntheticsBot() {
    return window._DATADOG_SYNTHETICS_BROWSER !== undefined
  },
  isE2EAccount() {
    const E2E_ACCOUNTS_PATTERN = /^e2e.test/i
    const email = user.get('email')

    return E2E_ACCOUNTS_PATTERN.test(email)
  },
  isE2ESession() {
    return this.isDatadogSyntheticsBot() || this.isE2EAccount()
  },
  isMixpanelEnabled() {
    if (!this.isE2ESession() && typeof mixpanel !== 'undefined') {
      return mixpanel.register
    }
  },
  isHotjarEnabled() {
    if (!this.isE2ESession() && typeof hj !== 'undefined') {
      return window.hj
    }
  },
  isIntercomEnabled() {
    if (!this.isE2ESession() && typeof Intercom !== 'undefined') {
      return window.Intercom
    }
  },
  tag(tags = []) {
    if (!this.isHotjarEnabled()) return

    window.hj('tagRecording', tags)
  },
  trigger(triggerName) {
    if (!this.isHotjarEnabled()) return
    window.hj('trigger', triggerName)
  },
  setProperty(properties) {
    if (this.isMixpanelEnabled()) {
      mixpanel.people.set_once(properties)
    }
  },
}

export default analytics

import { API_URL } from 'core/infra/environment'
import { HttpClient, type HttpClientConfig } from 'core/infra/http/HttpClient'

import sentry from 'legacy/utils/sentry'

import { CarrierRepository } from '../infra/CarrierRepository'

type GetCarrierDependencies = {
  repository: CarrierRepository
}

export function getCarrierFactory({ repository }: GetCarrierDependencies) {
  return async function getCarrierImpl(config?: HttpClientConfig) {
    try {
      return await repository.getCarrier()
    } catch (error) {
      sentry.log(error)
      throw error
    }
  }
}

export function getCarrier(config?: HttpClientConfig) {
  const httpClient = new HttpClient(API_URL)
  const repository = new CarrierRepository({ httpClient })
  const get = getCarrierFactory({ repository })

  return get(config)
}

import { type Account } from '../domain/Account'

type RawAccount = {
  first_name: string
  last_name: string
  onboarded: boolean
}

export function accountPayloadMapper(account: Account): RawAccount {
  return {
    first_name: account.firstName,
    last_name: account.lastName,
    onboarded: account.onboarded,
  }
}

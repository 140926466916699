function removeHash(url: string | URL): string {
  return url.toString().replace('/#/', '/')
}

/**
 * Class to work with the URL when using Hash Routing.
 */
export class HashedUrl extends URL {
  static fromCurrentHref(): HashedUrl {
    return new HashedUrl(window.location.href)
  }

  constructor(url: string | URL, base?: string | URL) {
    super(removeHash(url), base != null ? removeHash(base) : undefined)
  }

  get href(): string {
    // @ts-expect-error Have to call super here or it will be recursivelly called
    return super.href.replace(super.pathname, `/#${super.pathname}`)
  }

  set href(href: string) {
    // @ts-expect-error Have to call super here or it will be recursivelly called
    super.href = removeHash(href)
  }

  get pathname() {
    // @ts-expect-error Have to call super here or it will be recursivelly called
    return super.pathname
  }

  set pathname(pathname: string) {
    // @ts-expect-error Have to call super here or it will be recursivelly called
    super.pathname = removeHash(pathname)!
  }

  toString(): string {
    return this.href
  }

  toJSON(): string {
    return this.href
  }
}

import { useEffect, useState } from 'react'

import { Dialog, Text } from '@loadsmart/miranda-react'

import { useCookie } from 'core/ui/hooks/useCookie'

import { analytics } from 'legacy/utils/analytics'

import { AddPreferredLaneDialog } from 'preferred-lanes/ui/AddPreferredLaneDialog'
import { updateUserAccount } from 'user/use-cases/updateUserAccount'

import {
  OnboardingStep,
  REQUIRED_ONBOARDING_STEPS,
  getAdjacentSteps,
  showStep,
  isLastStep,
} from '../domain/Onboarding'
import { accountPayloadMapper } from '../infra/accountMapper'
import { formatStepTitle } from '../presenters/stepPresenter'
import RegionsOfOperationStep from './RegionsOfOperationStep'

interface OnboardingDialogProps {
  show: boolean
  firstName?: string
  lastName?: string
  onboardingSteps?: Array<OnboardingStep>
}

export const analyticsEvents = {
  onAddSuccess: 'Setup / Onboard / Add',
  onAddError: 'Setup / Onboard / Add / Error',
}

export function OnboardingDialog({
  show,
  firstName = '',
  lastName = '',
  onboardingSteps,
}: OnboardingDialogProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const pendingSteps = REQUIRED_ONBOARDING_STEPS.filter(step => onboardingSteps?.includes(step))
  const [currentStep, setCurrentStep] = useState<OnboardingStep>(pendingSteps[0])
  const [dismissDialogCookie, setDismissDialogCookie] = useCookie<boolean>(
    'onboarding.OnboardingDialog.dismiss'
  )

  useEffect(() => {
    setIsOpen(show && !dismissDialogCookie)
  }, [dismissDialogCookie, show])

  const handleStep = async (stepName: OnboardingStep, nextStepName?: OnboardingStep) => {
    if (nextStepName) setCurrentStep(nextStepName)

    if (isLastStep(pendingSteps, stepName)) {
      try {
        await updateUserAccount(
          accountPayloadMapper({
            firstName,
            lastName,
            onboarded: true,
          })
        )
        analytics.track(analyticsEvents.onAddSuccess)
        setIsOpen(false)
      } catch (error) {
        analytics.track(analyticsEvents.onAddError)
      }
    }
  }

  const handleDismiss = () => {
    setDismissDialogCookie(true)
    setIsOpen(false)
  }

  if (!currentStep) return null

  const [, nextStep] = getAdjacentSteps(pendingSteps, currentStep)
  const stepTitle = formatStepTitle(pendingSteps, currentStep)

  return (
    <Dialog
      open={isOpen}
      onClose={handleDismiss}
      size="extra-large"
      data-testid="onboarding-dialog"
    >
      <Dialog.Header>
        {stepTitle && (
          <Text variant="heading-md-bold" color="color-primary-60">
            {stepTitle}
          </Text>
        )}
        <Dialog.Close onClick={handleDismiss} data-testid="dismiss" />
      </Dialog.Header>
      {showStep(pendingSteps, currentStep, OnboardingStep.RegionsOfOperation) && (
        <RegionsOfOperationStep handleStep={handleStep} nextStep={nextStep} />
      )}
      {showStep(pendingSteps, currentStep, OnboardingStep.PreferredLanes) && (
        <AddPreferredLaneDialog handleStep={handleStep} nextStep={nextStep} />
      )}
    </Dialog>
  )
}

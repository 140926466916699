import { type StorageInterface } from 'core/infra/storage/StorageInterface'

import sentry from 'legacy/utils/sentry'

import { TOKEN_STORAGE_KEY } from '../domain/User'

type UserRepositoryDeps = {
  storage: StorageInterface
}

export interface UserRepository {
  getLocalToken(): string | undefined
}

export function createUserRepository({ storage }: UserRepositoryDeps): UserRepository {
  function getLocalToken() {
    try {
      return storage.get<string>(TOKEN_STORAGE_KEY)
    } catch (error) {
      sentry.log(error)
    }
  }

  return { getLocalToken }
}

import { useEffect } from 'react'

import { Button, Layout } from '@loadsmart/miranda-react'

import { PUBLIC_CARRIER_COOKIE, type BaseCarrier } from 'core/domain/Carrier'
import { RequestStatus } from 'core/domain/Request'
import { useAbortController } from 'core/ui/hooks/useAbortController'
import { useCookie } from 'core/ui/hooks/useCookie'

import { analytics } from 'legacy/utils/analytics'

import { type SignUp } from '../domain/SignUp'
import { useSignUp } from './hooks/useSignUp'
import { Dialog } from './SignUpDialog.styles'
import { SignUpForm } from './SignUpForm'
import { SignUpSuccessDialog } from './SignUpSuccessDialog'

export type SignUpDialogProps = {
  context?: string
  onCancel: () => void
  onComplete: () => void
  onLogin?: () => void
}

export function SignUpDialog({ context, onCancel, onComplete, onLogin }: SignUpDialogProps) {
  const { signUpCarrier, carrier, isLoading, status, error } = useSignUp()

  const [localCarrier] = useCookie<BaseCarrier>(PUBLIC_CARRIER_COOKIE)

  const abortController = useAbortController()

  const isSubmitted = status === RequestStatus.Done && Boolean(carrier)

  const initialValues: Partial<SignUp> = {
    entityType: localCarrier?.entityType,
  }

  useEffect(
    function onMount() {
      analytics.track('Signup / Dialog / View', {
        context,
      })
    },
    [context]
  )

  function handleSignUp(signUp: SignUp) {
    return signUpCarrier(signUp, abortController)
  }

  function handleCancel() {
    analytics.track('Signup / Carrier / Not now', { context })

    abortController.abort()
    onCancel()
  }

  if (isSubmitted) {
    return <SignUpSuccessDialog carrier={carrier!} onClose={onComplete} />
  }

  return (
    <Dialog open>
      <Dialog.Header>
        Sign up to our Carrier Platform and find more freight opportunities
      </Dialog.Header>
      <Dialog.Body>
        <Layout.Stack>
          <SignUpForm
            confirmButtonText="Finish your account"
            error={error}
            isLoading={isLoading}
            onSignUp={handleSignUp}
            context={context}
            initialValues={initialValues}
          />
          <Button variant="secondary" type="button" onClick={handleCancel} disabled={isLoading}>
            NOT NOW
          </Button>
          <Button variant="tertiary" type="button" onClick={onLogin}>
            Already have an account?
          </Button>
        </Layout.Stack>
      </Dialog.Body>
    </Dialog>
  )
}

import http from 'legacy/utils/http'
import requests from 'legacy/utils/requests'

export const getPreferredLanes = (params = {}) =>
  requests.get(`/api/v2/loadboard/preferred-lanes`, { params })

export const getExistingPreferredLane = (params = {}) =>
  requests.get(`/api/v2/loadboard/preferred-lanes/exists`, { params })

export const addPreferredLane = data =>
  requests.post(
    `/api/v2/loadboard/preferred-lanes`,
    JSON.stringify({ ...data, channel: 'carrier' }),
    http.getHeaders()
  )

export const deletePreferredLane = laneId =>
  requests.delete(`/api/v2/loadboard/preferred-lanes/${laneId}`)

export const updatePreferredLaneRate = (laneId, rate) =>
  requests.patch(`/api/v2/loadboard/preferred-lanes/${laneId}`, JSON.stringify({ rate }))

export const deletePreferredLaneRate = laneId =>
  requests.patch(`/api/v2/loadboard/preferred-lanes/${laneId}`, JSON.stringify({ rate: null }))

/**
 * Fetch the suggested rate for a preferred lane from our pricing algorithm
 * @param {object} data
 * @param {object} data.origin
 * @param {string} data.origin.description
 * @param {number} data.origin.latitude
 * @param {number} data.origin.longitude
 * @param {number} data.origin.radius
 * @param {object} data.delivery
 * @param {string} data.delivery.description
 * @param {number} data.delivery.latitude
 * @param {number} data.delivery.longitude
 * @param {number} data.delivery.radius
 * @param {string} data.equipment_type
 * @returns {{ suggested_rate: number }}
 */
export const getLaneSuggestedRate = data =>
  requests.post(`/api/v2/loadboard/preferred-lanes/suggested-rate`, data)

import { captureException, withScope } from '@sentry/react'
import { Component, type ErrorInfo, type ReactNode } from 'react'

import { getErrorLevel, isChunkLoadError, isNetworkError } from '../../../utils/error'
import { ErrorFallback, type ErrorFallbackProps } from './ErrorFallback'

type BoundaryError = Error & { cause?: Error }

type ErrorBoundaryProps = {
  fallback: (props: ErrorFallbackProps) => ReactNode
  children: ReactNode
  onReset?: () => void
}

type ErrorBoundaryState = {
  error?: BoundaryError
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps: Partial<ErrorBoundaryProps> = {
    fallback: ErrorFallback,
    onReset: () => window.location.reload(),
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      error: undefined,
    }
  }

  static getDerivedStateFromError(error: BoundaryError) {
    return { error }
  }

  componentDidCatch(error: BoundaryError, { componentStack }: ErrorInfo) {
    withScope(scope => {
      scope.setExtras({ componentStack })
      scope.setLevel(getErrorLevel(error))
      captureException(error)
    })
  }

  handleReset = () => {
    this.setState({
      error: undefined,
    })

    if (this.props.onReset) this.props.onReset()
  }

  render() {
    const { error } = this.state
    const { fallback, children } = this.props

    if (!error) return children

    if (isNetworkError(error)) {
      return (
        <ErrorFallback
          title="Network Error"
          description="We could not communicate with the servers. Please, check you internet connection and try again."
          onReset={this.handleReset}
        />
      )
    }

    if (isChunkLoadError(error)) {
      return (
        <ErrorFallback
          title="Unable to load"
          description="Something went wrong while trying to load this page. Please, check you internet connection and try again."
          onReset={this.handleReset}
        />
      )
    }

    return fallback({ onReset: this.handleReset })
  }
}

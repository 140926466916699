import React, { createContext, useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import LogoLoading from 'legacy/components/LogoLoading/LogoLoading'
import user from 'legacy/components/User/User'
import storage from 'legacy/utils/storage'

import { getUserAccount } from 'auth/use-cases/getUserAccount'

import { getCookie } from '../../infra/cookie'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getToken('color-neutral-white')};
`

type AuthContextValue = [string | null | undefined, (value: string) => void] | []

export const AuthContext = createContext<AuthContextValue>([])
AuthContext.displayName = 'AuthContext'

export function AuthProvider({ children }: { children?: React.ReactNode }) {
  const authState = useState<string | null | undefined>(() => {
    const userTokenStorage = storage.get('loadSmart.user-token')
    const userTokenCookie = getCookie('lsUserToken')
    return userTokenStorage || userTokenCookie
  })

  const [userToken] = authState
  const userEmail = storage.get('loadSmart.user-email')

  /** TODO: erase this when we are confident with the migration */
  /** When the user comes from `app-web` we have the user token but not the
   * user account data, so we need to fetch that from our backend and refresh
   * the screen to make evertything work smoothly
   */
  useEffect(() => {
    if (userToken && !userEmail) {
      getUserAccount({ userRepository: user }).then(() => {
        window.location.reload()
      })
    }
  }, [userToken, userEmail])

  if (userToken && !userEmail) {
    return (
      <Wrapper>
        <div>
          <p>Just a second, we are synchronizing your data.</p>
          <LogoLoading />
        </div>
      </Wrapper>
    )
  }

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
}

export function useAuthContext() {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

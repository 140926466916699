import { Helmet, HelmetProvider } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components/macro'

import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'

import { GlobalStyle } from 'legacy/components/global-style/GlobalStyle'

import { CarrierProvider } from 'auth/ui/context/CarrierContext'
import { ConfigProvider } from 'config/ui/ConfigContext'
import { UserAccountProvider } from 'user/ui/UserAccountContext'

import { AnalyticsProvider } from './analytics/AnalyticsContext'
import { SalesforceChatClient } from './infra/communication/SalesforceChatClient'
import { ENVIRONMENT, __PROD__ } from './infra/environment'
import { ErrorBoundary } from './ui/components/error-boundary/ErrorBoundary'
import { AuthProvider } from './ui/contexts/authContext'
import { SalesforceChatProvider } from './ui/contexts/SalesforceChatContext'
import { Router } from './ui/router/Router'

const helmetContext = {}

export function App() {
  return (
    <ThemeProvider theme={Themes.Miranda}>
      <HelmetProvider context={helmetContext}>
        <ErrorBoundary>
          <AnalyticsProvider>
            <ConfigProvider>
              <AuthProvider>
                <CarrierProvider>
                  <UserAccountProvider>
                    <SalesforceChatProvider client={SalesforceChatClient.getInstance()}>
                      <Helmet titleTemplate="%s Loadsmart">
                        {!__PROD__ && <title>{ENVIRONMENT ? `[${ENVIRONMENT}]` : null}</title>}
                      </Helmet>
                      <GlobalStyle />
                      <ToastContainer />
                      <Router />
                    </SalesforceChatProvider>
                  </UserAccountProvider>
                </CarrierProvider>
              </AuthProvider>
            </ConfigProvider>
          </AnalyticsProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </ThemeProvider>
  )
}

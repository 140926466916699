import { css } from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

const SalesforceChatStyle = css`
  .embeddedServiceHelpButton .helpButton .uiButton,
  .startButton .uiButton--default .uiButton .embeddedServiceSidebarButton,
  .embeddedServiceSidebarMinimizedDefaultUI.helpButton,
  .embeddedServiceSidebarMinimizedDefaultUI {
    background-color: ${getToken('color-primary')} !important;
    border-color: ${getToken('color-primary')} !important;
  }

  .embeddedServiceHelpButton,
  .embeddedServiceSidebar {
    z-index: 10000 !important;
  }

  .embeddedServiceSidebarHeader {
    background-color: ${getToken('color-primary')} !important;
  }

  .embeddedServiceHelpButton .helpButton .uiButton:focus,
  .startButton .uiButton--default .uiButton:focus .embeddedServiceSidebarButton {
    outline: 1px solid ${getToken('color-primary')} !important;
  }

  .embeddedServiceSidebarButton:not(:disabled):focus,
  .embeddedServiceSidebarButton:not(:disabled):hover {
    background: ${getToken('color-primary')} !important;
  }

  .embeddedServiceSidebarButton:not(:disabled) {
    background: ${getToken('color-primary')} !important;
  }

  .embeddedServiceSidebarFormField .slds-style-inputtext:focus,
  .embeddedServiceSidebarFormField .slds-style-select:focus {
    border: 1px solid ${getToken('color-primary')} !important;
    box-shadow: 0 0 2px 0 ${getToken('color-primary')} !important;
  }

  .embeddedServiceSidebarExtendedHeader {
    background-color: ${getToken('color-primary')} !important;
  }

  .embeddedServiceLiveAgentStateChatInputFooter .chasitorText.textAreaIsFocused {
    border-color: ${getToken('color-primary')} !important;
  }

  .embeddedServiceLiveAgentStateChat .messageArea:focus {
    border-color: ${getToken('color-primary')} !important;
  }

  .embeddedServiceLiveAgentStateChat .endChatContainer .endChatButton {
    margin: 0 24px 8px !important;
  }

  .embeddedServiceSidebarHeader .headerButton:focus:after,
  .embeddedServiceSidebarHeader .headerItem:focus:after {
    border: none !important;
  }

  .embeddedServiceSidebarButton.uiButton--inverse .label {
    color: ${getToken('color-neutral-white')} !important;
  }
`

export { SalesforceChatStyle }

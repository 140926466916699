import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { IconArrowRight, IconCalendar } from '@loadsmart/icons'
import { Button, IconButton, Tag } from '@loadsmart/loadsmart-ui'

import { Icon } from 'core/ui/components/icon/Icon'

import { Equipment } from 'legacy/components/LoadOffer/Equipment'
import { deletePreferredLaneRate } from 'legacy/services/preferredLanes'
import { analytics } from 'legacy/utils/analytics'
import { formatZonedDate } from 'legacy/utils/date/formatZonedDate'
import { formatCurrency } from 'legacy/utils/formatCurrency'

import { formatRate } from './formatRate'
import {
  Container,
  Wrapper,
  SubContainer,
  Divider,
  RateContainer,
  TargetRate,
  DateRange,
  DescriptionContainer,
  EquipmentTypesList,
  Lane,
  OptionsContainer,
  Radius,
  Weekdays,
  TargetRateContainer,
} from './PreferredLane.styles'
import { PreferredLaneRateModal } from './PreferredLaneRateModal/PreferredLaneRateModal'
import { RemoveRateModal } from './RemoveRateModal/RemoveRateModal'

const WEEKDAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

const getWeekDaysList = weekdays => {
  const maxDaysInWeek = WEEKDAYS.length
  return !weekdays || (weekdays && weekdays.length === maxDaysInWeek)
    ? 'Everyday'
    : weekdays
        .sort((a, b) => WEEKDAYS.indexOf(a) - WEEKDAYS.indexOf(b))
        .join(', ')
        .toLowerCase()
}

const formatDate = date => formatZonedDate(date, 'UTC', 'MMM d')

export function PreferredLane({ lane, onLaneRemoveRequest, onLaneUpdate, isBeingRemoved }) {
  const {
    origin,
    destination,
    weekdays,
    equipment_types,
    starts_at,
    expires_at,
    rate,
    suggested_rate,
  } = lane

  const [removeRateModalOpen, setRemoveRateModalOpen] = useState(false)
  const [preferredLaneRateModalType, setPreferredLaneRateModalType] = useState('closed')
  const [isLoading, setLoading] = useState(false)

  const handleRemoveRate = useCallback(() => {
    setRemoveRateModalOpen(true)
  }, [])

  const handleConfirmRemoveRate = useCallback(() => {
    setLoading(true)

    analytics.track('User Account / Preferred Lane / Rate Dialog / Remove', { lane, rate })

    deletePreferredLaneRate(lane.uuid)
      .then(result => {
        const { data: updatedPreferredLane } = result
        if (typeof onLaneUpdate === 'function') {
          onLaneUpdate(updatedPreferredLane)
        }
      })
      .catch(() => {
        toast.error('Unable to remove rate now. Try again later')
      })
      .then(() => {
        setLoading(false)
        setRemoveRateModalOpen(false)
      })
  }, [lane, rate, onLaneUpdate])

  const handleCancelRemoveRate = useCallback(() => {
    setRemoveRateModalOpen(false)
  }, [])

  const handleAddRate = useCallback(() => {
    setPreferredLaneRateModalType('add')
  }, [])

  const handleEditRate = useCallback(() => {
    setPreferredLaneRateModalType('edit')
  }, [])

  const handleClosePreferredLaneRateModal = useCallback(
    ({ confirmed, updatedPreferredLane }) => {
      setPreferredLaneRateModalType('closed')
      if (confirmed && updatedPreferredLane != null && typeof onLaneUpdate === 'function') {
        onLaneUpdate(updatedPreferredLane)
      }
    },
    [onLaneUpdate]
  )

  const weekdaysList = getWeekDaysList(weekdays)

  const equipmentTypesList = equipment_types
    ? equipment_types.map(equipmentType => (
        <Equipment key={equipmentType} equipmentType={equipmentType} />
      ))
    : null

  const radius = origin?.radius || destination?.radius

  const handleRemove = useCallback(() => {
    onLaneRemoveRequest(lane)
  }, [lane, onLaneRemoveRequest])

  return (
    <>
      <Container>
        <Wrapper>
          <SubContainer>
            <DescriptionContainer>
              <Lane>
                {origin?.description || 'Anywhere'}
                <IconArrowRight width={13} height={13} />
                {destination?.description || 'Anywhere'}
                {radius && <Radius>{radius} mi</Radius>}
              </Lane>
              <OptionsContainer>
                {equipmentTypesList && (
                  <EquipmentTypesList>{equipmentTypesList}</EquipmentTypesList>
                )}
                <Weekdays>{weekdaysList}</Weekdays>
              </OptionsContainer>
              {expires_at && (
                <DateRange>
                  <IconCalendar width={13} height={13} />
                  {`${formatDate(starts_at)} - ${formatDate(expires_at)}`}
                </DateRange>
              )}
            </DescriptionContainer>
          </SubContainer>
          {!isBeingRemoved && (
            <>
              <Divider />
              <RateContainer>
                <TargetRateContainer>
                  {!rate && (
                    <Button variant="primary" scale="small" onClick={handleAddRate}>
                      Add Rate
                    </Button>
                  )}
                  {rate && (
                    <>
                      <div>
                        Target rate <TargetRate>{formatCurrency(rate)}</TargetRate>
                      </div>
                      <IconButton scale="small" onClick={handleEditRate}>
                        <Icon name="pencil" size="16px" title="Edit Rate" />
                      </IconButton>
                      <IconButton scale="small" onClick={handleRemoveRate}>
                        <Icon name="trash" size="16px" title="Remove Rate" />
                      </IconButton>
                    </>
                  )}
                </TargetRateContainer>
                {suggested_rate > 0 && (
                  <Tag variant="default">
                    <Icon name="graph" />
                    Suggested for this lane: ${formatRate(suggested_rate)}
                  </Tag>
                )}
              </RateContainer>
            </>
          )}
        </Wrapper>
        {onLaneRemoveRequest && !isBeingRemoved && (
          <IconButton onClick={handleRemove} title="Remove preferred lane">
            <Icon name="trash" />
          </IconButton>
        )}
      </Container>
      {preferredLaneRateModalType !== 'closed' && (
        <PreferredLaneRateModal
          preferredLaneId={lane.uuid}
          lane={lane}
          initialRate={lane.rate}
          onClose={handleClosePreferredLaneRateModal}
          type={preferredLaneRateModalType}
          page="User Account"
        />
      )}
      <RemoveRateModal
        onConfirm={handleConfirmRemoveRate}
        onCancel={handleCancelRemoveRate}
        open={removeRateModalOpen}
        loading={isLoading}
      />
    </>
  )
}

PreferredLane.propTypes = {
  lane: PropTypes.shape({
    uuid: PropTypes.string,
    origin: PropTypes.shape({
      description: PropTypes.string,
    }),
    destination: PropTypes.shape({
      description: PropTypes.string,
    }),
    weekdays: PropTypes.arrayOf(PropTypes.string),
    equipment_types: PropTypes.arrayOf(PropTypes.string),
    starts_at: PropTypes.string,
    expires_at: PropTypes.string,
    rate: PropTypes.number,
    suggested_rate: PropTypes.number,
  }).isRequired,
  onLaneRemoveRequest: PropTypes.func,
  onLaneUpdate: PropTypes.func,
  isBeingRemoved: PropTypes.bool,
}

PreferredLane.defaultProps = {
  onLaneRemoveRequest: undefined,
  onLaneUpdate: undefined,
  isBeingRemoved: false,
  suggested_rate: 0,
}

import { sanitizeUrl } from '@braintree/sanitize-url'
import QueryString, { type StringifyOptions, type ParsedQuery } from 'query-string'

import { isNotEmpty, isString } from '../utils/string'
import { HashedUrl } from './HashedUrl'

function getCurrentSearch(): string {
  return HashedUrl.fromCurrentHref().search
}

function filterValues(value: string | null | Array<string | null>): Array<string> {
  const valueArray = Array.isArray(value) ? value : [value]
  return valueArray.filter((item): item is string => isString(item) && isNotEmpty(item))
}

/**
 * Returns the first value associated with the given search parameters.
 */
export function getQueryString(...keys: Array<string>): Record<string, string | undefined> {
  const queryString = parseQueryString(getCurrentSearch())
  const result: Record<string, string | undefined> = {}

  for (const key of keys) {
    result[key] = filterValues(queryString[key])[0]
  }

  return result
}

/**
 * Returns all the values associated with the given search parameter.
 */
export function getQueryStringAll(key: string): Array<string> {
  const queryString = parseQueryString(getCurrentSearch())
  const value = queryString[key]
  return filterValues(value)
}

/**
 * Parse a search string and returns all the values associated with all parametes
 */
export function parseQueryString(search: string): ParsedQuery<string> {
  const sanitizedUrl = sanitizeUrl(search)
  return QueryString.parse(sanitizedUrl, { sort: false })
}

/**
 * Stringify an object into a query string
 */
export function stringifyQueryString(
  object: Record<string, any>,
  options?: StringifyOptions
): string {
  return QueryString.stringify(object, {
    sort: false,
    skipEmptyString: true,
    skipNull: true,
    ...options,
  })
}

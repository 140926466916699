import { useEffect, useState } from 'react'

import { EmptyState } from '@loadsmart/loadsmart-ui'

import sentry from 'legacy/utils/sentry'

import { type CarrierAccount } from '../domain/Carrier'
import SignUpConfirmImg from './assets/signup-confirm.png'
import { useReSendEmail } from './hooks/useReSendEmail'

export type SignUpSuccessProps = {
  carrier: CarrierAccount
  displayCta?: boolean
}

export function SignUpSuccess({ carrier, displayCta = false }: SignUpSuccessProps) {
  const { isLoading, reSendEmail } = useReSendEmail()
  const [abortController, setAbortController] = useState(new AbortController())
  const [isBlocked, setBlocked] = useState(false)

  const isPendingApproval = carrier.account.approvalStatus === 'pending'

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [abortController])

  useEffect(() => {
    let timeout: number

    if (isBlocked) {
      timeout = window.setTimeout(() => {
        clearTimeout(timeout)
        setBlocked(false)
      }, 3000)
    }

    return () => clearTimeout(timeout)
  }, [isBlocked])

  async function handleReSendEmail() {
    const newAbortController = new AbortController()
    setAbortController(newAbortController)

    try {
      await reSendEmail(carrier.account.email, newAbortController)
      setBlocked(true)
    } catch (error) {
      sentry.log(error)
    }
  }

  if (isPendingApproval) {
    return (
      <EmptyState
        title="Your account is pending"
        description="Please, confirm your email address and wait for an admin to approve your account in the e-mail we sent. After activation, check your e-mail one more time to start booking"
        illustration={SignUpConfirmImg}
        variant="page"
      />
    )
  }

  const action = displayCta
    ? {
        onClick: handleReSendEmail,
        label: 'Re-send email',
        disabled: isLoading || isBlocked,
      }
    : undefined

  return (
    <EmptyState
      title="Check your email"
      description="We sent an email to verify your address. Please check your inbox and click on the link to activate your account."
      illustration={SignUpConfirmImg}
      variant="page"
      action={action}
    />
  )
}

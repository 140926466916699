import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Banner, type BannerProps } from '@loadsmart/miranda-react'
import { getToken } from '@loadsmart/miranda-react/dist/tokens'

import analytics from 'legacy/utils/analytics'
import user from 'legacy/utils/user'

import { useConfigContext } from 'config/ui/ConfigContext'

import { type EntityType } from '../domain/EntityType'
import { cookieClient } from '../infra/cookie'
import { Icon, type IconProps } from '../ui/components/icon/Icon'
import { Container } from '../ui/Container'
import { dismissBannerFactory } from './dismissBanner'
import { getBannerStateFactory } from './getBannerState'

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: var(--navbar-height);
  margin-bottom: -${getToken('spacing-10')};
  padding: ${getToken('spacing-6')} ${getToken('spacing-4')} 0;
`

type AnnouncementBannerProps = {
  allowedUrls?: string[]
  campaign: string
  description?: string
  dismissible: boolean
  icon?: IconProps['name']
  link?: {
    label: string
    url: string
  }
  message: string
  title: string
  variant: BannerProps['variant']
  allowedEntities: EntityType[]
}

const dismissBanner = dismissBannerFactory({ cookies: cookieClient })
const getBannerState = getBannerStateFactory({ cookies: cookieClient })

export function isPageAllowed(currentUrl: string, urls: string[] = []) {
  if (urls.length === 0) return true

  return urls.some(url => {
    return currentUrl.includes(url)
  })
}

export function AnnouncementBanner() {
  const { configAnnouncement } = useConfigContext()
  const { pathname } = useLocation()
  const [dismissed, setDismissed] = useState(true)

  const announcement: AnnouncementBannerProps = configAnnouncement.toObject() ?? {}
  const {
    variant,
    campaign,
    icon,
    title,
    description,
    link,
    allowedUrls,
    dismissible = true,
    allowedEntities = ['carrier'],
  } = announcement
  const isEntityAllowed = allowedEntities.includes(user.get('entityType') as EntityType)

  function dismiss() {
    analytics.track('Announcement Banner / Dismiss', { campaign: campaign })

    setDismissed(() => {
      return dismissBanner({ campaign: campaign })
    })
  }

  function click() {
    analytics.track('Announcement Banner / Click', { campaign: campaign })
  }

  useEffect(() => {
    if (!campaign || dismissed) return
    analytics.track('Announcement Banner / View', { campaign: campaign })
  }, [dismissed, campaign])

  useEffect(() => {
    if (!campaign) return
    setDismissed(() => getBannerState({ campaign: campaign }) === true)
  }, [campaign])

  if (!title || dismissed || !isEntityAllowed || !isPageAllowed(pathname, allowedUrls)) return null

  return (
    <Container variant="xxlg">
      <BannerContainer>
        <Banner variant={variant} dismissed={dismissed} dismissible={true}>
          {Boolean(icon) && (
            <Banner.Icon>
              <Icon name={icon!} size={24} color={`${variant}-dark` as IconProps['color']} />
            </Banner.Icon>
          )}
          <Banner.Title>{title}</Banner.Title>
          {Boolean(description) && <Banner.Description>{description}</Banner.Description>}
          {link != null && typeof link === 'object' && (
            <Banner.Actions>
              {dismissible && (
                <Banner.ActionSecondary onClick={dismiss}>Dismiss</Banner.ActionSecondary>
              )}
              <Banner.ActionPrimary href={link.url} onClick={click}>
                {link.label}
              </Banner.ActionPrimary>
            </Banner.Actions>
          )}
        </Banner>
      </BannerContainer>
    </Container>
  )
}

import styled from 'styled-components/macro'

import { type Size, sizes } from 'legacy/styles/screen'

export type Variant = Size

export type ContainerProps = {
  variant?: Variant
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${({ variant = 'xxlg' }) => sizes[variant]};
  margin: 0 auto;
`

import PropTypes from 'prop-types'
import { useMemo } from 'react'

import LocationSelect from '@loadsmart/react-location-select'

import { GOOGLE_MAPS_KEY } from 'core/infra/environment'

const DEFAULT_CONFIG = {
  types: ['geocode'],
  restrictions: { country: ['us', 'ca'] },
}

/**
 * @typedef {Object} Location
 * @property {string} description
 * @property {string} formatted_address
 * @property {string} place_id
 * @property {number} lat
 * @property {number} lng
 */

/**
 * @typedef {Object} LocationInputProps
 * @property {string} [name]
 * @property {string} [value]
 * @property {(location?: Location, event: ChangeEvent<HTMLInputElement>) => void} [onChange]
 * @property {() => void} [onClearButtonClick]
 */

/** @typedef {Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'name'>} HtmlInputProps */

/**
 * @param {HtmlInputProps & LocationInputProps} props
 */
export function LocationInput({
  name,
  value,
  onChange = () => {},
  onClearButtonClick = null,
  ...passThrough
}) {
  const fallbackEvent = useMemo(() => ({ target: { name } }), [name])

  const initialValue = value ? { label: value, value } : undefined

  function handleChangeLocation(event) {
    const value = event.target.value

    if (value) {
      const location = {
        ...value,
        description: value.address,
        formatted_address: value.address,
      }

      onChange(location, { ...event, ...fallbackEvent })
      return
    }

    onClearButtonClick?.(event.target.name)
  }

  return (
    <LocationSelect
      {...passThrough}
      name={name}
      value={initialValue}
      googleMapsAPIKey={GOOGLE_MAPS_KEY}
      onChange={handleChangeLocation}
      config={DEFAULT_CONFIG}
      autoComplete="off"
    />
  )
}

LocationInput.propTypes = {
  onChange: PropTypes.func,
  onClearButtonClick: PropTypes.func,
  value: PropTypes.string,
}

export default LocationInput

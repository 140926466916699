import { type OnboardingStep } from 'onboarding/domain/Onboarding'

import { type UserAccount, type ApprovalStatus, type UserRole } from '../domain/UserAccount'

export type RawUserAccount = {
  phone_number: string | null
  first_name: string
  last_name: string | null
  daimler_vip: boolean
  email: string | null
  avatar_url: string | null
  role: UserRole
  active: boolean
  id: string
  approval_status: ApprovalStatus
  registered: string | null
  show_onboarding: boolean
  onboarding_steps: OnboardingStep[] | null
}

export function mapApiToUserAccount(rawUserAccount: RawUserAccount): UserAccount {
  return {
    phoneNumber: rawUserAccount.phone_number || undefined,
    firstName: rawUserAccount.first_name,
    lastName: rawUserAccount.last_name || undefined,
    daimlerVip: rawUserAccount.daimler_vip,
    email: rawUserAccount.email || undefined,
    avatarUrl: rawUserAccount.avatar_url || undefined,
    role: rawUserAccount.role,
    active: rawUserAccount.active,
    id: rawUserAccount.id,
    approvalStatus: rawUserAccount.approval_status,
    registered: rawUserAccount.registered ? new Date(rawUserAccount.registered) : undefined,
    showOnboarding: rawUserAccount.show_onboarding,
    onboardingSteps: rawUserAccount.onboarding_steps || [],
  }
}

import { type ReactNode } from 'react'

import { type ConfigKeys } from '../domain/Config'
import { type ConfigValue } from '../domain/ConfigValue'
import { useConfigContext } from './ConfigContext'

type ConfigRenderProps = {
  children: ReactNode
  configName: ConfigKeys
  condition?: (configValue: ConfigValue) => boolean
}

function defaultCondition(configValue: ConfigValue) {
  return configValue.toBoolean()
}

export function ConfigRender({
  configName,
  condition = defaultCondition,
  children,
}: ConfigRenderProps) {
  const config = useConfigContext()

  const configValue = config[configName]

  if (!configValue) return null

  if (!condition(configValue)) return null

  return <>{children}</>
}

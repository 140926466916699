import { type FirebaseApp, initializeApp, onLog, setLogLevel } from 'firebase/app'

import sentry from 'legacy/utils/sentry'

import { __DEV__ } from './environment'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'carrier-web-a2fa2.firebaseapp.com',
  projectId: 'carrier-web-a2fa2',
  storageBucket: 'carrier-web-a2fa2.appspot.com',
  messagingSenderId: '102447196957',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export class FirebaseClient {
  private static app: FirebaseApp

  static init() {
    if (FirebaseClient.app) return

    FirebaseClient.app = initializeApp(firebaseConfig)

    if (__DEV__) {
      setLogLevel('verbose')
      onLog(function localLog({ message }) {
        console.log(message)
      })
    } else {
      onLog(function firebaseLog({ message }) {
        sentry.log(message, { level: 'warning' })
      })
    }
  }

  static getInstance() {
    if (!FirebaseClient.app) {
      throw new Error('Called getInstance without initializing Firebase')
    }

    return FirebaseClient.app
  }
}

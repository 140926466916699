import styled, { keyframes } from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

const animation = keyframes`
0% {
  width: 0;
}
90% { 
  width: 100%; 
}
`

const TopLoadingContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
`

const TopLoading = styled.div`
  animation: ${animation} 5s ease forwards infinite;
  background-color: ${getToken('color-primary')};
  background-size: 200% 100%;
  height: 4px;
  width: 100%;
  position: relative;
`

export function Fallback() {
  return (
    <TopLoadingContainer>
      <TopLoading aria-busy="true" aria-label="loading" />
    </TopLoadingContainer>
  )
}

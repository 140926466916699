import { API_URL } from 'core/infra/environment'
import { HttpClient, type HttpClientConfig } from 'core/infra/http/HttpClient'

import { UserAccountRepository } from '../infra/UserAccountRepository'

export type GetUserAccountDependencies = {
  userAccountRepository: UserAccountRepository
}

export function updateUserAccountFactory({ userAccountRepository }: GetUserAccountDependencies) {
  return function (data: {}, config?: { signal?: AbortSignal }) {
    return userAccountRepository.updateUserAccount({ data, config })
  }
}

export function updateUserAccount(data: {}, config?: HttpClientConfig) {
  const httpClient = new HttpClient(API_URL)
  const userAccountRepository = new UserAccountRepository({ httpClient })
  const update = updateUserAccountFactory({ userAccountRepository })

  return update(data, config)
}

import { useEffect, useRef } from 'react'

import { arraysAreEqual } from '../../utils/array'

export function useAbortController(dependencies: React.DependencyList = []): AbortController {
  const dependenciesRef = useRef<React.DependencyList>(dependencies)
  const abortControllerRef = useRef<AbortController>(new AbortController())

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort()
    }
  }, [])

  if (arraysAreEqual(dependenciesRef.current, dependencies)) {
    return abortControllerRef.current
  }

  dependenciesRef.current = dependencies
  abortControllerRef.current.abort()
  abortControllerRef.current = new AbortController()
  return abortControllerRef.current
}

import { type TruckType } from 'core/domain/Truck'

import { type EquipmentType } from '../domain/EquipmentType'

/**
 * Maps EquipmentType to TruckType. If a match is not possible, return undefined.
 */
export function mapEquipmentTypeToTruckType(equipmentType: EquipmentType): TruckType | undefined {
  switch (equipmentType) {
    case 'Conestoga':
      return 'CON'
    case 'DryBox':
      return 'DRB'
    case 'DryVan':
      return 'DRV'
    case 'Flatbed':
      return 'FBE'
    case 'FlatRack':
      return 'FRA'
    case 'Hotshot':
      return 'HST'
    case 'Less-than-truckload':
      return 'LTL'
    case 'Port Drayage':
      return 'IMC'
    case 'Reefer':
      return 'RFR'
    case 'Roll on Roll off':
      return 'RORO'
    case 'Sprinter':
      return 'SPR'
    case 'Straight Box Truck':
      return 'SBT'
    case '40ft High Cube Container':
    case 'Bulk Pneumatics':
    case 'Bulk Tanker':
    case 'Domestic Rail':
    case 'Power Only':
    case 'Reefer or Vented Van':
    default:
      return undefined
  }
}

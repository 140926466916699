import styled, { keyframes } from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

const loading = keyframes`
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% {left: 50%;}
  to {left: 100%;}
`

export const LogoLoading = styled.div.attrs({ 'data-testid': 'loading' })`
  background-color: ${getToken('color-neutral-lighter')};
  border-radius: 10px;
  height: 10px;
  overflow: hidden;
  position: relative;
  margin: 50px auto;
  width: 200px;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    background-color: ${props =>
      (props.theme && props.theme.color && props.theme.color.primary) || getToken('color-primary')};
    animation: ${loading} 1s linear infinite;
    height: 10px;
  }
`

export default LogoLoading

import queryString from 'query-string'

import { CLIENT } from 'legacy/constants/clients'
import { CARRIER_DEFAULT_HOME } from 'legacy/constants/tabs'
import { getAccountV2 } from 'legacy/services/account'
import { getTokenV2 } from 'legacy/services/auth'
import { analytics } from 'legacy/utils/analytics'
import { storage } from 'legacy/utils/storage'
import { user as userUtil } from 'legacy/utils/user'

export const user = {
  async getAuth(email, pass, setError, redirect = null, onAuthChange) {
    const postData = {
      grant_type: 'password',
      username: email,
      password: pass,
      client_id: CLIENT.client_id,
    }

    const payload = queryString.stringify(postData)

    try {
      const { data: token } = await getTokenV2(payload)
      this.setTokenInfo(token)

      const {
        data: { data: account },
      } = await getAccountV2()

      this.setAccountInfo(account)

      analytics.track('Login / Success')

      if (onAuthChange) {
        await onAuthChange(account, token)
      } else {
        this.redirectAfterAccount(account, redirect)
      }
    } catch (err) {
      if (err.response) {
        setError({
          status: err.response.status,
          validity: err.response.data && err.response.data.error_description,
        })
      }
      analytics.track('Login / Error', {
        status: err.response?.status,
        description: err.response?.data?.error_description,
      })
    }
  },
  redirectCarrier() {
    return user.location(CARRIER_DEFAULT_HOME)
  },
  redirectAfterAccount(account, page) {
    if (page) {
      user.location(page)
    } else if (account.type === 'carrier') {
      this.redirectCarrier()
    }
  },
  location(href) {
    window.location.href = href
  },
  setAccountInfo(account) {
    storage.set('loadSmart.user-firstName', account.first_name)
    storage.set('loadSmart.user-lastName', account.last_name)
    storage.set('loadSmart.user-email', account.email)
    storage.set('loadSmart.user-avatarUrl', account.avatar_url)
    storage.set('loadSmart.user-addressPhone', '')
    storage.set('loadSmart.user-companyName', account.company.name)
    storage.set('loadSmart.user-companyId', account.company.id)
    storage.set('loadSmart.user-companyBusinessSize', account.company.business_size)
    storage.set('loadSmart.user-carrierFleetSize', account.company.fleet_size_category)
    storage.set('loadSmart.user-daimlerVip', !!account.daimler_vip)
    storage.set('loadSmart.user-type', account.type)
    storage.set('loadSmart.user-role', account.role)
    storage.set('loadSmart.user-accountId', account.id)
    storage.set('loadSmart.user-intermodal', account.intermodal)
  },
  setTokenInfo(token) {
    storage.set('loadSmart.user-token', token.access_token)
    storage.set('loadSmart.user-tokenCreation', new Date().getTime())
  },
  logout() {
    userUtil.logout()
  },
}

export default user

import PropTypes from 'prop-types'

import { Dialog } from '@loadsmart/miranda-react'

function RemoveRateModal({ open, onConfirm, onCancel, loading }) {
  return (
    <Dialog open={open} variant="danger" size="small">
      <Dialog.Header>Remove rate</Dialog.Header>
      <Dialog.Body>Do you want to remove this rate?</Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionSecondary onClick={onCancel}>Cancel</Dialog.ActionSecondary>
        <Dialog.ActionPrimary
          data-testid="confirm-remove-rate"
          loading={loading}
          onClick={onConfirm}
        >
          Remove
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </Dialog>
  )
}

RemoveRateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

RemoveRateModal.defaultProps = {
  loading: false,
}

export { RemoveRateModal }

import { useEffect } from 'react'
import styled from 'styled-components/macro'

import { Button, CloseButton, Text } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

const BANNER_HEIGHT_IN_PIXELS = 50

const Banner = styled.div`
  height: ${BANNER_HEIGHT_IN_PIXELS}px;
  padding: ${getToken('space-s')};
  background-color: ${getToken('color-success-dark')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledButton = styled(Button)`
  color: ${getToken('color-neutral-white')};
  border-color: ${getToken('color-neutral-white')};
  margin: 0px ${getToken('space-s')};

  &:hover {
    color: ${getToken('color-neutral-light')} !important;
    border-color: ${getToken('color-neutral-light')} !important;
  }
`
const StyledClose = styled(CloseButton)`
  color: ${getToken('color-neutral-white')};
  margin-left: ${getToken('space-s')};
`

const StyledText = styled(Text)`
  color: ${getToken('color-neutral-white')};
  margin: auto 0px;
`

export type SurveyBannerProps = {
  displayText: string
  buttonLabel: string
  showFeedback: boolean
  onButtonClick: () => void
  onClose: () => void
}

export function SurveyBanner({
  displayText,
  buttonLabel,
  showFeedback,
  onButtonClick,
  onClose,
}: SurveyBannerProps) {
  useEffect(() => {
    const cssVar = '--page-wrapper-offset'
    const oldValue = getComputedStyle(document.documentElement).getPropertyValue(cssVar)
    const newValue = `calc(${oldValue} + ${BANNER_HEIGHT_IN_PIXELS}px)`

    document.documentElement.style.setProperty(cssVar, newValue)

    return function onUnmount() {
      document.documentElement.style.setProperty(cssVar, oldValue)
    }
  }, [])

  return (
    <Banner>
      <StyledText>{displayText}</StyledText>
      {!showFeedback && (
        <>
          <StyledButton scale="small" scheme="dark" onClick={onButtonClick}>
            {buttonLabel}
          </StyledButton>
          <StyledClose onClick={onClose} aria-label="close" />
        </>
      )}
    </Banner>
  )
}

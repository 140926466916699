import styled from 'styled-components/macro'

import { IconCaretDown } from '@loadsmart/icons'
import { getToken, toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { screen } from 'legacy/styles/screen'

const TitleWrapper = styled.div`
  display: none;
  align-items: center;

  ${screen.lg} {
    display: flex;
  }
`

const Title = styled.span`
  display: block;
  color: ${toCSSValue('color-text-inverted')};
  text-transform: uppercase;
  padding: ${getToken('spacing-4')};
  font-size: ${getToken('font-size-2')};
  font-weight: ${getToken('font-weight-regular')};

  ${screen.lg} {
    line-height: 50px;
    padding: 0 ${getToken('spacing-4')};
  }
`

const MenuIcon = styled(IconCaretDown)`
  margin-left: ${getToken('spacing-0-5')};
`

interface DropdownMenuTitleProps {
  title: string
  image?: JSX.Element
}

export function DropdownMenuTitle({ title, image }: DropdownMenuTitleProps) {
  return (
    <TitleWrapper>
      {image}
      <Title>
        {title}
        <MenuIcon width="8px" color={`${getToken('color-neutral-60')}`} />
      </Title>
    </TitleWrapper>
  )
}

import {
  IconTruckDryvan,
  IconTruckReefer,
  IconTruckFlatbed,
  IconTruckConestoga,
  IconTruckContainer,
  IconTruckStraight,
  IconTruckCourtainSide,
  IconTruckDoubleDrop,
  IconTruckDryvanTeam,
  IconTruckReeferTeam,
  IconTruckFlatbedTeam,
  IconTruckLtlDryvan,
  IconTruckLtlReefer,
  IconTruckRailDrybox,
  IconTruckRailReefer,
  IconTruckPtlDryvan,
  IconTruckPtlReefer,
  IconContainerReefer,
  IconContainer,
} from '@loadsmart/icons'

import { ALL_EQUIPMENT_TYPES, ALL_MODES } from 'legacy/constants/equipmentTypes'

const iconMap = {
  team: {
    default: {
      [ALL_EQUIPMENT_TYPES.DRV.value]: IconTruckDryvanTeam,
      [ALL_EQUIPMENT_TYPES.RFR.value]: IconTruckReeferTeam,
      [ALL_EQUIPMENT_TYPES.FBE.value]: IconTruckFlatbedTeam,
      default: IconTruckDryvanTeam,
    },
  },
  default: {
    [ALL_MODES.FTL.value]: {
      [ALL_EQUIPMENT_TYPES.RFR.value]: IconTruckReefer,
      [ALL_EQUIPMENT_TYPES.DRV.value]: IconTruckDryvan,
      [ALL_EQUIPMENT_TYPES.FBE.value]: IconTruckFlatbed,
      [ALL_EQUIPMENT_TYPES.CON.value]: IconTruckConestoga,
      [ALL_EQUIPMENT_TYPES.CUR.value]: IconTruckCourtainSide,
      [ALL_EQUIPMENT_TYPES.DDP.value]: IconTruckDoubleDrop,
      [ALL_EQUIPMENT_TYPES.IMC.value]: IconTruckContainer,
      [ALL_EQUIPMENT_TYPES.SDK.value]: IconTruckDoubleDrop,
      [ALL_EQUIPMENT_TYPES.STK.value]: IconTruckStraight,
      [ALL_EQUIPMENT_TYPES.IMC.value]: IconContainer,
      default: IconTruckDryvan,
    },
    [ALL_MODES.FCL.value]: {
      [ALL_EQUIPMENT_TYPES.RFR.value]: IconContainerReefer,
      [ALL_EQUIPMENT_TYPES.IMC.value]: IconTruckContainer,
      default: IconTruckContainer,
    },
    [ALL_MODES.LTL.value]: {
      [ALL_EQUIPMENT_TYPES.RFR.value]: IconTruckLtlReefer,
      [ALL_EQUIPMENT_TYPES.DRV.value]: IconTruckLtlDryvan,
      default: IconTruckLtlDryvan,
    },
    [ALL_MODES.RAIL.value]: {
      [ALL_EQUIPMENT_TYPES.RFR.value]: IconTruckRailReefer,
      [ALL_EQUIPMENT_TYPES.DRV.value]: IconTruckRailDrybox,
      [ALL_EQUIPMENT_TYPES.IMC.value]: IconTruckRailDrybox,
      default: IconTruckRailDrybox,
    },
    [ALL_MODES.PTL.value]: {
      [ALL_EQUIPMENT_TYPES.DRV.value]: IconTruckPtlDryvan,
      [ALL_EQUIPMENT_TYPES.RFR.value]: IconTruckPtlReefer,
      default: IconTruckPtlDryvan,
    },
    default: {
      [ALL_EQUIPMENT_TYPES.RFR.value]: IconTruckReefer,
      [ALL_EQUIPMENT_TYPES.DRV.value]: IconTruckDryvan,
      [ALL_EQUIPMENT_TYPES.FBE.value]: IconTruckFlatbed,
      [ALL_EQUIPMENT_TYPES.CON.value]: IconTruckConestoga,
      [ALL_EQUIPMENT_TYPES.CUR.value]: IconTruckCourtainSide,
      [ALL_EQUIPMENT_TYPES.DDP.value]: IconTruckDoubleDrop,
      [ALL_EQUIPMENT_TYPES.IMC.value]: IconTruckContainer,
      [ALL_EQUIPMENT_TYPES.SDK.value]: IconTruckDoubleDrop,
      [ALL_EQUIPMENT_TYPES.STK.value]: IconTruckStraight,
      [ALL_EQUIPMENT_TYPES.IMC.value]: IconContainer,
      [ALL_EQUIPMENT_TYPES.LTL.value]: IconTruckLtlDryvan,
      default: IconTruckDryvan,
    },
  },
}

function getIconFromEquipmentTypeAndMode({
  mode = 'default',
  equipmentType = 'default',
  isTeam = false,
} = {}) {
  const teamKey = isTeam ? 'team' : 'default'
  const modeKey = ALL_MODES.hasOwnProperty(mode) ? mode : 'default'
  const equipKey = ALL_EQUIPMENT_TYPES.hasOwnProperty(equipmentType) ? equipmentType : 'default'

  return iconMap[teamKey][modeKey || 'default'][equipKey || 'default']
}

export { getIconFromEquipmentTypeAndMode }

export class ConfigValue {
  private value: string

  constructor(value: string) {
    this.value = value
  }

  toBoolean(): boolean {
    return this.value === 'true'
  }

  toNumber(): number {
    return Number(this.value)
  }

  toString(): string {
    return this.value
  }

  toObject<T>(): T {
    return JSON.parse(this.value)
  }
}

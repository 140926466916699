import { useCallback, useState, useMemo, useEffect } from 'react'

import { useCarrierContext } from 'auth/ui/context/CarrierContext'

import { setSelectedStates, getSelectedRegions, type StateMapping } from '../../domain/Regions'
import statesMapping, { getRegion } from '../statesMapping'

export default function useRegionsOfOperation() {
  const [states, setStates] = useState<StateMapping>(statesMapping)
  const regions = useMemo(() => getSelectedRegions(states), [states])
  const { carrier } = useCarrierContext()

  const carrierStates = useMemo<StateMapping>(
    () => setSelectedStates(statesMapping, carrier?.regions),
    [carrier]
  )

  useEffect(() => setStates(carrierStates), [carrierStates])

  const toggleStateSelection = useCallback(
    (id: string) => {
      const lowercaseId = id.toLowerCase()
      const newState = {
        ...states[lowercaseId],
        selected: typeof states[lowercaseId] === 'object' && !states[lowercaseId].selected,
      }
      setStates({ ...states, [lowercaseId]: newState })
    },
    [states]
  )

  const toggleRegionSelection = useCallback(
    (region: string) => {
      const unselected = getRegion(region).filter((s: string) => !states[s.toLowerCase()].selected)
      const updateStates = unselected.length !== 0 ? unselected : getRegion(region)

      setStates(setSelectedStates(states, updateStates))
    },
    [states]
  )

  return {
    states,
    regions,
    toggleStateSelection,
    toggleRegionSelection,
  }
}

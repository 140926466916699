import { useCallback } from 'react'
import styled from 'styled-components/macro'

import { IconCog, IconLogout, IconUser } from '@loadsmart/icons'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { analytics } from 'legacy/utils/analytics'
import { user } from 'legacy/utils/user'

import { DropdownMenu } from './DropdownMenu'
import { StyledMenuItem } from './Navigation.styles'

const IconWrapper = styled.div`
  height: 32px;
  width: 32px;
  overflow: hidden;
  color: ${getToken('color-neutral-darker')};
  background-color: ${getToken('color-neutral-light')};
  border-radius: 50%;
  margin: 0 0 0 ${getToken('space-m')};
`

const StyledIcon = styled(IconUser)`
  padding-top: 5px;
  padding-right: 4px;
`

const Image = styled.img`
  height: 100%;
  width: 100%;
`

function Picture({ url }: { url: string | null }) {
  if (!url || url === 'null') {
    return (
      <IconWrapper>
        <StyledIcon width="36px" height="36px" />
      </IconWrapper>
    )
  }

  return (
    <IconWrapper>
      <Image src={url} width="28px" height="28px" alt="user image" />
    </IconWrapper>
  )
}

export function Profile() {
  const handleLogout = useCallback((event: React.MouseEvent) => {
    event.preventDefault()

    analytics.track('Logout')

    user.logout()

    window.location.href = '/login'
  }, [])

  return (
    <DropdownMenu title={user.firstName() || 'Profile'} image={<Picture url={user.avatarUrl()} />}>
      <StyledMenuItem
        title="Account"
        url="/profile/carrier"
        icon={<IconCog width="14px" height="14px" />}
      />
      <StyledMenuItem
        title="Log Out"
        url="/logout"
        onClick={handleLogout}
        icon={<IconLogout width="14px" height="14px" />}
      />
    </DropdownMenu>
  )
}

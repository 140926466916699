import { useState, useCallback } from 'react'

import { getLaneSuggestedRate } from 'legacy/services/preferredLanes'

export function useSuggestedRate(initialRate = null) {
  const [suggestedRate, setSuggestedRate] = useState(initialRate)
  const [isLoadingSuggestedRate, setIsLoading] = useState(false)

  const getSuggestedRate = useCallback(payload => {
    setIsLoading(true)
    return getLaneSuggestedRate(payload)
      .catch(error => {
        console.error(error)
        return { data: { suggested_rate: 0 } }
      })
      .then(response => {
        const rate = response.data.suggested_rate ?? 0
        setSuggestedRate(rate)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const clearSuggestedRate = useCallback(() => {
    setSuggestedRate(initialRate)
  }, [initialRate])

  return { suggestedRate, getSuggestedRate, clearSuggestedRate, isLoadingSuggestedRate }
}

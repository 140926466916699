import { BROKER_WELCOME_STORAGE_KEY } from '../domain/Carrier'
import { LocalStorageClient } from '../infra/storage/LocalStorageClient'
import { type StorageInterface } from '../infra/storage/StorageInterface'

export type ConfirmBrokerWelcomeParams = {
  storage: StorageInterface
}

export function confirmBrokerWelcomeFactory({ storage }: ConfirmBrokerWelcomeParams) {
  return function confirmBrokerWelcomeImpl() {
    storage.set(BROKER_WELCOME_STORAGE_KEY, true)

    return storage.get<boolean>(BROKER_WELCOME_STORAGE_KEY)
  }
}

export function confirmBrokerWelcome() {
  const storage = new LocalStorageClient()
  const confirmWelcome = confirmBrokerWelcomeFactory({ storage })

  confirmWelcome()
}

export class AlreadyExistsError extends Error {
  constructor(options?: ErrorOptions) {
    super('The required resource already exists', options)
    this.name = 'AlreadyExistsError'

    // For V8 only
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, AlreadyExistsError)
    }
  }
}

import { type Location } from '@loadsmart/react-location-select'

import { join } from 'core/utils/string'

export type Place = {
  description: string
  latitude?: number
  longitude?: number
  radius?: number
}

export function createFromLocation(location: Location): Place {
  return {
    description: location.address || join([location.city, location.state, location.country]),
    latitude: location.lat,
    longitude: location.lng,
  }
}

export function hasCoordinates(place?: Place): boolean {
  return typeof place?.latitude === 'number' && typeof place?.longitude === 'number'
}

import { useEffect, useState } from 'react'

import { Dialog } from '@loadsmart/loadsmart-ui'

import { sentry } from 'legacy/utils/sentry'

import { type CarrierAccount } from '../domain/Carrier'
import { useReSendEmail } from './hooks/useReSendEmail'
import { SignUpSuccess } from './SignUpSuccess'
import { SuccessMessageWrapper } from './SignUpSuccessDialog.styles'

export type SignUpSuccessDialogProps = {
  carrier: CarrierAccount
  onClose: () => void
}

export function SignUpSuccessDialog({ carrier, onClose }: SignUpSuccessDialogProps): JSX.Element {
  const { isLoading, reSendEmail } = useReSendEmail()
  const [abortController, setAbortController] = useState(new AbortController())
  const [isBlocked, setBlocked] = useState(false)

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [abortController])

  useEffect(() => {
    let timeout: number

    if (isBlocked) {
      timeout = window.setTimeout(() => {
        clearTimeout(timeout)
        setBlocked(false)
      }, 3000)
    }

    return () => clearTimeout(timeout)
  }, [isBlocked])

  async function handleReSendEmail() {
    const newAbortController = new AbortController()
    setAbortController(newAbortController)

    try {
      await reSendEmail(carrier.account.email, newAbortController)
      setBlocked(true)
    } catch (error) {
      sentry.log(error)
    }
  }

  function handleClose() {
    abortController.abort()
    onClose()
  }

  return (
    <Dialog open>
      <Dialog.Header>Sign up completed</Dialog.Header>
      <Dialog.Body>
        <SuccessMessageWrapper>
          <SignUpSuccess carrier={carrier} />
        </SuccessMessageWrapper>
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionConfirm onConfirm={handleReSendEmail} disabled={isLoading || isBlocked}>
          Re-send email
        </Dialog.ActionConfirm>
        <Dialog.ActionCancel onCancel={handleClose}>Close</Dialog.ActionCancel>
      </Dialog.Actions>
    </Dialog>
  )
}

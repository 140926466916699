import PropTypes from 'prop-types'
import { useCallback } from 'react'

import { Dialog } from '@loadsmart/miranda-react'

import { PreferredLane } from '../PreferredLane'

export function RemovePreferredLaneModal({ lane, isOpen, onConfirm, onCancel, loading }) {
  const { uuid } = lane

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  const handleRemove = useCallback(() => {
    onConfirm(uuid)
  }, [uuid, onConfirm])

  return (
    <Dialog open={isOpen} variant="danger" size="large">
      <Dialog.Header>Do you wish to remove this preferred lane?</Dialog.Header>
      <Dialog.Body>
        <PreferredLane lane={lane} isBeingRemoved />
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionSecondary data-testid="cancel-remove-preferred-lane" onClick={handleCancel}>
          Cancel
        </Dialog.ActionSecondary>
        <Dialog.ActionPrimary
          data-testid="remove-preferred-lane"
          onClick={handleRemove}
          loading={loading}
        >
          Remove
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </Dialog>
  )
}

RemovePreferredLaneModal.propTypes = {
  lane: PropTypes.shape({
    origin: PropTypes.shape({
      description: PropTypes.string,
    }),
    destination: PropTypes.shape({
      description: PropTypes.string,
    }),
    weekdays: PropTypes.arrayOf(PropTypes.string),
    equipment_types: PropTypes.arrayOf(PropTypes.string),
    starts_at: PropTypes.string,
    expires_at: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

RemovePreferredLaneModal.defaultProps = {
  lane: {},
  loading: false,
}

export default RemovePreferredLaneModal

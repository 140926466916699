import { type ComponentType } from 'react'

import { getCookie, setCookie } from '../infra/cookie'

const COOKIE_KEY = `chunk-retries`

type Loader<T = any> = () => Promise<{ default: T }>

export function componentLoader<T extends ComponentType<any>>(loader: Loader<T>) {
  return async function componentLoaderImpl() {
    try {
      const Component = await loader()
      setCookie(COOKIE_KEY, 0)
      return Component
    } catch (error) {
      const { name } = error as Error

      const retries = Number(getCookie(COOKIE_KEY) || 0)

      if (name !== 'ChunkLoadError' || retries > 3) {
        setCookie(COOKIE_KEY, 0)
        throw error
      }

      setCookie(COOKIE_KEY, retries + 1)
      window.location.reload()

      // returning an empty component to avoid crashes
      // had to force T type to please typescript since
      // the inferred component type might differ from () => null
      return { default: () => null } as unknown as { default: T }
    }
  }
}

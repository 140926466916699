import loadboard from 'legacy/containers/LoadsPage/constants/en'

const msgs = {
  // Accessorials
  'ls.components.Accessorials.tarp': 'TARP',
  'ls.components.Accessorials.lift': 'Lift Gate',
  'ls.components.Accessorials.pallet': 'Pallet Jack',
  'ls.components.Accessorials.airport': 'Airport',
  'ls.components.Accessorials.cfs': 'CFS',
  'ls.components.Accessorials.tsa': 'TSA',
  'ls.components.Accessorials.lumper': 'Lumper',
  'ls.components.Accessorials.bol': 'Blind Bol',
  'ls.components.Accessorials.heavy': 'Heavy Scale Ticket',
  'ls.components.Accessorials.light': 'Light Scale Ticket',
  'ls.components.Accessorials.drop': 'Drop Trailer',

  // Navigation
  'ls.components.Navigation.link.account': 'Account',
  'ls.components.Navigation.link.users': ' Users',
  'ls.components.Navigation.link.loads': 'Book',
  'ls.components.Navigation.link.fleet': 'Fleet',
  'ls.components.Navigation.link.shipments': 'Shipments',
  'ls.components.Navigation.link.rateConfirmation': 'Rate Confirmation',
  'ls.components.Navigation.link.chat': 'Chat',
  'ls.components.Navigation.link.analytics': 'History',
  'ls.components.Navigation.link.book': 'book',
  'ls.components.Navigation.link.shipper': 'Shipper',
  'ls.components.Navigation.link.carrier': 'Become a Carrier',
  'ls.components.Navigation.link.login': 'login',
  'ls.components.Navigation.link.signup': 'sign up',
  'ls.components.Navigation.link.forgot': 'forgot',
  'ls.components.Navigation.link.matching': 'Match',
  'ls.components.Navigation.link.facilities': 'facilities',

  // Login Dialog
  'ls.components.LoginDialog.title': 'Login',
  'ls.components.LoginDialog.link.forgot': 'Forgot your password?',
  'ls.components.LoginDialog.labels.password': 'Password',
  'ls.components.LoginDialog.labels.email': 'Email',
  'ls.components.LoginDialog.labels.noAccount': "Don't have an account? ",
  'ls.components.LoginDialog.labels.signup': 'Sign up',
  'ls.components.LoginDialog.labels.invalid': 'Invalid username or password',
  'ls.components.LoginDialog.labels.activated':
    'This account has not been activated yet. Please check your email',

  // Reset Password Dialog
  'ls.components.ResetPasswordEmail.labels.title': 'CHECK YOUR EMAIL',
  'ls.components.ResetPasswordEmail.labels.msg':
    "We've sent an email to reset your password.Please check your email, it may take a few minutes.",
  'ls.components.ResetPasswordEmail.labels.followUs': 'Follow us!',

  // Validation Hints
  'ls.components.Validation.labels.password': 'Password is required.',
  'ls.components.Validation.labels.email': 'Email is required',
  'ls.components.Validation.labels.emailInvalid': 'Email must be a valid email',
  'ls.components.Validation.labels.passwordNumber': 'Passwords should contain at least one number',
  'ls.components.Validation.labels.passwordLength': 'The minimum length is 8',
  'ls.components.Validation.labels.passwordMismatch': 'Password do not match',
  'ls.components.Validation.labels.phoneLength': 'Phone number must be at least 10 digits long',
  'ls.components.Validation.labels.phoneExists': 'This phone number already exists in our database',
  'ls.components.Validation.labels.loginFail': 'Email or password is incorrect',
  'ls.components.Validation.labels.company': 'Company name is required',
  'ls.components.Validation.labels.required': 'Required Field',
  'ls.components.Validation.labels.phoneRequired': 'Phone number is required',
  'ls.components.Validation.labels.driverRequired': 'Driver name is required',
  'ls.components.Validation.labels.equipment': 'You have to select your equipment',
  'ls.components.Validation.labels.scac': 'SCAC should contain only letters',
  'ls.components.Validation.labels.uiia': 'You need to have a valid UIIA',
  'ls.components.Validation.labels.portAccess': 'Port access required. Please contact us.',

  // Equipment Type
  'ls.components.EquipmentType.drv': 'Dry Van',
  'ls.components.EquipmentType.rfr': 'Reefer',
  'ls.components.EquipmentType.fbe': 'Flatbed',

  // PreferredLaneRateModal
  'ls.components.PreferredLaneRateModal.error': 'Unable to add rate now. Try again later',
  'ls.components.PreferredLaneRateModal.header': 'New preferred lane added successfuly!',
  'ls.components.PreferredLaneRateModal.anywhere': 'Anywhere',
  'ls.components.PreferredLaneRateModal.message': "What's your minimum all in rate for this lane?",
  'ls.components.PreferredLaneRateModal.addRate': 'Add Rate',
  'ls.components.PreferredLaneRateModal.editRate': 'Edit Rate',
  'ls.components.PreferredLaneRateModal.notNow': 'Not Now',
  'ls.components.PreferredLaneRateModal.cancel': 'Cancel',

  // LoadWeb
  'ls.components.LoadWeb.labels.help': 'I would like some help with this Instant Load! Going from:',
  'ls.components.LoadWeb.labels.sd_help':
    'I would like some help with this ShipperDirect Load! Going from:',
  'ls.components.LoadWeb.labels.contracted_help':
    'I would like some help with this Contracted Load! Going from:',

  ...loadboard,
}

export default msgs

import { user as User } from 'legacy/components/User/User'
import { analytics } from 'legacy/utils/analytics'
import { initRUM } from 'legacy/utils/datadog'
import { sentry } from 'legacy/utils/sentry'
import { storage } from 'legacy/utils/storage'
import { user } from 'legacy/utils/user'

import { createUserRepository } from 'auth/infra/userRepository'

import { SalesforceChatClient } from '../infra/communication/SalesforceChatClient'
import { getCookie, removeCookie } from '../infra/cookie'
import { DelightedAdapter } from '../infra/DelightedAdapter'
import { FirebaseClient } from '../infra/FirebaseClient'
import { createTokenInterceptor, HttpClient } from '../infra/http/HttpClient'
import { type NPSSurveyAdapter } from '../infra/NPSSurveyAdapter'
import { LocalStorageClient } from '../infra/storage/LocalStorageClient'

export function initApplication() {
  const sharedToken = getCookie('lsUserToken')
  const storageToken = storage.get('loadSmart.user-token')
  // We are getting the shared token from `app-web` and making sure that we
  // populate the other cookies accordingly so the application works
  // TODO: refactor multiple session tokens stored in different places
  if (sharedToken && !storageToken) {
    User.setTokenInfo({ access_token: sharedToken })
    // attempt to clear the shared session so the user can logout peacefully
    removeCookie('lsUserToken')
  }

  const userRepository = createUserRepository({
    storage: new LocalStorageClient(),
  })

  HttpClient.setup({
    request: createTokenInterceptor({ userRepository }),
  })

  // Salesforce Chat
  SalesforceChatClient.init()

  // Firebase
  FirebaseClient.init()

  // Legacy Analytics
  analytics.init()
  analytics.setup()

  // Legacy Sentry
  sentry.config()
  initRUM()

  // We won't even attempt to start delighted if we don't have the user token and email
  if ((sharedToken || storageToken) && user.get('email')) {
    const npsSurveyAdapter: NPSSurveyAdapter = new DelightedAdapter(window.delighted, {
      firstName: user.get('firstName'),
      lastName: user.get('lastName'),
      email: user.get('email'),
      carrierFleetSize: user.get('carrierFleetSize'),
      role: user.get('role'),
      entityType: user.get('entityType'),
    })

    npsSurveyAdapter.runSurvey()
  }
}

import '@loadsmart/miranda-react/dist/styles/variables.css'
import 'react-toastify/dist/ReactToastify.css'

import React from 'react'
import ReactDOM from 'react-dom'

import { App } from 'core/App'
import { IS_MSW_ACTIVE, __DEV__ } from 'core/infra/environment'
import { reportWebVitals } from 'core/infra/reportWebVitals'
import { initApplication } from 'core/use-cases/initApplication'

function main() {
  initApplication()

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )

  reportWebVitals()
}

if (IS_MSW_ACTIVE && __DEV__) {
  import('./test/msw/browser').then(({ worker }) => worker.start()).finally(() => main())
} else {
  main()
}

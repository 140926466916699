const topOffers = {
  'lb.topOffers.title': 'Recommended Loads for you',
  'lb.topOffers.subtitle': 'This is a recommended load based on your profile preferences',
  'lb.topOffers.seeLess': 'See Less',
  'lb.topOffers.seeMore': 'See More',
  'lb.topOffers.feedback.title': 'This load is bad because...',
  'lb.topOffers.feedback.cta': 'Send Feedback',
  'lb.topOffers.feedback.reasons.appointmentTime': 'Appointment Times',
  'lb.topOffers.feedback.reasons.puDate': 'PU Date',
  'lb.topOffers.feedback.reasons.allInRate': 'All-in-Rate',
  'lb.topOffers.feedback.reasons.pricePerMile': 'Price per Mile',
  'lb.topOffers.feedback.reasons.facilities': 'Facilities',
  'lb.topOffers.feedback.reasons.weight': 'Weight',
  'lb.topOffers.feedback.reasons.deadhead': 'Deadhead',
  'lb.topOffers.feedback.reasons.other': 'Other',
}

const offerCard = {
  'lb.offerCard.feedback.text': 'Don’t like this load?',
  'lb.offerCard.feedback.cta': 'Tell us why',
}

const loadDetails = {
  'lb.loadDetails.information.dwellTime': 'AVG DWELL TIME',
  'lb.loadDetails.information.detention': 'CHANCE OF DETENTION',
}

const loadStop = {
  'lb.loadStop.features.fastFacility': 'Fast facility',
  'lb.loadStop.features.topRatedFacility': 'Top rated facility',
  'lb.loadStop.features.earlyAppointment': 'Early appointment',
  'lb.loadStop.features.earlyAppt': 'Early Appt',
}

const booking = {
  // Truck Info Step
  'lb.booking.truckInfo.title': 'Truck info and location',
  'lb.booking.truckInfo.subTitle': "Let us know about your truck's info and MT location.",
  'lb.booking.truckInfo.labelb.date': 'Date',
  'lb.booking.truckInfo.placeHolder.date': '"MM / DD / YYYY"',
  'lb.booking.truckInfo.labelb.time': 'Military Time',
  'lb.booking.truckInfo.placeHolder.time': 'HHMM',
  'lb.booking.truckInfo.labelb.location': 'Location',
  'lb.booking.truckInfo.placeHolder.location': 'City, State or Zip',
  'lb.booking.truckInfo.labelb.tractorNumber': 'Tractor',
  'lb.booking.truckInfo.labelb.trailerNumber': 'Trailer',
  'lb.booking.truckInfo.labelb.optional': '(optional)',
  'lb.booking.truckInfo.placeHolder.number': 'Number',

  // Assign Driver
  'lb.booking.driver.addNewDriver': 'Add New Driver',
  'lb.booking.driver.driverRequired': 'You have to choose one driver',
}

const appSuggestion = {
  'lb.appSuggestion.message': 'Find, negotiate and book loads instantly with our mobile app',
  'lb.appSuggestion.download': 'Download it now',
  'lb.appSuggestion.dismiss': 'Dismiss app suggestion',
}

const outletDialog = {
  'lb.outletDialog.goToSignup': 'Already have an account?',
  'lb.outletDialog.goToLogin': 'Create an account',

  'lb.outletDialog.signUp.title.default':
    'Want to be instantly notified about new loads? Finish your account',
  'lb.outletDialog.signUp.title.default.preferredLane':
    'Want to be instantly notified about loads on this lane? Finish your account',
  'lb.outletDialog.signUp.title.loadTaken':
    'Load already taken. Finish your account to be notified about new loads',
  'lb.outletDialog.signUp.title.loadTaken.preferredLane':
    'Load already taken. Finish your account to be notified about loads on this lane',
  'lb.outletDialog.signUp.firstName': 'First name',
  'lb.outletDialog.signUp.lastName': 'Last name',
  'lb.outletDialog.signUp.email': 'Email',
  'lb.outletDialog.signUp.password': 'Password',
  'lb.outletDialog.signUp.confirmPassword': 'Confirm password',
  'lb.outletDialog.signUp.dotNumber': 'DOT Number',
  'lb.outletDialog.signUp.mcNumber': 'MC Number',
  'lb.outletDialog.signUp.modeToggle.ftl': 'Full Truck Load',
  'lb.outletDialog.signUp.modeToggle.drayage': 'Drayage',
  'lb.outletDialog.signUp.scacCode': 'SCAC Code',
  'lb.outletDialog.signUp.submit': 'Finish your account',
  'lb.outletDialog.signUp.cancel': 'Cancel',

  'lb.outletDialog.validUIIA.part1': 'By checking this box, I confirm I have a ',
  'lb.outletDialog.validUIIA.link': 'valid UIIA',
  'lb.outletDialog.validUIIA.part2':
    ' for all equipment providers at the port terminals or rail ramps from where I will accept Loadsmart shipments.',
  'lb.outletDialog.drayageRequirements':
    'By checking this box, I confirm I meet the requirements for access to any port terminal or rail ramp from where I accept a Loadsmart shipment.',

  'lb.outletDialog.signUp.success.title': 'Account created successfully',
  'lb.outletDialog.signUp.success.text':
    'Please check your inbox e-mail and click on the link to activate your account.',
  'lb.outletDialog.signUp.success.resendEmail': 'Re-send email',
  'lb.outletDialog.signUp.success.emailSent': 'Email sent!',
  'lb.outletDialog.signUp.success.close': 'Close',

  'lb.outletDialog.login.title': 'Login',
  'lb.outletDialog.login.title.preferredLane': 'Login to be notified about loads on this lane',
  'lb.outletDialog.login.email': 'Email',
  'lb.outletDialog.login.password': 'Password',
  'lb.outletDialog.login.cta': 'Log in',
  'lb.outletDialog.login.close': 'Close',

  'lb.outletDialog.forgotPassword.link': 'Forgot password?',
  'lb.outletDialog.forgotPassword.title': 'Forgot password?',
  'lb.outletDialog.forgotPassword.retrievePassword': 'Retrieve my password',
  'lb.outletDialog.forgotPassword.email': 'Email',
  'lb.outletDialog.forgotPassword.cancel': 'Cancel',
  'lb.outletDialog.forgotPassword.invalidEmail': 'Invalid email address',
  'lb.outletDialog.forgotPassword.requiredEmail': 'Email is required',
  'lb.outletDialog.forgotPassword.error': 'Something went wrong',
  'lb.outletDialog.forgotPassword.success.title': 'Check your email',
  'lb.outletDialog.forgotPassword.success.text':
    "We've sent an email to reset your password. Please check your email, it may take a few minutes.",
}

const loadTaken = {
  'lb.loadTaken.message': 'This load is no longer available :(',
}

const preferredLaneToggle = {
  'lb.preferredLaneToggle.save': 'Save preferred lane',
  'lb.preferredLaneToggle.saved': 'Saved preferred lane',
  'lb.preferredLaneToggle.inactive': 'I want to be notified about loads in this lane',
  'lb.preferredLaneToggle.active': 'You will be notified about loads in this lane',
  'lb.preferredLaneToggle.tooltip.active':
    "I don't want to be notified about loads with these parameters:",
  'lb.preferredLaneToggle.tooltip.inactive':
    'I want to be notified about loads with these parameters:',
}

const RMIS = {
  'lb.RMIS.onboardingBanner.title': 'Validate your documents in RMIS to start booking',
  'lb.RMIS.onboardingBanner.description':
    'You will not be able to book loads until RMIS approves your documents.',
  'lb.RMIS.onboardingBanner.estimatedTime': "It's easy and takes less than 5 minutes.",
  'lb.RMIS.onboardingBanner.goToRMIS': 'Go to RMIS',
  'lb.RMIS.onboardingBanner.completed': 'You are now able to book and move loads with us!',
  'lb.RMIS.onboardingBanner.underReview.title': 'We are validating your documents',
  'lb.RMIS.onboardingBanner.underReview.description':
    'Hang tight! We are currently processing your documents and will get back to you as soon as they have been validated.',
  'lb.RMIS.onboardingBanner.underReview.disclaimer':
    "If you don't receive an answer in 2 business days, please contact our support.",
}

const filters = {
  // features
  'lb.filters.features.label': 'Feature',
  'lb.filters.features.placeholder': 'Select a feature',
  'lb.filters.features.all': 'All features',
  'lb.filters.features.fastFacility': 'Fast facility',
  'lb.filters.features.topRatedFacility': 'Top rated facility',
  'lb.filters.features.earlyAppointment': 'Early appointment',
  'lb.filters.features.palletized': 'Palletized',

  // modes
  'lb.filters.modes.label': 'Mode',
  'lb.filters.modes.placeholder': 'All modes',
  'lb.filters.modes.ftl': 'FTL',
  'lb.filters.modes.ptl': 'PTL',
}

const bidding = {
  'lb.bidding.dialog.toast.lowerBid': 'Try again with a lower bid',
  'lb.bidding.dialog.lastRejectedBids': 'Last Rejected Bids',
  'lb.bidding.dialog.action.placeBid': 'Place Bid',
  'lb.bidding.dialog.action.bookNow': 'Book it now for',
  'lb.bidding.dialog.action.removeBid': 'Remove Bid',
  'lb.bidding.dialog.action.updateBid': 'Update Bid',
}

export default {
  ...topOffers,
  ...offerCard,
  ...loadDetails,
  ...loadStop,
  ...booking,
  ...appSuggestion,
  ...outletDialog,
  ...loadTaken,
  ...preferredLaneToggle,
  ...RMIS,
  ...filters,
  ...bidding,
}

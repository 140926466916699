import PropTypes from 'prop-types'
import { Children, useMemo } from 'react'

import {
  InputGroupContainer,
  InputGroupInnerContainer,
  InputGroupWrapper,
  AppendedContentWrapper,
} from './InputGroup.styles'

export const InputGroup = ({ children, append }) => {
  const wrappedChildren = useMemo(
    () =>
      Children.map(children, someChild => {
        return <InputGroupWrapper hasAppendedContent={append}>{someChild}</InputGroupWrapper>
      }),
    [append, children]
  )

  return (
    <InputGroupContainer>
      <InputGroupInnerContainer>{wrappedChildren}</InputGroupInnerContainer>
      <AppendedContentWrapper>{append}</AppendedContentWrapper>
    </InputGroupContainer>
  )
}

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  append: PropTypes.node,
}

InputGroup.defaultProps = {
  append: null,
}

export default InputGroup

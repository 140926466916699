import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

export type NavigationContextType =
  | {
      opened: boolean
      close: () => void
      open: () => void
      toggle: () => void
    }
  | undefined

export const NavigationContext = createContext<NavigationContextType>(undefined)
NavigationContext.displayName = 'NavigationContext'

export function useNavigationContext() {
  const context = useContext(NavigationContext)

  if (context === undefined) {
    throw new Error('useNavigationContext must be used within a NavigationContext')
  }

  return context
}

export function NavigationProvider({ children }: { children?: React.ReactNode }) {
  const [opened, setOpened] = useState<boolean>(false)

  const close = useCallback(() => {
    setOpened(false)
  }, [])

  const open = useCallback(() => {
    setOpened(true)
  }, [])

  const toggle = useCallback(() => {
    setOpened(prevState => !prevState)
  }, [])

  const memoizedValue = useMemo(
    () => ({
      opened,
      open,
      close,
      toggle,
    }),
    [opened, open, close, toggle]
  )

  return <NavigationContext.Provider value={memoizedValue}>{children}</NavigationContext.Provider>
}

import { useCallback, useState } from 'react'

import { getCookie, removeCookie, setCookie, type CookieOptions } from '../../infra/cookie'

export function useCookie<T = string>(
  name: string,
  options?: CookieOptions
): [T | undefined, (value: T) => void, () => void] {
  const [value, setValue] = useState<T | undefined>(() => getCookie<T>(name))

  const handleSetCookie = useCallback(
    (newValue: T) => {
      setCookie<T>(name, newValue, options)
      setValue(getCookie<T>(name))
    },
    [name, options]
  )

  const handleRemoveCookie = useCallback(() => removeCookie(name, options), [name, options])

  return [value, handleSetCookie, handleRemoveCookie]
}

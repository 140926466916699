import { sentry } from 'legacy/utils/sentry'

import { type NPSSurveyAdapter } from './NPSSurveyAdapter'

type Client = any

interface DelightedAdapterData {
  firstName: string | null
  lastName: string | null
  email: string | null
  carrierFleetSize: string | null
  role: string | null
  entityType: string | null
}

class DelightedAdapter implements NPSSurveyAdapter {
  private client: Client
  private firstName?: string | null
  private lastName?: string | null
  private email?: string | null
  private carrierFleetSize?: string | null
  private role?: string | null
  private entityType?: string | null

  constructor(client: Client, data?: Partial<DelightedAdapterData>) {
    this.client = client
    this.firstName = data?.firstName
    this.lastName = data?.lastName
    this.email = data?.email
    this.carrierFleetSize = data?.carrierFleetSize
    this.role = data?.role
    this.entityType = data?.entityType
  }

  private get name() {
    return [this.firstName, this.lastName]
      .map(name => name?.trim())
      .filter(name => name != null && name !== '')
      .join(' ')
  }

  private validate() {
    if (this.client == null) {
      throw new Error('Delighted: client is not defined')
    }

    const requiredFields = ['email', 'carrierFleetSize', 'role']
    const missingFields = requiredFields.filter(field => {
      // @ts-expect-error Don't care about type correctness here, just want to know if null or not
      return this[field] == null
    })

    if (missingFields.length > 0) {
      throw new Error(`Delighted: The required fields [${missingFields.join(', ')}] are missing`)
    }
  }

  runSurvey(): void {
    try {
      this.validate()

      this.client.survey({
        name: this.name,
        email: this.email,
        properties: {
          carrierFleetSize: this.carrierFleetSize,
          role: this.role,
          entityType: this.entityType,
        },
      })
    } catch (error) {
      sentry.log(error, { level: 'warning' })
    }
  }
}

export { DelightedAdapter, type DelightedAdapterData }

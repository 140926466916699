import styled from 'styled-components/macro'

import { getToken } from '@loadsmart/miranda-react/dist/tokens'

import { screen } from 'legacy/styles/screen'

export const Map = styled.div`
  padding: 20px 20px 0 0;

  ${screen.md} {
    display: inline-block;
  }
`

export const MapWrapper = styled.div`
  svg {
    display: none;

    ${screen.md} {
      display: inline-block;
      margin-right: 3%;
      padding-top: 50px;
      vertical-align: top;
    }
  }
`

export const Table = styled.table`
  color: rgb(${getToken('color-text-tertiary')});
  display: block;
  margin-top: 30px;
  margin-top: 0;
  user-select: none;
  width: 100%;

  ${screen.md} {
    margin-top: 30px;
    display: inline-block;
  }

  ${screen.lg} {
    padding-left: 30px;
  }

  tbody {
    display: block;

    ${screen.md} {
      display: table-row-group;
    }
  }

  tr {
    display: block;

    ${screen.md} {
      display: table-row;
    }
  }

  th {
    color: rgb(${getToken('color-text-tertiary')});
    cursor: pointer;
    display: none;
    font-weight: 700;
    padding: 0 0 5px 22px;
    text-align: left;
    text-transform: uppercase;
    width: 25%;

    ${screen.md} {
      display: table-cell;
    }
  }

  td {
    font-weight: 500;
    line-height: 1.8em;
    position: relative;
    display: block;

    ${screen.md} {
      display: table-cell;
      width: 25%;
    }
  }
`

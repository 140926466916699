import utcToZonedTimeFn from 'date-fns-tz/utcToZonedTime'

import DEPRECATED_TZ from 'legacy/constants/deprecatedTz'

import parseISO from './parseISO'

/**
 * @param {string} date
 * @param {string} [timezone]
 * @param {import('date-fns-tz').OptionsWithTZ} [options]
 * @returns {Date}
 */
export function utcToZonedTime(date, timezone = 'UTC', options = {}) {
  try {
    return utcToZonedTimeFn(parseISO(date), timezone, options)
  } catch (error) {
    if (Object.keys(DEPRECATED_TZ).includes(timezone)) {
      console.warn(`Deprecated Timezone - ${timezone}`)
      return utcToZonedTimeFn(parseISO(date), DEPRECATED_TZ[timezone], options)
    } else {
      // Not ideal, but it avoids the page crashing with unsupported timezones
      console.error(`Unsupported Timezone - ${timezone}`)
      return utcToZonedTimeFn(parseISO(date), 'UTF', options)
    }
  }
}

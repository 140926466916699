import React, { createContext, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useAuthContext } from 'core/ui/contexts/authContext'
import { useAbortController } from 'core/ui/hooks/useAbortController'

import sentry from 'legacy/utils/sentry'

import { type UserAccount } from '../domain/UserAccount'
import { getUserAccount } from '../use-cases/getUserAccount'

export const UserAccountContext = createContext<UserAccount | undefined>(undefined)
UserAccountContext.displayName = 'UserAccountContext'

export function UserAccountProvider({ children }: { children?: React.ReactNode }) {
  const [account, setAccount] = useState<UserAccount | undefined>(undefined)
  const abortController = useAbortController()
  const [userToken] = useAuthContext()

  useEffect(
    function onFirstRender() {
      async function getAccount() {
        try {
          const userAccount = await getUserAccount({ signal: abortController.signal })
          setAccount(userAccount)
        } catch (error) {
          if (!abortController.signal.aborted) {
            toast.error('An error occurred, please try again later')
            sentry.log(error)
          }
        }
      }

      if (userToken) getAccount()
    },
    [abortController.signal, userToken]
  )

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [abortController])

  return <UserAccountContext.Provider value={account}>{children}</UserAccountContext.Provider>
}

export function useUserAccountContext() {
  return useContext(UserAccountContext)
}

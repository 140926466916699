import { DropdownMenu } from './DropdownMenu'
import { StyledMenuItem } from './Navigation.styles'

export function Manage() {
  return (
    <DropdownMenu title="Manage">
      <StyledMenuItem title="Payments" url="/payments" stamp="NEW" />
      <StyledMenuItem title="My Lanes" url="/preferred-lanes" />
      <StyledMenuItem title="History" url="/history" />
      <StyledMenuItem title="Fleet" url="/fleet" />
    </DropdownMenu>
  )
}

const DEPRECATED_TZ = {
    'US/Alaska': 'America/Anchorage',
    'US/Aleutian': 'America/Adak',
    'US/Arizona': 'America/Phoenix',
    'US/Central': 'America/Chicago',
    'US/Eastern': 'America/New_York',
    'US/East-Indiana': 'America/Indiana/Indianapolis',
    'US/Hawaii': 'Pacific/Honolulu',
    'US/Indiana-Starke': 'America/Indiana/Knox',
    'US/Michigan': 'America/Detroit',
    'US/Mountain': 'America/Denver',
    'US/Pacific': 'America/Los_Angeles',
    'US/Pacific-New': 'America/Los_Angeles',
    'US/Samoa': 'Pacific/Pago_Pago',
};

export default DEPRECATED_TZ;

import PropTypes from 'prop-types'
import { useRef, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'

import { IconCaretDown } from '@loadsmart/icons'

import useWindowWidth from 'core/ui/hooks/useWindowWidth'

import Input, { inputBaseStyle } from 'legacy/components/InputV2/Input'

// If we pass null as `value`, browser will automatically return the text content inside of it as fallback.
// If we pass "null" as `value`, we have to check against "null", so we use a different more specific string instead.
const PLACEHOLDER_VALUE = '__PLACEHOLDER__'

const SmallIconCaretDown = () => <IconCaretDown height={10} />

const SelectContainer = styled.div``

const StyledMobileSelect = styled.select`
  ${inputBaseStyle};
`

const Select = ({
  suggestions: givenSuggestions,
  onSuggestionSelect,
  value,
  placeholder,
  identifierProp,
  displayProp,
  onClearButtonClick,
  ...passThrough
}) => {
  const windowWidth = useWindowWidth()
  const hiddenSelectRef = useRef()
  const hiddenSelectLabelRef = useRef()

  const useNativeSelect = windowWidth < 1024

  const handleHiddenSelectChange = useCallback(
    event => {
      event.persist()
      const value = event.target.value

      if (value === null || value === undefined || value === PLACEHOLDER_VALUE) {
        return onSuggestionSelect(null, event)
      }

      return onSuggestionSelect(event.target.value, event)
    },
    [onSuggestionSelect]
  )

  const handleFocus = useCallback(() => {
    if (!useNativeSelect) {
      return false
    }

    hiddenSelectLabelRef.current.focus()
  }, [useNativeSelect])

  const handleClearButtonClick = useCallback(
    event => {
      onClearButtonClick(passThrough.name, event)
      onSuggestionSelect(null, event)
    },
    [onClearButtonClick, onSuggestionSelect, passThrough.name]
  )

  const suggestions = useMemo(
    () =>
      givenSuggestions.map((someSuggestion = {}) =>
        displayProp === 'label' && identifierProp === 'value'
          ? someSuggestion
          : {
              label: someSuggestion[displayProp],
              value: someSuggestion[identifierProp],
            }
      ),
    [givenSuggestions, displayProp, identifierProp]
  )

  const selectedSuggestion = useMemo(
    () =>
      suggestions.find(someSuggestion => someSuggestion.value === value) || {
        label: '',
        value: '',
      },
    [suggestions, value]
  )

  return (
    <SelectContainer>
      {!useNativeSelect && (
        <Input
          icon={onClearButtonClick ? null : SmallIconCaretDown}
          iconAlignment={onClearButtonClick ? null : 'right'}
          suggestions={suggestions}
          onSuggestionSelect={onSuggestionSelect}
          value={selectedSuggestion.label}
          placeholder={placeholder}
          selectedSuggestion={selectedSuggestion}
          onClearButtonClick={onClearButtonClick ? handleClearButtonClick : null}
          {...passThrough}
          onFocus={handleFocus}
          blockSuggestions={useNativeSelect}
          selectOnly
        />
      )}

      {useNativeSelect && (
        <label ref={hiddenSelectLabelRef}>
          <StyledMobileSelect
            value={selectedSuggestion.value}
            onChange={handleHiddenSelectChange}
            ref={hiddenSelectRef}
            data-testid="select-mobile-native"
            {...passThrough}
          >
            {!!placeholder && (
              <option key="null" value={PLACEHOLDER_VALUE} data-testid="placeholder-option">
                {placeholder}
              </option>
            )}

            {suggestions.map(someSuggestion => (
              <option key={someSuggestion.value} value={someSuggestion.value}>
                {someSuggestion.label}
              </option>
            ))}
          </StyledMobileSelect>
        </label>
      )}
    </SelectContainer>
  )
}

Select.propTypes = {
  displayProp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  identifierProp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClearButtonClick: PropTypes.func,
}

Select.defaultProps = {
  displayProp: 'label',
  identifierProp: 'value',
  placeholder: '',
  onClearButtonClick: null,
}

export { Select, PLACEHOLDER_VALUE, SelectContainer }
export default Select

import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Layout, Text, Dialog } from '@loadsmart/miranda-react'

import { AddPreferredLane } from 'legacy/containers/PreferredLanes/AddPreferredLane/AddPreferredLane'
import { PreferredLane as PreferredLaneComponent } from 'legacy/containers/PreferredLanes/PreferredLane'
import { RemovePreferredLaneModal } from 'legacy/containers/PreferredLanes/RemovePreferredLaneModal/RemovePreferredLaneModal'
import { analytics } from 'legacy/utils/analytics'

import { OnboardingStep } from 'onboarding/domain/Onboarding'

import {
  type PreferredLane,
  canAddMoreLanes as getCanAddMoreLanes,
  hasPreferredLane,
  removePreferredLane,
} from '../domain/PreferredLane'
import { deletePreferredLane } from '../use-cases/deletePreferredLane'
import { addPreferredLaneStyles } from './AddPreferredLaneDialog.styles'

export type AddPreferredLaneDialogProps = {
  title?: string
  body?: React.ReactNode
  handleStep: (stepName: OnboardingStep, nextStepName?: OnboardingStep) => void
  nextStep?: OnboardingStep
}

const analyticsEvents = {
  onView: 'Setup / Preferred Lanes / View',
  onAddSuccess: 'Setup / Preferred Lanes / Add',
  onAddError: 'Setup / Preferred Lanes / Add / Error',
  onConfirm: 'Setup / Preferred Lanes / Confirm',
}

export const handleTrackAddSuccess = (payload: PreferredLane) => {
  analytics.track(analyticsEvents.onAddSuccess, payload)
}

export const handleTrackAddError = () => {
  analytics.track(analyticsEvents.onAddError)
}

export function AddPreferredLaneDialog({ handleStep, nextStep }: AddPreferredLaneDialogProps) {
  const [preferredLanes, setPreferredLanes] = useState<PreferredLane[]>([])
  const [preferredLaneBeingDeleted, setPreferredLaneBeingDeleted] = useState<PreferredLane | null>(
    null
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const canAddMoreLanes = getCanAddMoreLanes(preferredLanes.length)
  const hasPreferredLanes = preferredLanes.length > 0

  const handleOnFinish = useCallback(() => {
    analytics.track(analyticsEvents.onConfirm, {
      numberOfLanes: preferredLanes.length,
      preferredLanes,
    })
    handleStep(OnboardingStep.PreferredLanes, nextStep)
  }, [handleStep, preferredLanes, nextStep])

  function handleAddPreferredLane(newLane: PreferredLane) {
    setPreferredLanes(prevPreferredLanes => [...prevPreferredLanes, newLane])
  }

  function handlePreferredLaneRemoveRequest(lane: PreferredLane) {
    setPreferredLaneBeingDeleted(lane)
  }

  async function handleRemovePreferredLane(laneUuid: string) {
    try {
      setIsLoading(true)
      // TODO: Will be refactored later on CM-1041, from now I just wanted to
      // remove the use-case being called inside de domain
      if (hasPreferredLane(preferredLanes, laneUuid)) {
        await deletePreferredLane(laneUuid)
        setPreferredLanes(prevPreferredLanes => removePreferredLane(prevPreferredLanes, laneUuid))
      }
      setPreferredLaneBeingDeleted(null)
      toast.info('Preferred lane removed successfully')
    } catch {
      toast.error('An error ocurred when trying to remove this preferred lane')
    } finally {
      setIsLoading(false)
    }
  }

  function handleCancelRemovePreferredLane() {
    setPreferredLaneBeingDeleted(null)
  }

  function handleUpdatePreferredLane(updatedLane: PreferredLane) {
    setPreferredLanes(prevPreferredLanes =>
      prevPreferredLanes.map(lane => (lane.uuid === updatedLane.uuid ? updatedLane : lane))
    )
  }

  function getDialogTitle() {
    if (!canAddMoreLanes) return 'These are your preferred lanes'
    if (hasPreferredLanes) return 'Choose your preferred lanes!'
    return 'Insert your preferred lanes!'
  }

  useEffect(() => {
    analytics.track(analyticsEvents.onView)
  }, [])

  return (
    <>
      <Dialog.Body>
        <Layout.Stack gap="spacing-6">
          <Layout.Stack gap="spacing-4">
            <Text variant="heading-md-bold">{getDialogTitle()}</Text>
            <Text>Tell us your preferred lanes so we can focus on the best matches for you</Text>
          </Layout.Stack>
          {canAddMoreLanes && (
            <AddPreferredLane
              key={preferredLanes.length}
              style={addPreferredLaneStyles}
              trackSuccess={handleTrackAddSuccess}
              trackError={handleTrackAddError}
              onNewLaneAdded={handleAddPreferredLane}
              enableNotifyPeriod={false}
            />
          )}
          {hasPreferredLanes && (
            <Layout.Stack>
              <Text variant="heading-sm-bold">Preferred lanes added</Text>
              {preferredLanes.map(lane => (
                <PreferredLaneComponent
                  key={lane.uuid}
                  lane={lane}
                  onLaneRemoveRequest={handlePreferredLaneRemoveRequest}
                  onLaneUpdate={handleUpdatePreferredLane}
                />
              ))}
            </Layout.Stack>
          )}
        </Layout.Stack>
        {preferredLaneBeingDeleted && (
          <RemovePreferredLaneModal
            isOpen
            lane={preferredLaneBeingDeleted}
            onConfirm={handleRemovePreferredLane}
            onCancel={handleCancelRemovePreferredLane}
            loading={isLoading}
          />
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Layout.Group justify="flex-end">
          <Dialog.ActionPrimary onClick={handleOnFinish} disabled={!hasPreferredLanes}>
            Finish
          </Dialog.ActionPrimary>
        </Layout.Group>
      </Dialog.Footer>
    </>
  )
}

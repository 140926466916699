import { API_URL } from 'core/infra/environment'
import { HttpClient } from 'core/infra/http/HttpClient'

import { AccountRepository } from '../infra/AccountRepository'

export type ReSendEmailFactoryDependencies = {
  repository: AccountRepository
}

export function reSendEmailFactory({ repository }: ReSendEmailFactoryDependencies) {
  return function (email: string, config?: { signal?: AbortSignal }) {
    return repository.reSendEmail(email, config)
  }
}

export function reSendEmail(email: string, config?: { signal?: AbortSignal }) {
  const httpClient = new HttpClient(API_URL)
  const repository = new AccountRepository({ httpClient })
  const newReSendEmail = reSendEmailFactory({ repository })
  return newReSendEmail(email, config)
}

import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { Dialog } from '@loadsmart/loadsmart-ui'

import analytics from 'legacy/utils/analytics'

import { confirmBrokerWelcome } from '../../../use-cases/confirmBrokerWelcome'
import { getBrokerWelcomeState } from '../../../use-cases/getBrokerWelcomeState'

const StyledBody = styled(Dialog.Body)`
  text-align: left;
`

export function WelcomeBrokerModal() {
  const [open, setOpen] = useState(() => {
    return !getBrokerWelcomeState()
  })

  function closeDialog() {
    setOpen(false)
    confirmBrokerWelcome()
  }

  useEffect(
    function onMount() {
      if (open) {
        analytics.track('Broker Welcome / View')
      }
    },
    [open]
  )

  return (
    <Dialog open={open} onOverlayClick={closeDialog}>
      <Dialog.Header>Welcome!</Dialog.Header>
      <StyledBody>
        <p>Welcome! We're glad to have you here.</p>
        <p>
          We want to provide the best experience for your company while using Loadsmart, no matter
          if you're a carrier, shipper, or broker.
        </p>
        <p>
          As a Broker, you can use Loadsmart's platform to work with your shippers - allowing you to
          engage with RFPs and spot quotes that your shipper invites you to bid on.
        </p>
        <p>Let's start working together and move more with less.</p>
      </StyledBody>
      <Dialog.Actions>
        <Dialog.ActionConfirm onConfirm={closeDialog}>Let's get started</Dialog.ActionConfirm>
      </Dialog.Actions>
    </Dialog>
  )
}

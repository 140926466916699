import { sentry } from 'legacy/utils/sentry'
import { user } from 'legacy/utils/user'

import { retry } from '../../utils/function'
import { load } from '../script'

export const enum SalesforceChatDepartment {
  Carrier = 'carrier',
  HelpDesk = 'help-desk',
}

export const enum SalesforceChatStatus {
  PENDING = 'pending',
  READY = 'ready',
  ERROR = 'error',
  DISABLED = 'disabled',
}

export type SalesforceChatEventMap = {
  chatEnabled: (isChatEnabled: boolean) => void
}

export type SalesforceChatEvent = keyof SalesforceChatEventMap

type EventListeners = {
  [K in SalesforceChatEvent]: Array<SalesforceChatEventMap[K]>
}

type Settings = SalesforceChat.Settings
type SettingsKey = keyof Settings

type UserInformation = {
  firstName?: string
  lastName?: string
  email?: string
  companyId?: string
  accountId?: string
  type?: string
}

type Department = {
  identifier: string
  buttonId: string
  eswLiveAgentDevName: string
}

const SCRIPT_URL = process.env.REACT_APP_SALESFORCE_CHAT_SCRIPT_URL
const BASE_CORE_URL = process.env.REACT_APP_SALESFORCE_CHAT_BASE_CORE_URL
const BASE_LIVE_AGENT_URL = process.env.REACT_APP_SALESFORCE_CHAT_BASE_LIVE_AGENT_URL
const BASE_LIVE_AGENT_CONTENT_URL =
  process.env.REACT_APP_SALESFORCE_CHAT_BASE_LIVE_AGENT_CONTENT_URL
const COMMUNITY_ENDPOINT_URL = process.env.REACT_APP_SALESFORCE_CHAT_COMMUNITY_ENDPOINT_URL
const DEPLOYMENT_ID = process.env.REACT_APP_SALESFORCE_CHAT_DEPLOYMENT_ID
const ORG_ID = process.env.REACT_APP_SALESFORCE_CHAT_ORG_ID

const DEPARTMENT: {
  [key in SalesforceChatDepartment]: Department
} = {
  [SalesforceChatDepartment.Carrier]: {
    identifier: process.env.REACT_APP_SALESFORCE_DEPARTMENT_CONFIG_IDENTIFIER_CARRIER,
    buttonId: process.env.REACT_APP_SALESFORCE_DEPARTMENT_CONFIG_BUTTON_ID_CARRIER,
    eswLiveAgentDevName:
      process.env.REACT_APP_SALESFORCE_DEPARTMENT_CONFIG_ESW_LIVE_AGENT_DEV_NAME_CARRIER,
  },
  [SalesforceChatDepartment.HelpDesk]: {
    identifier: process.env.REACT_APP_SALESFORCE_DEPARTMENT_CONFIG_IDENTIFIER_HELP_DESK,
    buttonId: process.env.REACT_APP_SALESFORCE_DEPARTMENT_CONFIG_BUTTON_ID_HELP_DESK,
    eswLiveAgentDevName:
      process.env.REACT_APP_SALESFORCE_DEPARTMENT_CONFIG_ESW_LIVE_AGENT_DEV_NAME_HELP_DESK,
  },
}

export const HELP_BUTTON_CLASS = 'embeddedServiceHelpButton'
export const SIDEBAR_BUTTON_CLASS = 'embeddedServiceSidebarButton'

export class SalesforceChatClient {
  private static instance: SalesforceChatClient

  private _status: SalesforceChatStatus
  private promise: Promise<void>
  private userInformation: UserInformation
  private department: Department
  private isChatReady: boolean
  private isChatOpen: boolean
  private messageQueue: Array<string>
  private eventListeners: EventListeners

  private constructor() {
    const isActive = process.env.REACT_APP_SALESFORCE_IS_CHAT_ACTIVE === 'true'

    this._status = isActive ? SalesforceChatStatus.PENDING : SalesforceChatStatus.DISABLED
    this.promise = Promise.resolve()
    this.userInformation = {}
    this.department = DEPARTMENT[SalesforceChatDepartment.HelpDesk]
    this.isChatReady = false
    this.isChatOpen = false
    this.messageQueue = []
    this.eventListeners = {
      chatEnabled: [],
    }

    if (isActive) {
      this.setup()
    }
  }

  get status(): SalesforceChatStatus {
    return this._status
  }

  private set status(status: SalesforceChatStatus) {
    this._status = status
  }

  static init(): typeof SalesforceChatClient {
    if (SalesforceChatClient.instance == null) {
      SalesforceChatClient.instance = new SalesforceChatClient()
    }

    return SalesforceChatClient
  }

  static getInstance(): SalesforceChatClient {
    if (SalesforceChatClient.instance == null) {
      throw new Error('Tried to get SalesforceChatClient instance without initialize it first')
    }

    return SalesforceChatClient.instance
  }

  private setup(): void {
    this.promise = this.promise
      .then(() => load(SCRIPT_URL))
      .then(() => {
        if (window.embedded_svc == null) {
          throw new Error('"window.embedded_svc" was not correctly initialized')
        }
      })
      .then(() => this.updateUserInformation())
      .then(() => this.updateDepartmentConfiguration())
      .then(() => this.updateSettings())
      .then(() => this.attachEvents())
      .then(() => this.initService())
      .then(() => {
        this.status = SalesforceChatStatus.READY
      })
      .catch(error => {
        this.status = SalesforceChatStatus.ERROR

        sentry.log(new Error('Failed to initialize the Salesforce Chat', { cause: error }), {
          level: 'warning',
        })
      })
      .then(() => this.dispatch('chatEnabled', this.isChatEnabled()))
  }

  private updateUserInformation(): void {
    if (user.isLoggedIn()) {
      this.userInformation.firstName = user.get('firstName') ?? undefined
      this.userInformation.lastName = user.get('lastName') ?? undefined
      this.userInformation.email = user.get('email') ?? undefined
      this.userInformation.companyId = user.get('companyId') ?? undefined
      this.userInformation.accountId = user.get('accountId') ?? undefined
      this.userInformation.type = user.get('type') ?? undefined
    } else {
      this.userInformation = {}
    }
  }

  private updateDepartmentConfiguration(): void {
    if (this.userInformation.type === 'carrier') {
      this.department = DEPARTMENT[SalesforceChatDepartment.Carrier]
    } else {
      this.department = DEPARTMENT[SalesforceChatDepartment.HelpDesk]
    }
  }

  private updateSettings(): void {
    this.setSetting('baseLiveAgentContentURL', BASE_LIVE_AGENT_CONTENT_URL)
    this.setSetting('baseLiveAgentURL', BASE_LIVE_AGENT_URL)
    this.setSetting('deploymentId', DEPLOYMENT_ID)
    this.setSetting('language', 'en')
    this.setSetting('displayHelpButton', true)
    this.setSetting('isOfflineSupportEnabled', false)
    this.setSetting('entryFeature', 'LiveAgent')
    this.setSetting('enabledFeatures', ['LiveAgent'])
    this.setSetting('defaultMinimizedText', "We're online")
    this.setSetting('disabledMinimizedText', '(646) 887 6278')
    this.setSetting('storageDomain', window.location.hostname)
    this.setSetting('buttonId', this.department.buttonId)
    this.setSetting('eswLiveAgentDevName', this.department.eswLiveAgentDevName)

    this.setSetting('directToButtonRouting', prechatFormDetails => {
      return prechatFormDetails.find(({ label }) => label === 'buttonId')?.value
    })

    this.setSetting('prepopulatedPrechatFields', {
      FirstName: this.userInformation.firstName,
      LastName: this.userInformation.lastName,
      Email: this.userInformation.email,
    })

    this.setSetting('extraPrechatFormDetails', [
      { label: 'Case Origin', value: 'Chat' },
      { label: 'buttonId', value: this.department.buttonId },
      { label: 'Account ID', value: this.userInformation.companyId },
      { label: 'Contact ID', value: this.userInformation.accountId },
      { label: 'First Name', transcriptFields: ['First_Name__c'] },
      { label: 'Last Name', transcriptFields: ['Last_Name__c'] },
      { label: 'Email', transcriptFields: ['Email__c'] },
    ])

    this.setSetting('extraPrechatInfo', [
      {
        entityName: 'Account',
        saveToTranscript: 'AccountId',
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: true,
            fieldName: 'uuid__c',
            isExactMatch: true,
            label: 'Account ID',
          },
        ],
      },
      {
        entityName: 'Contact',
        saveToTranscript: 'ContactId',
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: true,
            fieldName: 'uuid__c',
            isExactMatch: true,
            label: 'Contact ID',
          },
          {
            doCreate: false,
            doFind: true,
            fieldName: 'email',
            isExactMatch: false,
            label: 'Email',
          },
          {
            doCreate: false,
            doFind: true,
            fieldName: 'email2__c',
            isExactMatch: false,
            label: 'Email',
          },
        ],
      },
      {
        entityName: 'Case',
        saveToTranscript: 'CaseId',
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: true,
            fieldName: 'loadsmart_ref_number__c',
            isExactMatch: true,
            label: 'Loadsmart Reference Number',
          },
          {
            doCreate: true,
            fieldName: 'Origin',
            label: 'Case Origin',
          },
        ],
      },
    ])
  }

  private attachEvents(): void {
    window.embedded_svc?.addEventHandler('onChatConferenceEnded', () => this.cleanup())
    window.embedded_svc?.addEventHandler('onChatEndedByChasitor', () => this.cleanup())
    window.embedded_svc?.addEventHandler('onChatEndedByAgent', () => this.cleanup())
    window.embedded_svc?.addEventHandler('onIdleTimeoutOccurred', () => this.cleanup())
    window.embedded_svc?.addEventHandler('onConnectionError', () => this.cleanup())
    window.embedded_svc?.addEventHandler('onChatEstablished', () => {
      this.isChatReady = true
      this.sendQueueMessages()
    })
    window.embedded_svc?.addEventHandler('afterDestroy', () => {
      this.cleanup()
      window.embedded_svc?.showHelpButton()
      this.dispatch('chatEnabled', this.isChatEnabled())
    })
  }

  private initService(): void {
    window.embedded_svc?.init(
      BASE_CORE_URL,
      COMMUNITY_ENDPOINT_URL,
      undefined,
      ORG_ID,
      this.department.identifier
    )
  }

  private getSetting<T extends SettingsKey>(key: T): Settings[T] | undefined {
    return window.embedded_svc?.settings?.[key]
  }

  private setSetting<T extends SettingsKey>(key: T, value: Settings[T]): void {
    if (window.embedded_svc != null) {
      window.embedded_svc.settings = window.embedded_svc.settings ?? {}
      window.embedded_svc.settings[key] = value
    }
  }

  private updateRefNumber(refNumber?: string): void {
    this.setSetting('prepopulatedPrechatFields', {
      ...this.getSetting('prepopulatedPrechatFields'),
      loadsmart_ref_number__c: refNumber,
    })
  }

  private cleanup(): void {
    this.isChatReady = false
    this.isChatOpen = false
    this.updateRefNumber()
  }

  private postMessage(message: string): void {
    window.embedded_svc?.postMessage('chasitor.sendMessage', message)
  }

  private sendQueueMessages(): void {
    for (const message of this.messageQueue) {
      this.postMessage(message)
    }

    this.messageQueue = []
  }

  private isReady(): boolean {
    return this.status === SalesforceChatStatus.READY
  }

  private enqueueAction(action: string, fn: () => void): void {
    this.promise = this.promise
      .then(() => {
        if (this.isReady()) {
          return fn()
        }
      })
      .catch(cause => {
        sentry.log(
          new Error(`Failed to perform the "${action}" action on Salesforce Chat`, { cause }),
          {
            level: 'warning',
          }
        )
      })
  }

  isChatEnabled(): boolean {
    const button = document.querySelector<HTMLButtonElement>(`.${HELP_BUTTON_CLASS}`)

    return (
      this.isReady() &&
      !window.embedded_svc?.isButtonDisabled &&
      (this.isChatOpen || (!this.isChatOpen && button != null && button.style.display !== 'none'))
    )
  }

  addEventListener<T extends SalesforceChatEvent>(
    type: T,
    listener: SalesforceChatEventMap[T]
  ): void {
    this.eventListeners[type].push(listener)
  }

  removeEventListener<T extends SalesforceChatEvent>(
    type: T,
    listener: SalesforceChatEventMap[T]
  ): void {
    this.eventListeners[type] = this.eventListeners[type].filter(item => item !== listener)
  }

  dispatch<K extends SalesforceChatEvent>(
    type: K,
    ...args: Parameters<SalesforceChatEventMap[K]>
  ): void {
    for (const listener of this.eventListeners[type]) {
      // @ts-expect-error TS get the type correctly when calling the method, but not when using the args
      listener(...args)
    }
  }

  openChat(): void {
    this.enqueueAction('openChat', () => {
      if (window.embedded_svc?.isButtonDisabled || this.isChatOpen) {
        return
      }

      window.embedded_svc?.onHelpButtonClick()

      const startChat = retry(
        () => {
          document.querySelector<HTMLButtonElement>(`.${SIDEBAR_BUTTON_CLASS}`)?.click()
          this.isChatOpen = true
        },
        () =>
          window.embedded_svc?.isIframeReady &&
          !window.embedded_svc?.componentInitInProgress &&
          document.querySelector(`.${SIDEBAR_BUTTON_CLASS}`) != null,
        60,
        500
      )

      return startChat()
    })
  }

  showChatButton(): void {
    this.enqueueAction('showChatButton', () => {
      this.cleanup()
      window.embedded_svc?.showHelpButton()
      this.dispatch('chatEnabled', this.isChatEnabled())
    })
  }

  hideChatButton(): void {
    this.enqueueAction('hideChatButton', () => {
      window.embedded_svc?.hideHelpButton()
      this.dispatch('chatEnabled', this.isChatEnabled())
    })
  }

  setRefNumber(refNumber?: string): void {
    this.enqueueAction('setRefNumber', () => {
      this.updateRefNumber(refNumber)
    })
  }

  sendMessage(message: string): void {
    this.enqueueAction('sendMessage', () => {
      if (this.isChatReady && this.messageQueue.length === 0) {
        this.postMessage(message)
      } else {
        this.messageQueue.push(message)
      }
    })
  }
}

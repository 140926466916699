import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { ENVIRONMENT, __DEV__ } from 'core/infra/environment'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const MAX_OBJECT_DEPTH = 5

const ALLOW_URLS = {
  // local: ['localhost:8080'],
  qa: ['carrier.qa.loadsmart.com'],
  staging: ['carrier.staging.loadsmart.com'],
  sandbox: ['carrier.sandbox.loadsmart.com'],
  production: ['carrier.loadsmart.com'],
}

export const sentry = {
  config(options = {}) {
    const { allowUrls = ALLOW_URLS[ENVIRONMENT] } = options

    if (!allowUrls) {
      console.debug(`skipping sentry: ${ENVIRONMENT} environment not allowed`)
      return
    }

    try {
      Sentry.init({
        allowUrls,
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT,
        integrations: [
          new BrowserTracing(),
          new ExtraErrorDataIntegration({ depth: MAX_OBJECT_DEPTH }),
        ],
        release: process.env.REACT_APP_RELEASE_VERSION,
      })
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Captures an exception event and sends it to Sentry.
   *
   * @param {any} exception An exception-like object.
   * @param {import('@sentry/types').CaptureContext} [captureContext] Additional scope data to apply to exception event.
   * @returns {string} The generated eventId.
   */
  log(exception, captureContext) {
    if (__DEV__) {
      console.error(exception)
    }

    return Sentry?.captureException(exception, captureContext)
  },
}

export default sentry

import {
  IconArrowLeft,
  IconArrowRight,
  IconArrowsCornerDown,
  IconArrowsCornerUp,
  IconArrowsExpand,
  IconArrowsExpandSide,
  IconArrowsReverse,
  IconArrowsUpDown,
  IconAttention,
  IconBell,
  IconBoard,
  IconBurgerMenu,
  IconChat,
  IconCheck,
  IconCheckCircle,
  IconClip,
  IconClock,
  IconClose,
  IconContractDeal,
  IconDeal,
  IconDetention,
  IconDolar,
  IconDotsVertical,
  IconDownload,
  IconDryvan,
  IconEmailFilled,
  IconEye,
  IconFast,
  IconFastPayment,
  IconGraph,
  IconHassleFree,
  IconHot,
  IconHourglass,
  IconInstantPricing,
  IconInvoice,
  IconMarkersFavorite,
  IconMinus,
  IconPencil,
  IconPhone,
  IconPlus,
  IconQuestionCircle,
  IconStar,
  IconSupport,
  IconTailoredLoads,
  IconTenderAcceptance,
  IconTrash,
  IconTruckBobtail,
  IconTruckConestoga,
  IconTruckContainer,
  IconTruckCourtainSide,
  IconTruckDoubleDrop,
  IconTruckDrayage,
  IconTruckDryvan,
  IconTruckFlatbed,
  IconTruckFlatrack,
  IconTruckLtlDryvan,
  IconTruckPtlDryvan,
  IconTruckVltl,
  IconTruckRailDrybox,
  IconTruckReefer,
  IconTruckRollOnRollOff,
  IconTruckSprinter,
  IconTruckStepdeck,
  IconTruckStraight,
  IconTruckVentilated,
  IconUpload,
  IconLocationGraph,
  IconMarkersPin,
  IconTruckHotshot,
  IconCopy,
  IconSearch,
  IconLike,
} from '@loadsmart/icons'
import { IconFactory, type IconProps as BaseIconProps } from '@loadsmart/loadsmart-ui'

const Icons = {
  'arrow-left': IconArrowLeft,
  'arrow-right': IconArrowRight,
  'arrows-corner-down': IconArrowsCornerDown,
  'arrows-corner-up': IconArrowsCornerUp,
  'arrows-expand-side': IconArrowsExpandSide,
  'arrows-expanded': IconArrowsExpand,
  'arrows-reverse': IconArrowsReverse,
  'arrows-up-down': IconArrowsUpDown,
  attention: IconAttention,
  bell: IconBell,
  board: IconBoard,
  'burger-menu': IconBurgerMenu,
  chat: IconChat,
  check: IconCheck,
  'check-circle': IconCheckCircle,
  clip: IconClip,
  clock: IconClock,
  close: IconClose,
  conestoga: IconTruckConestoga,
  copy: IconCopy,
  'contract-deal': IconContractDeal,
  deal: IconDeal,
  detention: IconDetention,
  dolar: IconDolar,
  dots: IconDotsVertical,
  download: IconDownload,
  dryvan: IconDryvan,
  'email-filled': IconEmailFilled,
  eye: IconEye,
  fast: IconFast,
  'fast-payment': IconFastPayment,
  graph: IconGraph,
  'hassle-free': IconHassleFree,
  hot: IconHot,
  'hour-glass': IconHourglass,
  'instant-pricing': IconInstantPricing,
  invoice: IconInvoice,
  'markers-favorite': IconMarkersFavorite,
  minus: IconMinus,
  pencil: IconPencil,
  phone: IconPhone,
  pin: IconMarkersPin,
  pins: IconLocationGraph,
  plus: IconPlus,
  'question-circle': IconQuestionCircle,
  search: IconSearch,
  star: IconStar,
  support: IconSupport,
  'tailored-loads': IconTailoredLoads,
  'tender-acceptance': IconTenderAcceptance,
  trash: IconTrash,
  like: IconLike,
  'truck-bobtail': IconTruckBobtail,
  'truck-conestoga': IconTruckConestoga,
  'truck-container': IconTruckContainer,
  'truck-courtainside': IconTruckCourtainSide,
  'truck-double-drop': IconTruckDoubleDrop,
  'truck-drayage': IconTruckDrayage,
  'truck-dryvan': IconTruckDryvan,
  'truck-flatbed': IconTruckFlatbed,
  'truck-flatrack': IconTruckFlatrack,
  'truck-hotshot': IconTruckHotshot,
  'truck-ltl-dryvan': IconTruckLtlDryvan,
  'truck-ptl-dryvan': IconTruckPtlDryvan,
  'truck-vltl': IconTruckVltl,
  'truck-rail-drybox': IconTruckRailDrybox,
  'truck-reefer': IconTruckReefer,
  'truck-rollon-rolloff': IconTruckRollOnRollOff,
  'truck-sprinter': IconTruckSprinter,
  'truck-stepdeck': IconTruckStepdeck,
  'truck-straight': IconTruckStraight,
  'truck-ventilated': IconTruckVentilated,
  upload: IconUpload,
}

export type IconMap = typeof Icons
export type IconName = keyof IconMap
export type IconProps = BaseIconProps<IconMap> & { title?: string }
export type IconComponent = (props: IconProps) => JSX.Element

const InternalIcon: IconComponent = IconFactory(Icons)

export function Icon({ title = '', ...props }: IconProps) {
  // Defaulting the title to nothing, because displaying the icon filename as title is not that helpful
  return <InternalIcon title={title} {...props} />
}

import { type SeverityLevel } from '@sentry/react'

export function isChunkLoadError(error: Error) {
  return error?.name.includes('ChunkLoadError')
}

export function isNetworkError(error: Error) {
  return error?.message.includes('Network Error')
}

export function getErrorLevel(error: Error) {
  let level: SeverityLevel = 'error'

  if (isChunkLoadError(error) || isNetworkError(error)) level = 'debug'

  return level
}

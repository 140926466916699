import axios from 'axios'

import http from 'legacy/utils/http'
import requests, { getHeadersDocs } from 'legacy/utils/requests'

import { getClientToken } from './auth'

export const getAccountV2 = () => axios.get('/api/v2/account', http.getHeadersV2())

export const resetAccountPassword = async data => {
  const requestConfig = http.getPublicHeadersV2()
  requestConfig.headers.Authorization = await getClientToken()
  return axios.post(`/api/v2/account/password/reset`, JSON.stringify(data), requestConfig)
}

export const resendEmail = data =>
  axios.post(
    `/api/v2/account/send_activation_email`,
    JSON.stringify(data),
    http.getPublicHeadersV2()
  )

export const updateAccount = data => requests.put('/api/v2/account/', JSON.stringify(data))

export const updateAccountCarrier = data => requests.patch(`/api/v2/carrier`, data)

export const uploadAvatar = data => getHeadersDocs().put('/api/v2/account/avatar', data)

export const setNewPasswordAccount = data =>
  axios.post(
    `/api/v2/account/password/reset/confirm`,
    JSON.stringify(data),
    http.getPublicHeadersV2()
  )

export const verifyAccount = data =>
  axios.post('/api/v2/account/verify', JSON.stringify(data), http.getPublicHeadersV2())

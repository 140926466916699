import { type EntityType } from 'core/domain/EntityType'

import type { ApprovalStatus } from '../domain/Account'
import { type CarrierAccount } from '../domain/Carrier'
import { type SignUp } from '../domain/SignUp'

export type RawAccount = {
  approval_status?: ApprovalStatus
  first_name: string
  last_name: string
  email: string
}

export type RawCarrier = {
  account: RawAccount & {
    id: string
  }
  dot_number: string
  mc_number: string
  id: string
  name: string
}

export type RawSignUp = {
  account: RawAccount & {
    password: string
  }
  dot_number: string
  mc_number: string
  entity_type?: EntityType
  drayage?: {
    scac_code: string
    has_power_refeer: boolean
    has_valid_emodal: boolean
    has_valid_uiia: boolean
  }
}

export function mapSignUpToApi(signUp: SignUp): RawSignUp {
  const rawSignUp: RawSignUp = {
    account: {
      first_name: signUp.firstName,
      last_name: signUp.lastName,
      email: signUp.email.toLowerCase(),
      password: signUp.password,
    },
    dot_number: signUp.dotNumber,
    mc_number: signUp.mcNumber,
    entity_type: signUp.entityType,
  }

  if (signUp.equipmentType === 'drayage') {
    rawSignUp.drayage = {
      scac_code: signUp.scac!.toUpperCase(),
      has_power_refeer: false,
      has_valid_emodal: Boolean(signUp.emodal),
      has_valid_uiia: Boolean(signUp.uiia),
    }
  }

  return rawSignUp
}

export function mapApiToCarrier(rawCarrier: RawCarrier): CarrierAccount {
  return {
    account: {
      id: rawCarrier.account.id,
      email: rawCarrier.account.email,
      firstName: rawCarrier.account.first_name,
      lastName: rawCarrier.account.last_name,
      approvalStatus: rawCarrier.account.approval_status || undefined,
    },
    mcNumber: rawCarrier.mc_number,
    dotNumber: rawCarrier.dot_number,
    id: rawCarrier.id,
    name: rawCarrier.name,
  }
}

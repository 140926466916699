import { type UserAccount } from 'user/domain/UserAccount'

export enum OnboardingStep {
  RegionsOfOperation = 'regions_of_operation',
  EquipmentTypes = 'equipment_types',
  PreferredLanes = 'preferred_lanes',
}

export const REQUIRED_ONBOARDING_STEPS = [
  OnboardingStep.RegionsOfOperation,
  OnboardingStep.PreferredLanes,
]

export function isOnboardingRequired(userAccount?: UserAccount): boolean {
  if (!userAccount) return false

  return Boolean(
    userAccount.showOnboarding &&
      userAccount.onboardingSteps.length &&
      userAccount.onboardingSteps.some(step => REQUIRED_ONBOARDING_STEPS.includes(step))
  )
}

export function showStep(
  pendingSteps: Array<OnboardingStep>,
  currentStep: OnboardingStep,
  stepName: OnboardingStep
): boolean {
  return Boolean(pendingSteps?.includes(stepName) && currentStep === stepName)
}

export function getAdjacentSteps(
  pendingSteps: Array<OnboardingStep>,
  stepName?: OnboardingStep
): Array<OnboardingStep | undefined> {
  if (!stepName) return []

  const index = pendingSteps.indexOf(stepName)
  const prev = pendingSteps.slice(index - 1, index)[0]
  const next = pendingSteps.slice(index + 1)[0]

  return [prev, next]
}

export function isLastStep(
  pendingSteps: Array<OnboardingStep>,
  stepName?: OnboardingStep
): boolean {
  return Boolean(pendingSteps[pendingSteps.length - 1] === stepName)
}

import { datadogRum } from '@datadog/browser-rum'

import { analytics } from './analytics'

function initRUM() {
  const env = process.env.NODE_ENV
  const isE2E = analytics.isE2EAccount()

  if (
    env === 'production' &&
    !isE2E &&
    process.env.REACT_APP_CARRIER_DATADOG_RUM_ID &&
    process.env.REACT_APP_CARRIER_DATADOG_RUM_TOKEN &&
    process.env.REACT_APP_RELEASE_VERSION
  ) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_CARRIER_DATADOG_RUM_ID,
      clientToken: process.env.REACT_APP_CARRIER_DATADOG_RUM_TOKEN,
      site: 'datadoghq.com',
      service: 'carrier-web',
      version: process.env.REACT_APP_RELEASE_VERSION,
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: true,
      env,
    })
  }
}

export { initRUM }

import { useState } from 'react'

import { RequestStatus } from 'core/domain/Request'

import { reSendEmail as reSendEmailUseCase } from '../../use-cases/reSendEmail'

export function useReSendEmail() {
  const [status, setStatus] = useState(RequestStatus.Initial)

  async function reSendEmail(email: string, { signal }: AbortController) {
    setStatus(RequestStatus.Pending)

    try {
      if (!signal.aborted) {
        await reSendEmailUseCase(email, { signal })
        setStatus(RequestStatus.Done)
      }
    } catch (error) {
      setStatus(RequestStatus.Error)
      throw error
    }
  }

  const isLoading = status === RequestStatus.Pending
  const hasError = status === RequestStatus.Error

  return {
    status,
    isLoading,
    hasError,
    reSendEmail,
  }
}

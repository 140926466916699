import React from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'

import { Navigation } from 'legacy/components/navigation/Navigation'
import { NavigationProvider } from 'legacy/contexts/NavigationContext'

import { AuthDialogs } from 'auth/ui/AuthDialogs'
import { useCarrierContext } from 'auth/ui/context/CarrierContext'
import { isOnboardingRequired } from 'onboarding/domain/Onboarding'
import { OnboardingDialog } from 'onboarding/ui/OnboardingDialog'
import { useUserAccountContext } from 'user/ui/UserAccountContext'

import { AnnouncementBanner } from '../../announcements/AnnouncementBanner'
import { componentLoader } from '../../utils/component'
import { PrivateRoute } from '../components/private-route/PrivateRoute'
import { WelcomeBrokerModal } from '../components/welcome-modal/WelcomeBrokerModal'
import { useAuthContext } from '../contexts/authContext'
import { Fallback } from './Fallback'
import { PageNotFound } from './PageNotFound'

const AccountVerifyPage = React.lazy(
  componentLoader(() => import('legacy/containers/AccountVerifyPage/AccountVerifyPage'))
)
const AuthPage = React.lazy(componentLoader(() => import('legacy/containers/AuthPage/AuthPage')))
const LoginPage = React.lazy(componentLoader(() => import('auth/ui/LoginPage')))
const LoadsPage = React.lazy(componentLoader(() => import('legacy/containers/LoadsPage/LoadsPage')))
const ResetPasswordPage = React.lazy(componentLoader(() => import('auth/ui/ResetPasswordPage')))
const SetNewPasswordPage = React.lazy(componentLoader(() => import('auth/ui/SetNewPasswordPage')))
const SignupPage = React.lazy(componentLoader(() => import('auth/ui/SignUpPage')))
const AccountCarrierPage = React.lazy(
  componentLoader(() => import('legacy/containers/AccountCarrierPage/AccountCarrierPage'))
)
const ContractsPageApp = React.lazy(
  componentLoader(() => import('legacy/containers/ContractsApp/ContractsApp'))
)
const DriverPage = React.lazy(
  componentLoader(() => import('legacy/containers/DriverPage/DriverPage'))
)
const FleetPage = React.lazy(componentLoader(() => import('legacy/containers/FleetPage/FleetPage')))
const HistoryPage = React.lazy(
  componentLoader(() => import('legacy/containers/HistoryPage/HistoryPage'))
)
const PreferredLanesRouter = React.lazy(
  componentLoader(() => import('preferred-lanes/ui/PreferredLanesRouter'))
)
const RateConfirmation = React.lazy(
  componentLoader(() => import('legacy/containers/RateConPage/RateConfirmation'))
)

const RfpPage = React.lazy(componentLoader(() => import('rfp/ui/RfpPage')))
const SpotQuotePage = React.lazy(componentLoader(() => import('spot-quote/ui/SpotQuotePage')))
const ShipmentsPageApp = React.lazy(
  componentLoader(() => import('legacy/containers/ShipmentsPage/ShipmentsPageApp'))
)
const TruckPage = React.lazy(componentLoader(() => import('legacy/containers/TruckPage/TruckPage')))
const PaymentPage = React.lazy(componentLoader(() => import('payments/ui/PaymentPage')))
const ShipmentPage = React.lazy(componentLoader(() => import('shipments/ui/ShipmentPage')))
const TenderRouter = React.lazy(componentLoader(() => import('tender/ui/TenderRouter')))

const FacilityPage = React.lazy(() => import('facilities/ui/FacilityPage'))

export function Router() {
  const [userToken] = useAuthContext()
  const { carrier } = useCarrierContext()
  const account = useUserAccountContext()
  const isLogged = Boolean(userToken)
  const isCarrier = carrier?.entityType === 'carrier'
  const isBroker = carrier?.entityType === 'broker'
  const showOnboarding = isLogged && isCarrier && isOnboardingRequired(account)

  return (
    <HashRouter>
      <NavigationProvider>
        <Navigation logged={isLogged} />
      </NavigationProvider>
      <AnnouncementBanner />
      <React.Suspense fallback={<Fallback />}>
        <Switch>
          <Redirect from="/" to="/loads" exact />
          <Route path="/verify" component={AccountVerifyPage} />
          <Route path="/auth" component={AuthPage} />
          <Route path="/loads" component={LoadsPage} />
          <Route path="/profile/carrier" component={AccountCarrierPage} />
          <PrivateRoute path="/fleet" component={FleetPage} />
          <Route path="/driver" component={DriverPage} />
          <Route path="/truck" component={TruckPage} />
          <Route path="/rate-con/:refNumber" component={RateConfirmation} />
          <PrivateRoute
            path="/history/:id"
            component={HistoryPage}
            entitiesNotAllowed={['broker']}
          />
          <Redirect from="/history" to="/history/0" />
          <Route path="/preferred-lanes" component={PreferredLanesRouter} />
          <Route path="/shipments" component={ShipmentsPageApp} />
          <Route path="/contracts" component={ContractsPageApp} />
          <Route path="/payments" component={PaymentPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route path="/set-new-password" component={SetNewPasswordPage} />
          <Route path="/facilities/:uuid/review/:reviewUuid" component={FacilityPage} />
          <Route path="/facilities/:uuid" component={FacilityPage} />
          <Route path="/rfp" component={RfpPage} />
          <Route path="/spot-quote" component={SpotQuotePage} />
          <Route path="/shipment" component={ShipmentPage} />
          <Route path="/tender" component={TenderRouter} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </React.Suspense>
      <AuthDialogs />
      {isBroker && <WelcomeBrokerModal />}
      {showOnboarding && (
        <OnboardingDialog
          show={showOnboarding}
          firstName={account?.firstName}
          lastName={account?.lastName}
          onboardingSteps={account?.onboardingSteps}
        />
      )}
    </HashRouter>
  )
}

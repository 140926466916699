import styled from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { screen } from 'legacy/styles/screen'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;

  color: ${getToken('color-neutral-darker')};
  font-size: 13px;

  border: 1px solid ${getToken('color-neutral-light')};
  border-radius: ${getToken('border-radius-s')};

  transition: border-color 0.1s linear;

  :hover {
    border-color: ${getToken('color-neutral-light')};
  }
`

export const Wrapper = styled.div`
  flex-grow: 1;
  padding-right: 16px;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${screen.md} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin: ${getToken('space-m')} 0;

  background-color: ${getToken('color-neutral-lighter')};
  border: 0;
`

export const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getToken('space-s')};

  ${screen.md} {
    flex-direction: row;
    align-items: center;
  }
`

export const TargetRate = styled.span`
  font-weight: bold;
`

export const TargetRateContainer = styled.div`
  display: flex;
  gap: ${getToken('space-s')};
  align-items: center;
  margin-bottom: ${getToken('space-s')};

  ${screen.md} {
    margin-bottom: unset;
  }
`

export const DateRange = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;

  font-weight: bold;

  > svg {
    margin-right: 5px;
  }
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;

  > :not(:last-child) {
    margin-right: 0px;
    margin-bottom: 6px;
  }

  ${screen.md} {
    flex-flow: row;
    align-items: center;

    > :not(:last-child) {
      margin-right: 30px;
      margin-bottom: 0px;
    }
  }
`

export const EquipmentTypesList = styled.span`
  display: inline-flex;
  flex-flow: row;
`

export const Lane = styled.span`
  font-weight: bold;
  font-size: 16px;

  svg {
    margin: 0 6px;
  }
`

export const OptionsContainer = styled.div`
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  > :not(:last-child) {
    margin-right: 10px;
  }

  ${screen.md} {
    justify-content: flex-end;
    > :not(:last-child) {
      margin-right: 30px;
    }
  }
`

export const Radius = styled.span`
  margin-left: 10px;

  font-weight: 400;
  font-size: 13px;
  font-style: italic;
  white-space: nowrap;
`

export const Weekdays = styled.span`
  display: inline-flex;

  text-transform: capitalize;
`

import { createContext, useContext, useMemo } from 'react'

import { toArray } from '../utils/array'
import { merge } from '../utils/object'

export type AnalyticsContextValue = {
  context: Record<string, unknown>
  prefix: Array<string>
}

export const AnalyticsContext = createContext<AnalyticsContextValue | undefined>(undefined)
AnalyticsContext.displayName = 'AnalyticsContext'

type AnalyticsProviderProps = {
  children?: React.ReactNode
  context?: Record<string, unknown>
  prefix?: string | Array<string>
}

export function AnalyticsProvider({ children, context, prefix }: AnalyticsProviderProps) {
  const parentValue = useContext(AnalyticsContext)

  const childValue = useMemo(
    () => ({
      context: merge({}, parentValue?.context, context),
      prefix: toArray(parentValue?.prefix).concat(toArray(prefix)),
    }),
    [parentValue, context, prefix]
  )

  return <AnalyticsContext.Provider value={childValue}>{children}</AnalyticsContext.Provider>
}

import PropTypes from 'prop-types'
import { useState, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

import { Button, TextField, ToggleGroup } from '@loadsmart/loadsmart-ui'

import { Icon } from 'core/ui/components/icon/Icon'

import { LoadingV2 } from 'legacy/components/LoadingV2/LoadingV2'
import { addPreferredLane } from 'legacy/services/preferredLanes'

import { formatRate } from '../formatRate'
import { preferredLaneType } from '../propTypes'
import { SuggestedRateBanner } from '../SuggestedRateBanner'
import { useSuggestedRate } from '../useSuggestedRate'
import {
  Container,
  FormGroup,
  FormInputWrapper,
  FormLabel,
  FormLine,
} from './AddPreferredLane.styles'
import { DEFAULT_RADIUS_VALUE, SelectLane } from './SelectLane'
import { SelectPeriod } from './SelectPeriod'

const WEEKDAY_CHOICES = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

const weekdayChoices = WEEKDAY_CHOICES.map(someWeekDay => ({
  label: someWeekDay,
  value: someWeekDay,
}))

const equipmentTypeChoices = [
  {
    label: 'DRV',
    value: 'DRV',
    leading: <Icon name="truck-dryvan" />,
  },
  {
    label: 'RFR',
    value: 'RFR',
    leading: <Icon name="truck-reefer" />,
  },
  {
    label: 'FBE',
    value: 'FBE',
    leading: <Icon name="truck-flatbed" />,
  },
]

const defaultEquipmentType = 'DRV'

export function AddPreferredLane({
  onNewLaneAdded,
  defaultLane,
  isSuggestion,
  trackSuccess,
  trackError,
  style,
  enableNotifyPeriod,
}) {
  const { suggestedRate, getSuggestedRate, clearSuggestedRate, isLoadingSuggestedRate } =
    useSuggestedRate()
  const [dateRange, setDateRange] = useState({
    checked: false,
    startDate: undefined,
    endDate: undefined,
  })
  const [equipmentType, setEquipmentType] = useState(defaultEquipmentType)
  const [lane, setLane] = useState(undefined)
  const [weekdays, setWeekdays] = useState([])
  const [minRate, setMinRate] = useState(0)
  const [touchedMinRate, setTouchedMinRate] = useState(false)

  const formattedRate = formatRate(minRate)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const canSubmit =
    !isSubmitting &&
    Boolean(lane?.pickup || lane?.delivery) &&
    !(dateRange.checked && !(dateRange.startDate || dateRange.endDate))

  const handleDateRangeChange = useCallback((checked, startDate, endDate) => {
    setDateRange({ checked, startDate, endDate })
  }, [])

  const handleEquipmentChange = useCallback(event => {
    setEquipmentType(event.target.value)
  }, [])

  const handleLaneChange = useCallback(lane => {
    setLane(lane)
  }, [])

  const handleWeekdaysChange = useCallback(event => {
    setWeekdays(event.target.value)
  }, [])

  const handleMinumumRate = e => {
    if (!touchedMinRate) setTouchedMinRate(true)
    setMinRate(e.target.value)
  }

  const handleAddPreferredLanes = useCallback(async () => {
    const newPreferredLane = {}

    if (lane?.pickup) {
      newPreferredLane.origin = {
        description: lane.pickup.description,
        latitude: lane.pickup.lat,
        longitude: lane.pickup.lng,
        radius: lane.radius,
      }
    }

    if (lane?.delivery) {
      newPreferredLane.destination = {
        description: lane.delivery.description,
        latitude: lane.delivery.lat,
        longitude: lane.delivery.lng,
        radius: lane.radius,
      }
    }

    if (weekdays.length) {
      newPreferredLane.weekdays = weekdays
    }

    if (equipmentType) {
      newPreferredLane.equipment_types = [equipmentType]
    }

    if (dateRange.checked && (dateRange.startDate || dateRange.endDate)) {
      newPreferredLane.starts_at = dateRange.startDate
      newPreferredLane.expires_at = dateRange.endDate || newPreferredLane.starts_at
    }

    if (minRate) {
      newPreferredLane.rate = minRate
    }

    try {
      setIsSubmitting(true)

      const { data: preferredLane } = await addPreferredLane(newPreferredLane)

      onNewLaneAdded(preferredLane)

      trackSuccess({
        lane,
        rate: minRate,
        suggestedRate,
        isSuggestion,
      })

      toast.success('New preferred lane added successfully')
    } catch (error) {
      trackError()
      if (error.response?.status === 422) {
        toast.error('This preferred lane already exists')
      } else {
        toast.error('Error when adding preferred lane')
      }
    } finally {
      setIsSubmitting(false)
    }
  }, [
    lane,
    weekdays,
    equipmentType,
    dateRange.checked,
    dateRange.startDate,
    dateRange.endDate,
    minRate,
    onNewLaneAdded,
    suggestedRate,
    isSuggestion,
    trackSuccess,
    trackError,
  ])

  useEffect(() => {
    if (!lane?.pickup || !lane?.delivery) return

    getSuggestedRate({
      origin: {
        description: lane.pickup.description,
        latitude: lane.pickup.lat,
        longitude: lane.pickup.lng,
        radius: lane.radius,
      },
      destination: {
        description: lane.delivery.description,
        latitude: lane.delivery.lat,
        longitude: lane.delivery.lng,
        radius: lane.radius,
      },
      equipment_type: equipmentType,
    })
  }, [equipmentType, getSuggestedRate, lane])

  useEffect(() => {
    if (touchedMinRate) return

    setMinRate(suggestedRate)
  }, [minRate, suggestedRate, touchedMinRate])

  useEffect(() => {
    if (!lane?.pickup || !lane?.delivery) clearSuggestedRate()

    if (touchedMinRate) return

    setMinRate(0)
  }, [clearSuggestedRate, lane, touchedMinRate])

  useEffect(() => {
    if (defaultLane) {
      setEquipmentType(defaultLane.equipmentType || defaultEquipmentType)
      setLane({
        pickup: defaultLane.pickup,
        delivery: defaultLane.delivery,
        radius: DEFAULT_RADIUS_VALUE,
      })
    }
  }, [defaultLane])

  return (
    <Container style={style}>
      <FormLine>
        <FormGroup>
          <FormLabel>Lane</FormLabel>
          <FormInputWrapper>
            <SelectLane onChange={handleLaneChange} lane={lane} />
          </FormInputWrapper>
        </FormGroup>

        {enableNotifyPeriod && (
          <FormGroup>
            <FormLabel>Notify for a period</FormLabel>
            <FormInputWrapper>
              <SelectPeriod onChange={handleDateRangeChange} />
            </FormInputWrapper>
          </FormGroup>
        )}
      </FormLine>
      <FormLine>
        <FormGroup>
          <FormLabel>Weekday</FormLabel>
          <FormInputWrapper>
            <ToggleGroup
              id="weekday"
              name="weekday"
              onChange={handleWeekdaysChange}
              options={weekdayChoices}
              scale="default"
              value={weekdays}
              multiple
            />
          </FormInputWrapper>
        </FormGroup>

        <FormGroup>
          <FormLabel>Equipment Type</FormLabel>
          <FormInputWrapper>
            <ToggleGroup
              id="equipment-type"
              name="equipment-type"
              onChange={handleEquipmentChange}
              options={equipmentTypeChoices}
              scale="default"
              value={equipmentType}
            />
          </FormInputWrapper>
        </FormGroup>
      </FormLine>
      <FormLine>
        {suggestedRate !== null && (
          <SuggestedRateBanner rate={suggestedRate} loading={isLoadingSuggestedRate} />
        )}
      </FormLine>
      <FormLine>
        <FormGroup>
          <FormLabel>Minimum Rate</FormLabel>
          <FormInputWrapper>
            <TextField
              type="currency"
              leading={<Icon name="dolar" width="16" height="16" />}
              scale="default"
              value={formattedRate}
              onChange={handleMinumumRate}
            />
          </FormInputWrapper>
        </FormGroup>
        <FormGroup>
          <FormInputWrapper>
            <Button
              variant="primary"
              disabled={!canSubmit}
              onClick={handleAddPreferredLanes}
              data-testid="add-preferred-lane"
            >
              {isSubmitting ? <LoadingV2 width={15} height={15} /> : 'Add preferred lane'}
            </Button>
          </FormInputWrapper>
        </FormGroup>
      </FormLine>
    </Container>
  )
}

AddPreferredLane.propTypes = {
  onNewLaneAdded: PropTypes.func.isRequired,
  defaultLane: preferredLaneType,
  isSuggestion: PropTypes.bool,
  trackSuccess: PropTypes.func.isRequired,
  trackError: PropTypes.func.isRequired,
  style: PropTypes.object,
  enableNotifyPeriod: PropTypes.bool,
}

AddPreferredLane.defaultProps = {
  isSuggestion: false,
  style: {},
  enableNotifyPeriod: true,
}

export function isEmpty(value: string): boolean {
  return value.trim().length === 0
}

export function isNotEmpty(value: string): boolean {
  return value.trim().length > 0
}

export function isString(value?: unknown): value is string {
  return typeof value === 'string'
}

/**
 * Join an array of values into a single `string`.
 *
 * @param values An array of `string`, `undefined` or `null`. Empty `strings`,
 *               `undefined` and `null` values are removed before the join.
 * @param separator Separator used to join the values. Default to `", "`.
 */
export function join(values: Array<string | undefined | null>, separator: string = ', '): string {
  return values.filter(isString).filter(isNotEmpty).join(separator)
}

export function plural(word: string, value: number, pluralForm?: string) {
  const pluralWord = pluralForm ?? `${word}s`

  return value !== 1 ? pluralWord : word
}

/**
 * Sort function to sort strings alphabetically.
 *
 * @param stringA The first string to compare.
 * @param stringB The second string to compare.
 * @returns Returns -1 if the first string comes before the second string in a case-insensitive alphabetical order, 1 if it comes after, and 0 if they are equal in the order.
 */
export function sortAlphabetically(stringA: string, stringB: string) {
  const resA = stringA.toLowerCase()
  const resB = stringB.toLowerCase()

  if (resA < resB) return -1
  else if (resA > resB) return 1
  return 0
}

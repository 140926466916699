import { BROKER_WELCOME_STORAGE_KEY } from '../domain/Carrier'
import { LocalStorageClient } from '../infra/storage/LocalStorageClient'
import { type StorageInterface } from '../infra/storage/StorageInterface'

export type GetBrokerWelcomeStateParams = {
  storage: StorageInterface
}

export function getBrokerWelcomeStateFactory({ storage }: GetBrokerWelcomeStateParams) {
  return function getBrokerWelcomeStateImpl() {
    return Boolean(storage.get<boolean>(BROKER_WELCOME_STORAGE_KEY))
  }
}

export function getBrokerWelcomeState() {
  const storage = new LocalStorageClient()
  const confirmWelcome = getBrokerWelcomeStateFactory({ storage })

  return confirmWelcome()
}

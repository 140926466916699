import { type EntityType } from './EntityType'

export type BaseCarrier = {
  name: string
  entityType?: EntityType
}

export const PUBLIC_CARRIER_COOKIE = 'ls-public-carrier'
export const CARRIER_INFO_STORAGE_KEY = 'ls-carrier-info'
export const BROKER_WELCOME_STORAGE_KEY = 'ls-seen-broker-welcome'

import { useEffect } from 'react'

export function useEventMessage(
  messageHandler: (ev: MessageEvent) => void,
  options: boolean | AddEventListenerOptions | undefined
) {
  useEffect(
    function onMount() {
      window.addEventListener('message', messageHandler, options)

      return function onCleanup() {
        window.removeEventListener('message', messageHandler)
      }
    },
    [messageHandler, options]
  )
}

/**
 * Returns a Promise that will wait for the provided amount of time
 * @param {number} duration In milliseconds, must be equal or greater than zero
 * @returns {Promise<void>}
 */
export function wait(duration: number): Promise<void> {
  if (typeof duration !== 'number' || duration < 0) {
    throw new TypeError('"duration" must be a number equal or greater than zero')
  }

  return new Promise(resolve => {
    setTimeout(resolve, duration)
  })
}

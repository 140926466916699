import formatDate from './formatDate'
import { utcToZonedTime } from './utcToZonedTime'

export function formatZonedDate(datetime, timezone = 'UTC', format = 'EEE, MMM d') {
  if (!datetime) return null

  return formatDate(utcToZonedTime(datetime, timezone), format)
}

export default formatZonedDate

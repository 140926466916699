import Cookies, { type CookieAttributes } from 'js-cookie'

import { envDomain, ENVIRONMENT, EnvType } from './environment'

export type CookieOptions = CookieAttributes

export const DEFAULT_OPTIONS: CookieAttributes = Object.freeze({
  domain: envDomain[ENVIRONMENT] || envDomain[EnvType.Production],
  sameSite: 'strict',
  secure: true,
})

export function getCookie<T = string>(name: string): T | undefined {
  const value = Cookies.get(name)
  return value ? JSON.parse(value) : undefined
}

export function setCookie<T>(name: string, value: T, options?: CookieOptions): void {
  const stringValue = value === undefined ? '' : JSON.stringify(value)
  Cookies.set(name, stringValue, { ...DEFAULT_OPTIONS, ...options })
}

export function removeCookie(name: string, options?: CookieOptions): void {
  Cookies.remove(name, { ...DEFAULT_OPTIONS, ...options })
}

export type CookieClient = typeof cookieClient
export const cookieClient = {
  getCookie,
  setCookie,
  removeCookie,
}

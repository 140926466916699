import { useState } from 'react'

import { analytics } from 'legacy/utils/analytics'
import { user } from 'legacy/utils/user'

import { type ConfigKeys } from 'config/domain/Config'
import { useConfigContext } from 'config/ui/ConfigContext'

import { LocalStorageClient } from '../../../infra/storage/LocalStorageClient'
import { SurveyBanner } from './SurveyBanner'

export type BannerConfig = {
  text: string
  textFeedback: string
  feedbackDelay: number
  buttonLabel: string
  enabled: boolean
  roleRestriction: string[]
  storageKey: string
  target: string
}

export type SurveyBannerControllerProps = {
  flagKey: ConfigKeys
}

export function SurveyBannerController({ flagKey }: SurveyBannerControllerProps) {
  const [showFeedback, setShowFeedback] = useState(false)
  const config: BannerConfig = useConfigContext()[flagKey].toObject()
  const storage = new LocalStorageClient()
  const [hasSeen, setHasSeen] = useState(!!storage.get(config.storageKey))
  const displayText = showFeedback ? `${config.textFeedback} ${user.email()}` : config.text

  function sendToMixpanel(label: string) {
    const obj = {
      firstName: user.firstName(),
      lastName: user.lastName(),
      email: user.email(),
      role: user.role,
      company: user.companyId,
      ...config,
    }

    analytics.track(label, obj)
  }

  function dismissBanner(label: string, showNotify = false, delay = 1000) {
    showNotify && config.target && window.open(config.target, '_blank', 'noopener noreferrer')
    storage.set(config.storageKey, true)
    sendToMixpanel(`Survey banner / ${config.storageKey} / ${label}`)
    setShowFeedback(showNotify)
    setTimeout(() => {
      setHasSeen(true)
    }, delay)
  }

  function handleButtonClick() {
    dismissBanner(config.buttonLabel, true, config.feedbackDelay)
  }

  function handleClose() {
    dismissBanner('Closed', false, 0)
  }

  if (hasSeen || !config.enabled || !config.roleRestriction.includes(user.role || '')) {
    return null
  }

  return (
    <SurveyBanner
      displayText={displayText}
      buttonLabel={config.buttonLabel}
      showFeedback={showFeedback}
      onButtonClick={handleButtonClick}
      onClose={handleClose}
    />
  )
}

import { isNotEmpty } from './string'

export function getNumberFromString(rate: string) {
  return Number(rate.replace(/[^\d]/g, ''))
}

export function isNumber(value?: unknown): value is number {
  return typeof value === 'number'
}

export function parseNumber(value?: string | number | null): number | undefined {
  if (typeof value === 'number') {
    return value
  }

  if (value == null) {
    return undefined
  }

  return isNotEmpty(value) ? Number.parseFloat(value) : undefined
}

export function round(value: number, precision: number) {
  return Math.round((value + Number.EPSILON) * 10 ** precision) / 10 ** precision
}

export function clamp(value: number, min: number = 0, max: number = 100): number {
  return Math.max(min, Math.min(value, max))
}

export function isZero(value: number) {
  return value === 0
}

export function padNumber(number: number, targetLength: number, padString: string) {
  return String(number).padStart(targetLength, padString)
}

export function numberFormatter(numberString?: string | number | null, decimalPlaces = 6): string {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: decimalPlaces,
  }).format

  const number = Number(numberString || 0)

  if (isNaN(number)) {
    return formatter(0)
  }

  return formatter(number || 0)
}

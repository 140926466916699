import styled from 'styled-components/macro'

import { Dialog as MDialog } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

export const Dialog = styled(MDialog)`
  // Fix dialog not having scrolling
  [role='dialog'] {
    margin-top: var(--navbar-height);
    max-height: calc(100vh - var(--navbar-height) - ${getToken('space-m')});
    overflow-y: auto;
    overflow-x: hidden;
  }

  ${MDialog.Body} {
    margin-bottom: 0;
  }
`

import PropTypes from 'prop-types'
import { useEffect } from 'react'
import styled from 'styled-components/macro'

import { Banner } from '@loadsmart/loadsmart-ui'

import { Icon } from 'core/ui/components/icon/Icon'

import { analytics } from 'legacy/utils/analytics'
import { formatCurrency } from 'legacy/utils/formatCurrency'

export const StyledBanner = styled(Banner)`
  width: 100%;
  min-width: unset;
  margin: 16px 0;
`

export function SuggestedRateBanner({ rate = 0, loading = false }) {
  const formattedRate = formatCurrency(rate)
  const title = `Suggested market rate: ${loading ? 'loading...' : formattedRate}`

  useEffect(() => {
    if (rate > 0)
      analytics.track('Preferred Lane / Suggested Rate Banner / View', { suggestedRate: rate })
  }, [rate])

  if (rate > 0) {
    return <StyledBanner icon={<Icon name="graph" />} title={title} dismissible={false} />
  }

  return (
    <StyledBanner variant="warning" title="No suggested rate for this lane" dismissible={false} />
  )
}

SuggestedRateBanner.propTypes = {
  rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
}

import React, { createContext, useContext, useEffect, useMemo } from 'react'

import { EmptyState } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/miranda-react/dist/tokens'

import { RequestStatus } from 'core/domain/Request'
import ErrorIllustration from 'core/ui/assets/illustration-departing-truck.svg'
import { LoadingState } from 'core/ui/async-content/LoadingState'
import { useAbortController } from 'core/ui/hooks/useAbortController'

import user from 'legacy/utils/user'

import { type Carrier } from '../../domain/Carrier'
import { useCarrier } from '../hooks/useCarrier'

export type CarrierProviderType = {
  carrier: Carrier | undefined | null
  setCarrier: (carrier: Carrier) => void
}

export const CarrierContext = createContext<CarrierProviderType | null>(null)
CarrierContext.displayName = 'CarrierContext'

export function useCarrierContext() {
  const context = useContext(CarrierContext)

  if (context === null) {
    throw new Error(`useCarrierContext must be used within a CarrierProvider`)
  }

  return context
}

export function CarrierProvider({ children }: { children?: React.ReactNode }) {
  const abortController = useAbortController()
  const { carrier, status, fetchCarrier, setCarrier } = useCarrier()
  const value = useMemo<CarrierProviderType>(() => ({ carrier, setCarrier }), [carrier, setCarrier])

  useEffect(() => {
    if (user.isLoggedIn()) {
      fetchCarrier({ signal: abortController.signal })
    }
  }, [fetchCarrier, abortController])

  if (status === RequestStatus.Pending) {
    return <LoadingState />
  }

  if (status === RequestStatus.Error) {
    return (
      <EmptyState
        variant="page"
        illustration={ErrorIllustration}
        description="We could not load the user information at this time. Try again in a few seconds."
        action={{
          label: 'Try again',
          onClick: () => fetchCarrier({ signal: abortController.signal }),
        }}
        style={{ margin: '0 auto', paddingTop: getToken('spacing-30') }}
      />
    )
  }

  return <CarrierContext.Provider value={value}>{children}</CarrierContext.Provider>
}

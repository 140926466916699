import { type CookieClient } from '../infra/cookie'

type DismissBannerDeps = {
  cookies: CookieClient
}

type DismissBannerParams = {
  campaign: string
}

export function dismissBannerFactory({ cookies }: DismissBannerDeps) {
  return function dismissBannerImpl({ campaign }: DismissBannerParams) {
    try {
      const key = `dismissed-${campaign}-banner`
      cookies.setCookie(key, true)
      return true
    } catch (error) {
      return false
    }
  }
}

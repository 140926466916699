import { useCallback } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Tag } from '@loadsmart/loadsmart-ui'
import { getToken, toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { screen } from 'legacy/styles/screen'
import { analytics } from 'legacy/utils/analytics'

import { useNavigationContext } from '../../contexts/NavigationContext'

const Item = styled.li`
  border-bottom: ${getToken('border-thin')} solid ${getToken('color-neutral-40')};

  ${screen.lg} {
    border: none;
    display: inline-block;
  }
`

const StyledLink = styled(Link)<{ $withStamp?: boolean; $selected: boolean }>`
  display: ${({ $withStamp }) => ($withStamp ? 'flex' : 'block')};
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  padding: ${getToken('spacing-4')};
  font-size: ${getToken('font-size-2')};
  font-weight: ${getToken('font-weight-regular')};
  transition: background-color 0.1s linear;
  user-select: none;
  will-change: background-color;

  color: ${toCSSValue('color-text-inverted')};

  background: ${({ $selected }) => ($selected ? toCSSValue('color-primary-60') : 'transparent')};

  &:hover {
    background: ${toCSSValue('color-primary-60', 0.4)};
  }

  ${screen.lg} {
    line-height: 50px;
    padding: 0 ${getToken('spacing-4')};
  }
`

const Icon = styled.span`
  svg {
    display: none;
    vertical-align: middle;
    height: 14px;
    width: 14px;

    ${screen.lg} {
      margin: 0 20px 0 0;
      display: inline-block;
    }
  }
`

interface NavigationItemProps {
  icon?: JSX.Element
  stamp?: string
  title: string
  url: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  onCallback?: (e: React.SyntheticEvent<EventTarget>) => void
}

export function NavigationItem({
  icon,
  stamp,
  title,
  url,
  onCallback,
  onClick,
  ...restProps
}: NavigationItemProps) {
  const { close } = useNavigationContext()

  const selected = useRouteMatch({ path: url })

  const handleOnClick = useCallback(
    (event: React.SyntheticEvent<EventTarget>) => {
      if (onCallback) {
        onCallback(event)
      }
      close()
      analytics.track(`Navibar / Click / ${title}`)
    },
    [close, onCallback, title]
  )

  return (
    <Item onClick={handleOnClick} {...restProps}>
      <StyledLink to={url} onClick={onClick} $withStamp={!!stamp} $selected={Boolean(selected)}>
        {icon && <Icon>{icon}</Icon>}
        <span>{title}</span>

        {stamp && (
          <Tag size="small" variant="success">
            {stamp}
          </Tag>
        )}
      </StyledLink>
    </Item>
  )
}

import zonedTimeToUtcFn from 'date-fns-tz/zonedTimeToUtc'

import DEPRECATED_TZ from 'legacy/constants/deprecatedTz'

function zonedTimeToUtc(date, timezone, options = {}) {
  try {
    return zonedTimeToUtcFn(date, timezone, options)
  } catch {
    if (Object.keys(DEPRECATED_TZ).includes(timezone)) {
      console.warn(`Deprecated Timezone - ${timezone}`)
      return zonedTimeToUtcFn(date, DEPRECATED_TZ[timezone], options)
    } else {
      console.error(`Unsupported Timezone - ${timezone}`)
      return zonedTimeToUtcFn(date, 'UTC', options)
    }
  }
}

export { zonedTimeToUtc }

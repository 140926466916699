import styled from 'styled-components/macro'

import { Banner, EmptyState, Layout, Text } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { screen } from 'legacy/styles/screen'

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  height: 100vh;

  ${screen.md} {
    overflow-y: none;
    flex-direction: row;
  }
`

export const ContentWrapper = styled(Layout.Stack)<{ $animate?: boolean }>`
  padding: ${getToken('space-l')};
  flex-grow: 1;
  gap: ${getToken('space-m')};

  ${screen.md} {
    overflow-y: auto;
    padding: ${getToken('space-2xl')};
    flex-basis: 40%;
    gap: ${getToken('space-3xl')};

    ${({ $animate }) => ($animate ? 'animation: fadein 0.5s, slide 0.5s;' : '')}

    @keyframes slide {
      0% {
        transform: translate(50%, 0%);
      }
      100% {
        transform: translate(0%, 0%);
      }
    }

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`

export const ImageWrapper = styled.div<{ $backgroundImage: any }>`
  display: none;
  position: relative;
  overflow: hidden;
  padding: ${getToken('space-l')};

  background-position: center center;
  background-size: cover;

  animation: fadein 0.5s;

  ${screen.md} {
    display: flex;
    flex-basis: 60%;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100vh;

    background-image: ${({ $backgroundImage }) => `url(${$backgroundImage})`};
  }

  ${screen.lg} {
    padding: ${getToken('space-3xl')};
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const InputGroup = styled(Layout.Stack)`
  flex-grow: 1;
  text-align: left;
`

export const TextCenter = styled(Text)`
  text-align: center;
`

export const StyledEmptyState = styled(EmptyState)`
  display: flex;
  margin: auto;
`

export const StyledBanner = styled(Banner)`
  min-width: 0;
`

import { type FirebaseApp } from 'firebase/app'
import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
  type RemoteConfig,
} from 'firebase/remote-config'

import { __PROD__ } from 'core/infra/environment'

import sentry from 'legacy/utils/sentry'

import { defaultConfig, type RemoteConfigType } from '../domain/Config'
import { ConfigValue } from '../domain/ConfigValue'
import { type ConfigClient } from './ConfigAdapter'

const MINIMUM_FETCH_INTERVAL = __PROD__ ? 3600000 : 60000

export class FirebaseConfigClient implements ConfigClient {
  config: RemoteConfig

  constructor(app: FirebaseApp) {
    this.config = getRemoteConfig(app)
    this.config.settings.minimumFetchIntervalMillis = MINIMUM_FETCH_INTERVAL
    this.config.defaultConfig = defaultConfig
  }

  async getConfigs() {
    try {
      const fetchStatus = await fetchAndActivate(this.config)

      if (fetchStatus) {
        console.debug(`fetched new configs from remote`)
      } else {
        console.debug(`no new configs were fetched`)
      }
    } catch (error) {
      sentry.log(new Error('Failed to fetch configs from remote', { cause: error as Error }), {
        level: 'warning',
      })
    }

    const configs = getAll(this.config)

    return Object.entries(configs).reduce((acc, current) => {
      const [configName, value] = current

      const configValue = new ConfigValue(value.asString())

      return { ...acc, [configName]: configValue }
    }, {} as RemoteConfigType)
  }
}

import { API_URL } from 'core/infra/environment'
import { HttpClient, type HttpClientConfig } from 'core/infra/http/HttpClient'

import { UserAccountRepository } from '../infra/UserAccountRepository'

export type GetUserAccountDependencies = {
  userAccountRepository: UserAccountRepository
}

export function getUserAccountFactory({ userAccountRepository }: GetUserAccountDependencies) {
  return function (config?: { signal?: AbortSignal }) {
    return userAccountRepository.getUserAccount(config)
  }
}

export function getUserAccount(config?: HttpClientConfig) {
  const httpClient = new HttpClient(API_URL)
  const userAccountRepository = new UserAccountRepository({ httpClient })
  const get = getUserAccountFactory({ userAccountRepository })

  return get(config)
}

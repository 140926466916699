import { useState, type FormEvent } from 'react'

import { Label, TextField } from '@loadsmart/loadsmart-ui'
import { Button, Layout } from '@loadsmart/miranda-react'

import { type LoginMessage } from '../domain/LoginMessage'
import { isEmailValid } from '../domain/User'
import { InputGroup, StyledBanner } from './Account.styles'

export type LoginFormProps = {
  onLogin: (email: string, password: string) => void
  loading?: boolean
  message?: LoginMessage
}

export function LoginForm({ message, loading = false, onLogin }: LoginFormProps) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const isDisabled = !password || !isEmailValid(email) || loading
  const hasMessage = Boolean(message) && Boolean(message?.title)

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()

    onLogin(email, password)
  }

  return (
    <>
      {hasMessage && <StyledBanner variant="danger" title={message!.title} dismissible={false} />}
      <form onSubmit={handleSubmit} noValidate>
        <Layout.Stack gap="spacing-6">
          <InputGroup space="xs">
            <Label htmlFor="email">Email</Label>
            <TextField
              aria-label="email"
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onInput={({ target }) => setEmail((target as HTMLInputElement).value)}
            />
          </InputGroup>
          <InputGroup space="xs">
            <Label htmlFor="password">Password</Label>
            <TextField
              aria-label="password"
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              value={password}
              onInput={({ target }) => setPassword((target as HTMLInputElement).value)}
            />
          </InputGroup>
          <Button type="submit" variant="primary" disabled={isDisabled} loading={loading}>
            LOGIN
          </Button>
        </Layout.Stack>
      </form>
    </>
  )
}

import { type FormEvent, useState } from 'react'

import {
  Button,
  Checkbox,
  ErrorMessage,
  Label,
  Layout,
  Link,
  Text,
  TextField,
  ToggleGroup,
} from '@loadsmart/loadsmart-ui'
import type Status from '@loadsmart/loadsmart-ui/dist/utils/types/Status'

import { type EntityType } from 'core/domain/EntityType'
import { Icon } from 'core/ui/components/icon/Icon'

import analytics from 'legacy/utils/analytics'
import sentry from 'legacy/utils/sentry'

import { ConfigProvider } from 'config/ui/ConfigContext'
import { ConfigRender } from 'config/ui/ConfigRender'

import { type SignUp } from '../domain/SignUp'
import { SignUpError } from '../use-cases/carrierSignUp'
import { InputGroup, StyledBanner } from './SignUpForm.styles'

const equipmentTypeOptions = [
  {
    leading: <Icon name="truck-dryvan" />,
    label: 'Full Truck Load',
    value: 'ftl',
  },
  {
    leading: <Icon name="truck-drayage" />,
    label: 'Intermodal',
    value: 'drayage',
  },
]

const entityTypeOptions = [
  {
    label: 'Carrier',
    value: 'carrier',
  },
  {
    label: 'Broker',
    value: 'broker',
  },
]

export type SignUpFormProps = {
  error?: SignUpError
  isLoading?: boolean
  disabled?: boolean
  context?: string
  confirmButtonText?: string
  onSignUp: (signUp: SignUp) => Promise<void>
  initialValues?: Partial<SignUp>
}

export function SignUpForm({
  error,
  context,
  isLoading = false,
  disabled = false,
  confirmButtonText = 'Sign up',
  initialValues,
  onSignUp,
}: SignUpFormProps) {
  const [entityType, setEntityType] = useState(() => initialValues?.entityType || 'carrier')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [dotNumber, setDotNumber] = useState('')
  const [mcNumber, setMcNumber] = useState('')
  const [equipmentType, setEquipmentType] = useState<SignUp['equipmentType']>('ftl')
  const [scac, setScac] = useState('')
  const [uiia, setUiia] = useState(false)
  const [emodal, setEmodal] = useState(false)

  const isSignUpButtonDisabled = disabled || isLoading

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const type = equipmentType === 'ftl' ? 'ftl' : 'intermodal'

    try {
      await onSignUp({
        entityType,
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation,
        dotNumber,
        mcNumber,
        equipmentType,
        scac,
        uiia,
        emodal,
      })

      analytics.track('Signup / Carrier / Submitted', {
        type,
        entityType,
        context,
      })
    } catch (unhandledError) {
      const eventName = 'Signup / Carrier / Submit / Error'

      if (unhandledError instanceof SignUpError) {
        const { serverError, status, validationErrors } = unhandledError

        if (validationErrors) return

        analytics.track(eventName, {
          context,
          description: serverError,
          status,
          type,
          entityType,
        })
      } else {
        const err = unhandledError as Error

        analytics.track(eventName, {
          context,
          description: err?.message,
          type,
          entityType,
        })
      }

      sentry.log(unhandledError)
    }
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Layout.Stack>
        {error?.serverError && (
          <StyledBanner scale="default" title={error?.serverError} variant="danger" />
        )}
        <ConfigProvider>
          <ConfigRender configName="enableBrokerSignup">
            <InputGroup>
              <ToggleGroup
                id="entityType"
                name="entityType"
                value={entityType}
                onChange={({ target: { value } }) => setEntityType(value as EntityType)}
                options={entityTypeOptions}
              />
            </InputGroup>
          </ConfigRender>
        </ConfigProvider>
        <Layout.Group>
          <InputGroup>
            <Label htmlFor="firstName" required>
              First name
            </Label>
            <TextField
              id="firstName"
              name="firstName"
              placeholder="First name"
              value={firstName}
              onChange={({ target: { value } }) => setFirstName(value)}
              status={(error?.validationErrors?.firstName ? 'danger' : 'neutral') as Status}
              aria-required="true"
              autoFocus
            />
            {error?.validationErrors?.firstName && (
              <ErrorMessage>{error?.validationErrors?.firstName}</ErrorMessage>
            )}
          </InputGroup>
          <InputGroup>
            <Label htmlFor="lastName" required>
              Last name
            </Label>
            <TextField
              id="lastName"
              name="lastName"
              placeholder="Last name"
              value={lastName}
              onChange={({ target: { value } }) => setLastName(value)}
              status={(error?.validationErrors?.lastName ? 'danger' : 'neutral') as Status}
              aria-required="true"
            />
            {error?.validationErrors?.lastName && (
              <ErrorMessage>{error?.validationErrors?.lastName}</ErrorMessage>
            )}
          </InputGroup>
        </Layout.Group>
        <InputGroup>
          <Label htmlFor="email" required>
            Email
          </Label>
          <TextField
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            placeholder="Email"
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            status={(error?.validationErrors?.email ? 'danger' : 'neutral') as Status}
            aria-required="true"
          />
          {error?.validationErrors?.email && (
            <ErrorMessage>{error?.validationErrors?.email}</ErrorMessage>
          )}
        </InputGroup>
        <Layout.Group>
          <InputGroup>
            <Label htmlFor="password" required>
              Password
            </Label>
            <TextField
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              placeholder="Password"
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
              status={(error?.validationErrors?.password ? 'danger' : 'neutral') as Status}
              aria-required="true"
            />
            {error?.validationErrors?.password && (
              <ErrorMessage>{error?.validationErrors?.password}</ErrorMessage>
            )}
          </InputGroup>
          <InputGroup>
            <Label htmlFor="passwordConfirmation" required>
              Password confirmation
            </Label>
            <TextField
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              placeholder="Password confirmation"
              value={passwordConfirmation}
              onChange={({ target: { value } }) => setPasswordConfirmation(value)}
              status={
                (error?.validationErrors?.passwordConfirmation ? 'danger' : 'neutral') as Status
              }
              aria-required="true"
            />
            {error?.validationErrors?.passwordConfirmation && (
              <ErrorMessage>{error?.validationErrors?.passwordConfirmation}</ErrorMessage>
            )}
          </InputGroup>
        </Layout.Group>
        <Layout.Group>
          <InputGroup>
            <Label htmlFor="dotNumber" required>
              DOT number
            </Label>
            <TextField
              id="dotNumber"
              name="dotNumber"
              type="number"
              placeholder="DOT number"
              value={dotNumber}
              onChange={({ target: { value } }) => setDotNumber(value)}
              status={(error?.validationErrors?.dotNumber ? 'danger' : 'neutral') as Status}
              aria-required="true"
            />
            {error?.validationErrors?.dotNumber && (
              <ErrorMessage>{error?.validationErrors?.dotNumber}</ErrorMessage>
            )}
          </InputGroup>
          <InputGroup>
            <Label htmlFor="mcNumber" required>
              MC number
            </Label>
            <TextField
              id="mcNumber"
              name="mcNumber"
              type="number"
              placeholder="MC number"
              value={mcNumber}
              onChange={({ target: { value } }) => setMcNumber(value)}
              status={(error?.validationErrors?.mcNumber ? 'danger' : 'neutral') as Status}
              aria-required="true"
            />
            {error?.validationErrors?.mcNumber && (
              <ErrorMessage>{error?.validationErrors?.mcNumber}</ErrorMessage>
            )}
          </InputGroup>
        </Layout.Group>
        <InputGroup>
          <ToggleGroup
            id="equipmentType"
            name="equipmentType"
            value={equipmentType}
            onChange={({ target: { value } }) => setEquipmentType(value as SignUp['equipmentType'])}
            options={equipmentTypeOptions}
          />
          {error?.validationErrors?.equipmentType && (
            <ErrorMessage>{error?.validationErrors?.equipmentType}</ErrorMessage>
          )}
        </InputGroup>
        {equipmentType === 'drayage' && (
          <>
            <InputGroup>
              <Label htmlFor="scac" required>
                SCAC code
              </Label>
              <TextField
                id="scac"
                name="scac"
                placeholder="SCAC code"
                value={scac}
                onChange={({ target: { value } }) => setScac(value)}
                status={(error?.validationErrors?.scac ? 'danger' : 'neutral') as Status}
                aria-required="true"
              />
              {error?.validationErrors?.scac && (
                <ErrorMessage>{error?.validationErrors?.scac}</ErrorMessage>
              )}
            </InputGroup>
            <InputGroup>
              <Checkbox
                id="uiia"
                name="uiia"
                checked={uiia}
                onChange={() => setUiia(value => !value)}
              >
                By checking this box, I confirm I have a{' '}
                <Link
                  href="https://www.uiia.org/motor-carriers-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  valid UIIA
                </Link>{' '}
                for all equipment providers at the port terminals or rail ramps from where I will
                accept Loadsmart shipments.
              </Checkbox>
              {error?.validationErrors?.uiia && (
                <ErrorMessage>{error?.validationErrors?.uiia}</ErrorMessage>
              )}
            </InputGroup>
            <InputGroup>
              <Checkbox
                id="emodal"
                name="emodal"
                checked={emodal}
                onChange={() => setEmodal(value => !value)}
              >
                By checking this box, I confirm I meet the requirements for access to any port
                terminal or rail ramp from where I accept a Loadsmart shipment.
              </Checkbox>
              {error?.validationErrors?.emodal && (
                <ErrorMessage>{error?.validationErrors?.emodal}</ErrorMessage>
              )}
            </InputGroup>
          </>
        )}
        <Text>
          By clicking sign up, you agree that you have read and accepted our{' '}
          <Link
            href="https://loadsmart.com/master-saas-and-services-agreement"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            href="https://loadsmart.com/user-agreement"
            target="_blank"
            rel="noopener noreferrer"
          >
            User Agreement
          </Link>
        </Text>
        <Button
          variant="primary"
          type="submit"
          disabled={isSignUpButtonDisabled}
          loading={isLoading}
        >
          {confirmButtonText}
        </Button>
      </Layout.Stack>
    </form>
  )
}

import { useState } from 'react'

import { RequestStatus, type ServerError } from 'core/domain/Request'
import { type HttpClientError } from 'core/infra/http/HttpClient'

import { type CarrierToken } from '../../domain/CarrierLogin'
import { type LoginMessage } from '../../domain/LoginMessage'
import { PendingApprovalError } from '../../errors/PendingApprovalError'
import { PendingEmailVerificationError } from '../../errors/PendingEmailVerificationError'
import { carrierLogin } from '../../use-cases/carrierLogin'

export function useLogin() {
  const [tokenInfo, setTokenInfo] = useState<CarrierToken | undefined>()
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Initial)
  const [message, setMessage] = useState<LoginMessage | undefined>()

  const isLoading = status === RequestStatus.Pending

  async function login(email: string, password: string, { signal }: AbortController) {
    setStatus(RequestStatus.Pending)
    setMessage(undefined)

    try {
      if (!signal.aborted) {
        const token = await carrierLogin(email, password, { signal })

        setTokenInfo(token)
        setMessage(undefined)
        setStatus(RequestStatus.Done)

        return token
      }
    } catch (loginError) {
      if (signal.aborted) return

      setStatus(RequestStatus.Error)
      setTokenInfo(undefined)

      const approvalError = loginError instanceof PendingApprovalError
      const emailVerificationError = loginError instanceof PendingEmailVerificationError

      if (!approvalError && !emailVerificationError) {
        const apiError = (loginError as HttpClientError<ServerError>)?.response
        setMessage({
          variant: apiError?.status === 403 ? 'warning' : 'danger',
          title: apiError?.data.error_description ?? 'There was an error trying to login',
        })
      }

      throw loginError
    }
  }

  return {
    isLoading,
    message,
    status,
    tokenInfo,
    login,
  }
}

import QueryString from 'query-string'

import { type Data } from 'core/domain/Request'
import { type HttpClientConfig, type HttpClientInterface } from 'core/infra/http/HttpClient'

import { CLIENT } from 'legacy/constants/clients'

import { type CarrierAccount } from '../domain/Carrier'
import { type SignUp } from '../domain/SignUp'
import { mapApiToCarrierToken, mapLoginToApi, type RawCarrierToken } from './CarrierLoginMapper'
import { mapApiToCarrier, mapSignUpToApi, type RawCarrier } from './SignUpMapper'

export type AccountRepositoryDependencies = {
  httpClient: HttpClientInterface
}

export type SetNewPasswordParams = {
  email: string
  newPassword: string
  token: string
}

export class AccountRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: AccountRepositoryDependencies) {
    this.httpClient = httpClient
  }

  async getClientToken(config?: HttpClientConfig): Promise<string> {
    const endpoint = '/api/v2/oauth/token'
    const payload = QueryString.stringify({ grant_type: 'client_credentials' })
    const token = window.btoa(`${CLIENT.client_id}:${CLIENT.client_secret}`)
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${token}`,
    }

    const { data } = await this.httpClient.post(endpoint, payload, {
      ...config,
      headers: {
        ...config?.headers,
        ...headers,
      },
    })

    return `${data.token_type} ${data.access_token}`
  }

  async createAccountCarrier(signUp: SignUp, config?: HttpClientConfig): Promise<CarrierAccount> {
    const endpoint = '/api/v2/carriers'
    const token = await this.getClientToken()
    const headers = { Authorization: token }

    const rawSignUp = mapSignUpToApi(signUp)

    const {
      data: { data },
    } = await this.httpClient.post<Data<RawCarrier>>(endpoint, rawSignUp, {
      ...config,
      headers: {
        ...config?.headers,
        ...headers,
      },
    })

    try {
      return mapApiToCarrier(data)
    } catch (error) {
      throw new Error('Failed to map API return to Carrier', { cause: error as Error })
    }
  }

  async createAccountCarrierIntermodal(
    signUp: SignUp,
    config?: HttpClientConfig
  ): Promise<CarrierAccount> {
    const endpoint = '/api/v2/intermodal/carrier'
    const token = await this.getClientToken()
    const headers = { Authorization: token }

    const rawSignUp = mapSignUpToApi(signUp)

    const {
      data: { data },
    } = await this.httpClient.post<Data<RawCarrier>>(endpoint, rawSignUp, {
      ...config,
      headers: {
        ...config?.headers,
        ...headers,
      },
    })

    try {
      return mapApiToCarrier(data)
    } catch (error) {
      throw new Error('Failed to map API return to Carrier', { cause: error as Error })
    }
  }

  async reSendEmail(email: string, config?: HttpClientConfig): Promise<void> {
    const endpoint = '/api/v2/account/send_activation_email'

    await this.httpClient.post(endpoint, { email }, config)
  }

  async login(email: string, password: string, config?: HttpClientConfig) {
    const endpoint = '/api/v2/oauth/token'
    const payload = QueryString.stringify(mapLoginToApi(email, password))
    const authorization = window.btoa(`${CLIENT.client_id}:${CLIENT.client_secret}`)
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${authorization}`,
    }

    const { data } = await this.httpClient.post<RawCarrierToken>(endpoint, payload, {
      ...config,
      headers: {
        ...config?.headers,
        ...headers,
      },
    })

    try {
      return mapApiToCarrierToken(data)
    } catch (error) {
      throw new Error('Failed to map API to CarrierToken', { cause: error as Error })
    }
  }

  async resetPassword(email: string, config?: HttpClientConfig): Promise<void> {
    const endpoint = '/api/v2/account/password/reset'
    const token = await this.getClientToken()
    const headers = { Authorization: token }

    await this.httpClient.post(
      endpoint,
      { email },
      {
        ...config,
        headers: {
          ...config?.headers,
          ...headers,
        },
      }
    )
  }

  async setNewPassword(
    { email, newPassword, token }: SetNewPasswordParams,
    config?: HttpClientConfig
  ) {
    const endpoint = `/api/v2/account/password/reset/confirm`

    const data = {
      email,
      new_password: newPassword,
      token,
    }

    return this.httpClient.post(endpoint, data, config)
  }
}

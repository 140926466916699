import styled from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { Equipment } from 'legacy/components/LoadOffer/Equipment'
import { screen } from 'legacy/styles/screen'

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 16px;

  border: 1px solid ${getToken('color-neutral-light')};
  border-radius: ${getToken('border-radius-s')};
`

export const FormLine = styled.div`
  display: flex;
  flex: 1 auto 0 0;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  ${screen.md} {
    flex-flow: row wrap;
    gap: 32px;
    align-items: center;
    justify-content: flex-start;
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  min-height: 76px;

  ${screen.md} {
    flex-basis: auto;
    width: auto;
  }
`

export const FormInputWrapper = styled.div`
  width: 100%;
  padding: 8px 0;
`

export const FormLabel = styled.span`
  font-weight: bold;
  font-size: 16px;
`

export const StyledEquipment = styled(Equipment)`
  justify-content: center;
  min-width: 78px;
`

export const StyledWeekdayLabel = styled.span`
  min-width: 32px;

  text-align: center;
`

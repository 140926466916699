import React, { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react'

import { defaultConfigValues, type RemoteConfigType } from '../domain/Config'
import { fetchRemoteConfigs } from '../use-cases/fetchRemoteConfigs'

export const ConfigContext = createContext<RemoteConfigType | undefined>(undefined)
ConfigContext.displayName = 'ConfigContext'

export function ConfigProvider({ children }: { children?: React.ReactNode }) {
  const [configs, setConfigs] = useState<RemoteConfigType>(defaultConfigValues)
  const mountedRef = React.useRef(false)

  useEffect(function onFirstRender() {
    async function getRemoteConfigs() {
      const newConfigs = await fetchRemoteConfigs()
      if (newConfigs && mountedRef.current) {
        setConfigs(newConfigs)
      }
    }

    getRemoteConfigs()
  }, [])

  useLayoutEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  return <ConfigContext.Provider value={configs}>{children}</ConfigContext.Provider>
}

export function useConfigContext() {
  const context = useContext(ConfigContext)

  if (context === undefined) {
    throw new Error(`useConfigContext must be used within a ConfigProvider`)
  }

  return context
}

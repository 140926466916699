import styled from 'styled-components/macro'

import { screen } from 'legacy/styles/screen'

import { DropdownMenuItem } from './DropdownMenuItem'

export const StyledMenuItem = styled(DropdownMenuItem)`
  ${screen.lg} {
    & > a {
      width: 200px;
    }
  }
`

export const MenuList = styled.ul`
  ${screen.lg} {
    display: flex;
    align-items: center;
  }
`

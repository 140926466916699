import { CLIENT } from 'legacy/constants/clients'

import { type CarrierToken } from '../domain/CarrierLogin'

export type RawCarrierToken = {
  access_token: string
  scope: string
  token_type: string
  expires_in: number
}

export type RawLogin = {
  grant_type: string
  username: string
  password: string
  client_id: string
}

export function mapLoginToApi(email: string, password: string): RawLogin {
  return {
    grant_type: 'password',
    username: email,
    password: password,
    client_id: CLIENT.client_id,
  }
}

export function mapApiToCarrierToken(carrierToken: RawCarrierToken): CarrierToken {
  return {
    accessToken: carrierToken.access_token,
    scope: carrierToken.scope,
    tokenType: carrierToken.token_type,
    expiresIn: carrierToken.expires_in,
  }
}

import { type CookieClient } from '../infra/cookie'

type GetBannerStateDeps = {
  cookies: CookieClient
}

type GetBannerStateParams = {
  campaign: string
}

export function getBannerStateFactory({ cookies }: GetBannerStateDeps) {
  return function getBannerStateImpl({ campaign }: GetBannerStateParams) {
    try {
      const key = `dismissed-${campaign}-banner`
      return cookies.getCookie<boolean>(key)
    } catch (error) {
      return false
    }
  }
}

import { type EntityType } from 'core/domain/EntityType'

import { type CarrierEquipmentType, type Carrier } from '../domain/Carrier'

export type RawCarrierStatus = 'new' | 'pending' | 'ready' | 'inactive'

export type RawCarrier = {
  id: string
  name: string
  dot_number: string
  contracting_requirements_passes: boolean
  mc_number: string | null
  account_id: string
  physical_street: string
  physical_city: string
  physical_state: string
  physical_zip: string | null
  physical_country: string
  dispatcher_phone_number: string | null
  equipment_types: Array<CarrierEquipmentType>
  regions: Array<string>
  bipd_insurance: number | null
  cargo_insurance: number | null
  safety_passed: boolean
  has_planner: boolean
  has_active_dnu: boolean
  fleet_size_category: string
  status: RawCarrierStatus | null
  eld_provider_id: string | null
  quickpay_enabled: boolean
  bid_enabled: boolean
  bid_probability: boolean
  entity_type: EntityType
  documents_status?: string
}

export function mapApiToCarrier(rawCarrier: RawCarrier): Carrier {
  if (!rawCarrier) throw new TypeError(`Expected rawCarrier, but received '${typeof rawCarrier}'`)

  return {
    id: rawCarrier.id,
    name: rawCarrier.name,
    mc: rawCarrier.mc_number || undefined,
    dot: rawCarrier.dot_number,
    contractingRequirementsPasses: rawCarrier.contracting_requirements_passes,
    accountId: rawCarrier.account_id,
    address: {
      street: rawCarrier.physical_street,
      city: rawCarrier.physical_city,
      state: rawCarrier.physical_state,
      zip: rawCarrier.physical_zip,
      country: rawCarrier.physical_country,
    },
    dispatcherPhoneNumber: rawCarrier.dispatcher_phone_number || undefined,
    equipmentTypes: rawCarrier.equipment_types,
    regions: rawCarrier.regions,
    bipdInsurance: rawCarrier.bipd_insurance ?? undefined,
    cargoInsurance: rawCarrier.cargo_insurance ?? undefined,
    safetyPassed: rawCarrier.safety_passed,
    hasPlanner: rawCarrier.has_planner,
    hasActiveDNU: rawCarrier.has_active_dnu,
    fleetSizeCategory: rawCarrier.fleet_size_category,
    status: rawCarrier.status || undefined,
    eldProviderId: rawCarrier.eld_provider_id || undefined,
    quickpayEnabled: rawCarrier.quickpay_enabled,
    bidEnabled: rawCarrier.bid_enabled,
    bidProbability: rawCarrier.bid_probability,
    entityType: rawCarrier.entity_type,
  }
}

import { useState } from 'react'

import { RequestStatus } from 'core/domain/Request'

import { type CarrierAccount } from '../../domain/Carrier'
import { type SignUp } from '../../domain/SignUp'
import { carrierSignUp, SignUpError } from '../../use-cases/carrierSignUp'

type State = {
  status: RequestStatus
  carrier?: CarrierAccount
  error?: SignUpError
}

export function useSignUp() {
  const [state, setState] = useState<State>({ status: RequestStatus.Initial })

  async function signUpCarrier(signUp: SignUp, { signal }: AbortController) {
    setState({ status: RequestStatus.Pending })

    try {
      if (!signal.aborted) {
        const carrier = await carrierSignUp(signUp, { signal })

        setState({ status: RequestStatus.Done, carrier })
      }
    } catch (error) {
      if (error instanceof SignUpError) {
        setState({ status: RequestStatus.Error, error })

        throw error
      } else {
        setState({ status: RequestStatus.Error })

        throw new Error('Unexpected error during SignUp', { cause: error })
      }
    }
  }

  const isLoading = state.status === RequestStatus.Pending
  const hasError = state.status === RequestStatus.Error

  return {
    status: state.status,
    error: state.error,
    carrier: state.carrier,
    isLoading,
    hasError,
    signUpCarrier,
  }
}

import { EmptyState } from '@loadsmart/loadsmart-ui'
import { Layout } from '@loadsmart/miranda-react'

import ImgBrokenLink from 'legacy/assets/img/empty-containers-broken-link.png'
import { PageWrapper } from 'legacy/components/PageWrapper/PageWrapper'

import { Container } from '../../Container'

export type ErrorFallbackProps = {
  title?: string
  description?: string
  onReset?: () => void
}

const defaultTitle = 'Oops...'
const defaultDescription = `This is embarrassing, but something went wrong with the page. It's not you. Just try again or wait a few minutes.`

export function ErrorFallback({
  title = defaultTitle,
  description = defaultDescription,
  onReset,
}: ErrorFallbackProps) {
  const action =
    typeof onReset === 'function'
      ? {
          onClick: onReset,
          label: 'Retry',
        }
      : undefined

  return (
    <PageWrapper>
      <Container>
        <Layout.Stack align="center" padding="spacing-20">
          <EmptyState
            illustration={ImgBrokenLink}
            title={title}
            description={description}
            action={action}
            variant="page"
          />
        </Layout.Stack>
      </Container>
    </PageWrapper>
  )
}

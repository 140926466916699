import { type Data } from 'core/domain/Request'
import { type HttpClientInterface, type HttpClientConfig } from 'core/infra/http/HttpClient'

import { type AccountRepositoryDependencies } from './AccountRepository'
import { type RawCarrier, mapApiToCarrier } from './carrierMapper'

type GetCarrierParams = {
  config?: HttpClientConfig
}

type UpdateCarrierParams = {
  data: Object
  config?: HttpClientConfig
}

export class CarrierRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: AccountRepositoryDependencies) {
    this.httpClient = httpClient
  }

  async getCarrier({ config }: GetCarrierParams = {}) {
    const endpoint = '/api/v2/carrier/'

    const result = await this.httpClient.get<Data<RawCarrier>>(endpoint, config)

    return mapApiToCarrier(result.data.data)
  }

  async updateCarrier({ data, config }: UpdateCarrierParams = { data: {} }) {
    const endpoint = '/api/v2/carrier/'

    const result = await this.httpClient.patch<Data<RawCarrier>>(endpoint, data, config)

    return mapApiToCarrier(result.data.data)
  }
}

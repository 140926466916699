import { type ListResults } from 'core/domain/Request'
import { AlreadyExistsError } from 'core/infra/errors/AlreadyExistsError'
import { NotFoundError } from 'core/infra/errors/NotFoundError'
import {
  type HttpClientConfig,
  type HttpClientError,
  type HttpClientInterface,
  type HttpClientResponse,
} from 'core/infra/http/HttpClient'
import { stringifyQueryString } from 'core/infra/queryString'

import { formatDate } from 'legacy/utils/date/formatDate'
import { zonedTimeToUtc } from 'legacy/utils/date/zonedTimeToUtc'

import { type PreferredLane } from '../domain/PreferredLane'
import { type RawPreferredLane, mapApiToPreferredLane } from './PreferredLaneMapper'

export type PreferredLaneRepositoryDependencies = {
  httpClient: HttpClientInterface
}

export class PreferredLaneRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: PreferredLaneRepositoryDependencies) {
    this.httpClient = httpClient
  }

  async getPreferredLanes(page: number, pageSize: number, config?: HttpClientConfig) {
    const queryParams = stringifyQueryString({ page, page_size: pageSize })
    const endpoint = `/api/v2/loadboard/preferred-lanes?${queryParams}`

    const { data } = await this.httpClient.get<ListResults<RawPreferredLane>>(endpoint, config)

    try {
      return {
        count: data.count,
        results: data.results.map(mapApiToPreferredLane),
      }
    } catch (error) {
      throw new Error('Failed to map API return to PreferredLane', { cause: error })
    }
  }

  /**
   * Given a Preferred Lane, it will use the its values to search for a existing Preferred Lane.
   *
   * @returns UUID of matching Preferred Lane
   * @throws {NotFoundError} If no matching Preferred Lane is found
   */
  async getByPreferredLane(preferredLane: PreferredLane, config?: HttpClientConfig) {
    const endpoint = '/api/v2/loadboard/preferred-lanes/exists'
    const params: Record<string, string> = Object.create(null)

    if (preferredLane.origin != null) {
      params.origin = `${preferredLane.origin.latitude},${preferredLane.origin.longitude}`
    }

    if (preferredLane.destination != null) {
      params.destination = `${preferredLane.destination.latitude},${preferredLane.destination.longitude}`
    }

    if (Array.isArray(preferredLane.equipmentTypes)) {
      params.equipment_type = preferredLane.equipmentTypes.join(',')
    }

    if (preferredLane.startsAt != null) {
      params.starts_at = formatDate(zonedTimeToUtc(preferredLane.startsAt), 'yyyy-MM-dd')
    }

    if (preferredLane.expiresAt != null) {
      params.expires_at = formatDate(zonedTimeToUtc(preferredLane.expiresAt), 'yyyy-MM-dd')
    }

    try {
      const response = await this.httpClient.get<{ uuid: string }>(endpoint, { ...config, params })

      return response.data.uuid
    } catch (error) {
      if ((error as HttpClientError)?.response?.status === 404) {
        throw new NotFoundError({ cause: error })
      }

      throw error
    }
  }

  /**
   * @throws {AlreadyExistsError} If a Preferred Lane with the same values already exists
   */
  async addPreferredLane(preferredLane: PreferredLane, config?: HttpClientConfig) {
    const endpoint = '/api/v2/loadboard/preferred-lanes'
    const payload = {
      channel: 'carrier',
      origin: preferredLane.origin,
      destination: preferredLane.destination,
      equipment_types: preferredLane.equipmentTypes,
      weekdays: preferredLane.weekdays,
      starts_at: preferredLane.startsAt,
      expires_at: preferredLane.expiresAt,
      rate: preferredLane.rate,
    }

    let response: HttpClientResponse<RawPreferredLane>

    try {
      response = await this.httpClient.post<RawPreferredLane>(endpoint, payload, config)
    } catch (error) {
      if ((error as HttpClientError)?.response?.status === 422) {
        throw new AlreadyExistsError({ cause: error })
      }

      throw error
    }

    try {
      return mapApiToPreferredLane(response.data)
    } catch (error) {
      throw new Error('Failed to map API return to PreferredLane', { cause: error })
    }
  }

  async deletePreferredLane(preferredLaneId: string, config?: HttpClientConfig) {
    const endpoint = `/api/v2/loadboard/preferred-lanes/${preferredLaneId}`

    await this.httpClient.delete(endpoint, config)
  }

  async updatePreferredLaneRate(preferredLaneId: string, rate: number, config?: HttpClientConfig) {
    const endpoint = `/api/v2/loadboard/preferred-lanes/${preferredLaneId}`

    const response = await this.httpClient.patch(endpoint, { rate }, config)

    try {
      return mapApiToPreferredLane(response.data)
    } catch (error) {
      throw new Error('Failed to map API return to PreferredLane', { cause: error })
    }
  }

  async deletePreferredLaneRate(preferredLaneId: string, config?: HttpClientConfig) {
    const endpoint = `/api/v2/loadboard/preferred-lanes/${preferredLaneId}`

    const response = await this.httpClient.patch(endpoint, { rate: null }, config)

    try {
      return mapApiToPreferredLane(response.data)
    } catch (error) {
      throw new Error('Failed to map API return to PreferredLane', { cause: error })
    }
  }

  async getPreferredLaneSuggestedRate(preferredLane: PreferredLane, config?: HttpClientConfig) {
    const endpoint = '/api/v2/loadboard/preferred-lanes/suggested-rate'
    const payload = {
      origin: preferredLane.origin,
      destination: preferredLane.destination,
      equipment_type: preferredLane.equipmentTypes?.[0],
    }

    const response = await this.httpClient.post<{ suggested_rate: number }>(
      endpoint,
      payload,
      config
    )

    try {
      return response.data.suggested_rate
    } catch (error) {
      throw new Error('Failed to map API return to Suggested Rate', { cause: error })
    }
  }
}
